import { setActualDate } from '@/helpers/store/setActualDate'
import requestHelper from '@/helpers/store/requestHelper'
import { calcChange } from '@/helpers/store/calcChange'
import { i18n } from '@/lang'

export const ecosystem = {
  namespaced: true,
  actions: {
    async load(ctx) {
      const { selectedDomain } = ctx.rootGetters
      await requestHelper(
        {
          url: '/data/getSubDomains',
          domains: selectedDomain,
          viewId: ctx.rootState.view.viewId,
        },
        ctx
      )
      ctx.commit('updateActualDate')
      ctx.commit('updateLoading', false)
    },
  },
  state: () => ({
    data: [],
    actualDate: null,
    loading: true,
    error: { status: false },
  }),
  mutations: {
    updateData(state, data) {
      const adaptedData = data.map(dataItem => {
        return {
          domainName: dataItem.domainName,
          data: dataItem.data.map(item => {
            return {
              domainName: item.domainName,
              traffic: item.visits[0] || null,
            }
          }),
        }
      })
      state.data = adaptedData
    },
    updateActualDate(state, value) {
      state.actualDate = '2021-12-01' // setActualDate(state.data, value)
    },
    updateLoading(state, status) {
      state.loading = status
    },
    updateError(state, data) {
      state.error = data
    },
  },
  getters: {
    dataOnActualDate(state) {
      return state.data[0]?.data
    },
    tableData(state, getters, rootState, rootGetters) {
      const items = []
      const fields = [
        {
          key: 'domainName',
          label: i18n.global.t('analytics.site.title'),
          sortable: true,
        },
        {
          key: 'traffic',
          label: i18n.global.t('analytics.traffic.title'),
          sortable: true,
        },
        // {
        //   key: 'change',
        //   label: i18n.global.t('analytics.change.title'),
        //   sortable: true,
        // },
      ]
      getters.dataOnActualDate.map(item => {
        items.push({
          domainName: item.domainName,
          traffic: item.traffic,
          // change: item.change,
        })
      })
      return {
        items,
        fields,
      }
    },
    isData(state, getters) {
      return (
        !state.loading &&
        !state.error.status &&
        getters.dataOnActualDate?.length
      )
    },
  },
}
