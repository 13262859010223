<template>
  <div class="d-flex justify-content-between g-0 item">
    <div class="wrapper_refferal_link">
      <div class="wrapper_links">
        <Favicon class="icon" :domain="domain" />
        <template v-if="name">
          <span class="headline">{{ name }} </span>
        </template>
        <span v-else class="headline">{{ getSourceName(domain) }} </span>
      </div>
      <a class="refferal_link" target="_blank" :href="'//' + domain">
        {{ domain }}</a
      >
    </div>
    <div class="wrapper_percents">
      <div class="percents">{{ share }}</div>
      <GrowRate v-if="change" :change="change" />
      <IndicatorShare
        v-if="!change && indicator"
        class="indicator_share"
        :width="share"
      />
    </div>
  </div>
</template>

<script>
import Favicon from '@/components/shared/Favicon.vue'
import getFavicon from '@/helpers/domains/getFavicon'
import getSourceName from '@/helpers/domains/getSourceName'
import GrowRate from '@/components/shared/GrowRate.vue'
import IndicatorShare from '@/components/widgets/widgets-helpers/IndicatorShare.vue'
import OtherIcon from '@/assets/icons/other.svg'

export default {
  data() {
    return {
      OtherIcon,
    }
  },
  props: {
    name: String,
    share: String,
    change: Number,
    domain: String,
    indicator: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    GrowRate,
    Favicon,
    IndicatorShare,
  },
  methods: {
    getFavicon,
    getSourceName,
  },
}
</script>

<style scoped>
.icon {
  margin-right: 8px;
}
.wrapper_refferal_link {
  max-width: 270px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.wrapper_links {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #5c5f6c;
}

.wrapper_refferal_link > .refferal_link {
  margin-left: 22px;
}

.wrapper_percents {
  width: 58px;
}

.percents {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0e0f10;
  text-align: end;
}
.indicator_share {
  margin-top: 5px;
  width: 48px;
  margin-left: 10px;
}
.wrapper_select {
  margin-bottom: 35px;
}
.item {
  padding: 10px 20px;
  border-radius: 7px;
}
.item:hover {
  background: #f9f9fc;
  padding: auto 10px;
}
</style>
