<template>
  <div>
    <Table
      v-if="!loading"
      :items="tableData.items"
      :fields="tableData.fields"
      sortBy="traffic"
      :sortDesc="true"
    >
      <template v-slot:domainPicker>
        <DomainPicker type="local" />
      </template>
      <template #cell(domainName)="data">
        <LinkCell :data="data" :favicon="true" />
      </template>
      <template #cell(traffic)="data">
        <TrafficCell
          :data="{ value: data.value === -1 ? '<50k' : data.value }"
        />
      </template>
    </Table>
    <SkeletonLoading v-if="loading" name="table" />
  </div>
</template>

<script>
import DomainPicker from '@/components/DomainPicker.vue'
import TrafficCell from '@/components/tables/cells/TrafficCell.vue'
import LinkCell from '@/components/tables/cells/LinkCell.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import Table from '@/components/tables/Table.vue'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {}
  },
  components: {
    LinkCell,
    Table,
    TrafficCell,
    DomainPicker,
    SkeletonLoading,
  },
  props: {
    section: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('similarSites', ['tableData']),
    ...mapState('similarSites', {
      similarSites: state => state,
    }),
    loading() {
      return this.similarSites.loading
    },
  },
}
</script>

<style scoped></style>
