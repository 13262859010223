<template>
  <WidgetWrapper>
    <WidgetTitle
      v-if="!loading"
      :title="$t('main.mobileApps.title')"
      :description="$t('main.mobileApps.description')"
    />
    <div v-if="isData" class="content" ref="content">
      <div class="d-flex row row-cols-1 row-cols-md-3">
        <div class="col" v-for="store of stores" :key="store.name">
          <div class="d-flex flex-column">
            <div class="wrapper_col_title">
              <span class="text-center text-md-start d-flex align-items-center">
                {{ store.name }}
                <img class="ms-2" :src="store.icon" />
              </span>
            </div>
            <div class="d-flex flex-column flex-wrap g-0">
              <template v-if="isAppInStore(store.key)">
                <AppItem
                  v-for="mobileApp of mainDomainData[store.key].slice(0, 3)"
                  :name="mobileApp.name"
                  :iconUrl="mobileApp.iconLink"
                  :rating="mobileApp.rate"
                  :key="mobileApp.name"
                />
              </template>
              <template v-else>
                <AppItem :name="$t('error.notFound')" rating="0" />
              </template>
            </div>
          </div>
        </div>
        <div
          class="col d-none d-md-flex justify-content-center align-items-center"
        >
          <img class="mobile_app_svg" :src="MobileAppsIllustaration" />
        </div>
      </div>
    </div>
    <WidgetError v-if="!isData && !loading" />
    <SkeletonLoading v-if="loading" name="mobileApps" />
  </WidgetWrapper>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import WidgetTitle from '@/components/widgets/widgets-helpers/WidgetTitle.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import WidgetError from '@/components/errors/WidgetError.vue'
import MobileAppsIllustaration from '@/assets/illustrations/MobileApps.svg'
import AppStoreIcon from '@/assets/icons/mobile-apps/AppStore.svg'
import GooglePlayIcon from '@/assets/icons/mobile-apps/GooglePlay.svg'
import AppItem from '@/components/widgets/mobile-apps/AppItem.vue'
import WidgetWrapper from '@/components/widgets/widgets-helpers/WidgetWrapper.vue'

export default {
  data() {
    return {
      stores: [
        { name: 'Google Play', key: 'googleApps', icon: GooglePlayIcon },
        { name: 'App Store', key: 'appleApps', icon: AppStoreIcon },
      ],
      MobileAppsIllustaration,
    }
  },
  components: {
    WidgetTitle,
    SkeletonLoading,
    WidgetError,
    AppItem,
    WidgetWrapper,
  },
  computed: {
    ...mapGetters('mobileApps', ['dataOnActualDate', 'mainDomainData']),
    ...mapState('additionalData', {
      additionalData: state => state,
    }),
    ...mapState('mobileApps', {
      mobileApps: state => state,
    }),
    isData() {
      return (
        !this.loading &&
        !this.additionalData.error.status &&
        this.mainDomainData
      )
    },
    loading() {
      return this.additionalData.loading
    },
  },
  methods: {
    isAppInStore(key) {
      return this.mainDomainData[key]?.length
    },
  },
}
</script>

<style scoped>
.wrapper_col_title {
  padding-bottom: 16px;
  border-bottom: 1px solid #eaeef8;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

.mobile_app_svg {
  max-width: 255px;
}
</style>
