<template>
  <div class="add_site d-flex">
    <img class="plus_icon" :src="PlusIcon" />
    <span>{{ $t('buttons.addSite') }}</span>
  </div>
</template>

<script>
import PlusIcon from '@/assets/icons/base/plus.svg'

export default {
  data() {
    return {
      PlusIcon,
    }
  },
  computed: {},
  methods: {},
}
</script>

<style>
.add_site {
  cursor: pointer;
  font-weight: 600;
}
.add_site span {
  margin-top: 1px;
}

.plus_icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  stroke: white;
}
</style>
