<template>
  <li class="link">
    <a v-if="href" :href="href" target="_blank">
      <div class="icon">
        <slot></slot>
      </div>
      <span>{{ name }}</span>
    </a>
    <router-link v-if="routerLink" :to="routerLink">
      <div class="icon">
        <slot></slot>
      </div>
      <span>{{ name }}</span>
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'Checkbox',
  props: ['name', 'href', 'routerLink'],
  components: {},
}
</script>

<style scoped>
li {
  list-style: none;
}

li > * {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  font-size: 14px;
  line-height: 19px;
  color: #aab2ba;
  height: 100%;
  white-space: nowrap;
}

li.link:hover > *,
li.link.checked > * {
  color: #ffffff;
}

.icon {
  margin-right: 25px;
}
</style>
