<template>
  <a class="link" :style="styleOptions" :href="'//' + link" target="_blank">
    <Favicon v-if="favicon" :domain="data.value" :marginRight="true" />{{
      data.value
    }}
  </a>
</template>

<script>
import Favicon from '@/components/shared/Favicon.vue'

export default {
  components: { Favicon },
  props: ['data', 'domain', 'favicon', 'styleOptions'],
  data() {
    return {}
  },
  computed: {
    link() {
      if (this.domain) return this.domain + this.data.value
      return `${this.data.value}`
    },
  },
  methods: {},
}
</script>

<style scoped>
.favicon {
  margin-right: 10px;
}
.link {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
