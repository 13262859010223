<template>
  <span>
    <button @click="addDomainHandler(domain)" class="button_add_site">
      <svg
        id="plus_2_icon"
        class="add_icon"
        width="16px"
        height="16px"
        viewBox="0 0 12 12"
        preserveAspectRatio="none"
      >
        <path
          d="M5.99997 11L6.00003 1M1 5.99999L11 5.99999"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
      {{ $t('buttons.toCompare') }}
    </button>
    <ToastError v-if="addDomainError" :errorData="errorData" />
  </span>
</template>

<script>
/** @TODO */
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ToastError from '@/components/errors/ToastError.vue'

export default {
  data() {
    return {
      addDomainError: false,
    }
  },
  components: {
    ToastError,
  },
  props: {
    domain: String,
  },
  methods: {
    ...mapMutations('authModule', ['setStep', 'setFrom']),
    ...mapActions(['addDomain']),
    async addDomainHandler(similarSite) {
      if (this.addDomainLoading) return
      this.addDomainError = false

      const result = await this.addDomain({
        domains: [similarSite],
        from: 'similar_sites',
      })
      if (result && result.error) {
        if (result.error === 'addMoreSites') {
          return this.$store.dispatch(
            'trialEndedPopupModule/getPlanAndOpen',
            'add_domain'
          )
        }
        this.errorData = {
          name: result.error,
          from: 'similar_sites',
        }
        this.addDomainError = true
      }
    },
    openLogin() {
      this.setFrom('header_login_button')
      this.setStep(1)
    },
  },
}
</script>

<style scoped>
.button_add_site {
  display: none;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: -1px;
}
@media (min-width: 992px) {
  .button_add_site {
    display: inline-block;
    background: none;
    right: auto;
    color: #2684fe;
    opacity: 1;
    text-transform: uppercase;
  }
  .add_icon {
    stroke: #2684fe;
    width: 9px;
    height: 9px;
    margin-top: -2px;
    margin-right: 4px;
  }
  .wrapper_link {
    position: relative;
  }
}
</style>
