<template>
  <Modal
    :isOpen="isSubscriptionModalConfirmOpen"
    :closeHandler="closeConfirmModal"
    :styleModal="{ width: '508px' }"
  >
    <template v-slot:title>
      {{ $t('modals.email.inputEmail') }}
    </template>
    <template v-slot:body>
      <form @submit.prevent="onSubmit">
        <div class="modal_description">
          {{ $t('modals.email.sendCheck') }}
        </div>
        <MyInput
          autocomplete="email"
          type="email"
          :hasError="hasEmailError"
          :value="email"
          @input="email = $event"
          :label="'Email*'"
        >
          <template v-slot:error>
            {{ $t('modals.email.wrongEmail') }}
          </template>
        </MyInput>
        <Checkbox
          class="tos_checkbox"
          :value="tosCheckbox"
          @input="tosCheckbox = $event"
        >
          {{ $t('modals.email.accept') }}
          <a
            class="link"
            target="_blank"
            rel="noreferrer"
            href="https://pro.metrica.guru/tos.pdf"
            >{{ $t('modals.email.terms') }}</a
          >
        </Checkbox>
        <Checkbox
          :value="personalDataCheckbox"
          @input="personalDataCheckbox = $event"
        >
          {{ $t('modals.email.iAgree') }}
          <a
            class="link"
            target="_blank"
            rel="noreferrer"
            href="https://pro.metrica.guru/tos.pdf"
            >{{ $t('modals.email.personalData') }}</a
          >
        </Checkbox>
        <Button
          :disabled="!(!hasEmailError && tosCheckbox && personalDataCheckbox)"
          class="modal_email_btn"
          :color="'blue'"
        >
          {{ $t('buttons.buy') }}
        </Button>
        <div
          class="not_available"
          v-if="!!errorCode && errorCode !== 'already subscribed'"
        >
          {{ $t('modals.email.errorHappened') }}
          <br />
          {{ $t('modals.email.pleaseContact') }}
          <a target="_blank" :href="TELEGRAM_SUPPORT">{{
            $t('modals.email.support')
          }}</a>
          <br />
          {{ $t('modals.email.errorCode') }} {{ errorCode }}
        </div>
        <div class="not_available" v-if="errorCode === 'already subscribed'">
          {{ $t('modals.email.subscriptionAlreadyActivated') }}
        </div>
        <div class="form_description">
          <div>
            {{ $t('modals.email.agreement1') }}
          </div>
          <a href="https://pro.metrica.guru/tos.pdf" target="_blank">
            {{ $t('modals.email.agreement2') }}
          </a>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Modal from '@/components/modals/Modal.vue'
import Button from '@/components/buttons/Button.vue'
import MyInput from '@/components/shared/Input.vue'
import { validateEmail } from '@/helpers/validators'
import { api } from '@/helpers/base/api'
import { TELEGRAM_SUPPORT } from '@/helpers/variables'
import { makeEvent } from '@/helpers/base/amplitude'
import Checkbox from '../shared/Checkbox.vue'

export default {
  name: 'SubscribeConfirmModal',
  data() {
    return {
      tosCheckbox: null,
      personalDataCheckbox: null,
      email: '',
      TELEGRAM_SUPPORT,
      errorCode: null,
    }
  },
  components: {
    Modal,
    Button,
    MyInput,
    Checkbox,
  },
  computed: {
    hasEmailError() {
      return !validateEmail(this.email)
    },
    ...mapState('subscription', [
      'isSubscriptionModalConfirmOpen',
      'from',
      'optionToConfirm',
    ]),
  },
  methods: {
    ...mapMutations('subscription', ['closeConfirmModal']),
    async onSubmit() {
      this.errorCode = null

      makeEvent('subscription', {
        button: 'in_email_buy_button',
      })

      try {
        const { PaymentURL, success, message } = await api.post(
          '/subscription/initPaymentTinkoff',
          {
            planId: this.optionToConfirm.id,
            price: this.optionToConfirm.price,
            email: this.email,
          }
        )
        if (success) {
          window.location.href = PaymentURL
        } else {
          this.errorCode = message
        }
      } catch (error) {
        const errorName = error.response.data.message || 'unknown error'
        this.errorCode = errorName
        console.log(error.response)
      }
    },
  },
}
</script>

<style scoped>
.modal_description {
  font-size: 16px;
  line-height: 19px;
  color: #808493;
  text-align: center;
  margin-bottom: 20px;
}
.modal_email_btn {
  margin-top: 30px;
  margin-bottom: 15px;
}

.form_description {
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #aab2ba;
}
.form_description a {
  text-decoration: underline;
  color: #aab2ba;
}
.tos_checkbox {
  margin-bottom: 10px;
}
.link {
  color: #4e8cf9;
}
.not_available {
  text-align: center;
  margin-bottom: 10px;
}
.tos_checkbox {
  margin-top: 20px;
}
</style>
