<template>
  <button :disabled="disabled" @click="$emit('click', $event)" :class="['inline_btn', bold && 'bold_btn']">
      <slot  />
  </button>
</template>

<script>
export default {
    props: {
        disabled: Boolean,
        bold: Boolean
    },
}
</script>

<style>
.inline_btn{
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    color: #4E8CF9;
    background: transparent;
}
.inline_btn:disabled,
.inline_btn[disabled] {
    opacity: 0.6;
}
.bold_btn{
    font-weight: 500;
}
</style>