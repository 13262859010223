<template>
  <div>
    <div class="divider"></div>
    <div class="error_title" v-html="$t(`error.${errorData.name}.title`)"></div>
    <div
      class="error_body"
      v-html="$t(`error.${errorData.name}.body`)"
      @click="onClick($event)"
    ></div>
  </div>
</template>

<script>
import { API_SERVER } from '@/helpers/variables'

export default {
  data() {
    return {
      accessToken: new URLSearchParams(window.location.search).get(
        'access_token'
      ),
    }
  },
  computed: {},
  props: {
    errorData: {
      type: Object,
      default: () => {
        return {
          name: 'notFoundByRequest',
          specialTitleText: '',
        }
      },
    },
  },
  methods: {
    async onClick(event) {
      const { target } = event
      /** Если баланс закончился и  */
      if (this.errorData.name === 'balanceEnded' && target.nodeName === 'A') {
        const url = `${API_SERVER}more_subscription_info?access_token=${this.accessToken}`
        await fetch(url)
      }
    },
  },
}
</script>

<style scoped>
.divider {
  height: 1px;
  background: #eaebed;
  margin: 12px 0;
}
.error_title {
  font-weight: 500;
  font-size: 18px;
  margin: 12px 0 6px 0;
  color: #151515;
  text-align: center;
}

.error_body {
  font-size: 12px;
  color: #8f8f8f;
  text-align: center;
}

.error_body :v-deep(.paragraph) {
  margin-bottom: 12px;
}
</style>
