<template>
  <button class="dynamic">
    <span>{{ $t('buttons.dynamic') }}</span>
    <img src="/dynamic.svg" alt="c" />
  </button>
</template>

<script>
export default {
  props: {},
}
</script>

<style>
button.dynamic {
  padding: 8px 20px;
  border-radius: 7px;
  background: var(--button-pink);
  color: white;
  font-weight: bold;
}

button.dynamic:hover {
  background: var(--button-pink-hover);
}

button.dynamic > span {
  margin-right: 10px;
}
</style>
