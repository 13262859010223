<template>
  <teleport v-if="isOpen" to="#modals">
    <div
      @keydown="handleEscPress"
      @click="handleCloseModal"
      class="overlay_modal g-0"
    >
      <div
        @click="handleInsideModalClick"
        aria-modal="true"
        tabindex="-1"
        role="dialog"
        class="modal_component"
      >
        <slot></slot>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  data() {
    return {}
  },
  name: 'Modal',
  components: {},
  props: {
    isOpen: Boolean,
    closeHandler: Function,
  },
  methods: {
    handleCloseModal() {
      if (this.closeHandler && this.isOpen) this.closeHandler()
    },
    handleEscPress({ key }) {
      if (key === 'Escape') this.handleCloseModal()
    },
    handleInsideModalClick(event) {
      event.stopPropagation()
    },
  },
}
</script>

<style scoped>
.overlay_modal {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
