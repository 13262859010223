<template>
  <div class="nav" :class="{ collapsed: isCollapsedLocal }">
    <ul>
      <li class="logo">
        <div class="wrapper_logo" @click="updateCollapsedLocal()">
          <div class="icon burger">
            <img :src="BurgerWhiteIcon" />
          </div>
          <img class="logo" src="/logo_white.svg" />
        </div>
      </li>
      <!-- <MenuItem :name="$t('menu.pricePage')" routerLink="/pricing">
        <img :src="PriceIcon" />
      </MenuItem> -->
      <MenuItem :name="$t('menu.extension')" :href="CHROME_EXTENSION_LINK">
        <img :src="ExtensionIcon" />
      </MenuItem>
      <MenuItem :name="$t('menu.telegramBot')" :href="TELEGRAM_URL">
        <img :src="BotIcon" />
      </MenuItem>
      <div class="divider"></div>
      <LocaleSwitcher />
    </ul>
    <ul></ul>
  </div>
</template>

<script>
import ExtensionIcon from '@/assets/icons/menu/Extension.svg'
import BotIcon from '@/assets/icons/menu/Bot.svg'
import PriceIcon from '@/assets/icons/menu/Price.svg'
import BurgerWhiteIcon from '@/assets/icons/menu/BurgerWhite.svg'
import { TELEGRAM_URL, CHROME_EXTENSION_LINK } from '@/helpers/variables'
import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
import MenuItem from './MenuItem.vue'

export default {
  data() {
    return {
      isCollapsedLocal: this.isCollapsed,
      TELEGRAM_URL,
      CHROME_EXTENSION_LINK,
      ExtensionIcon,
      BotIcon,
      PriceIcon,
      BurgerWhiteIcon,
    }
  },
  props: ['isCollapsed'],
  computed: {},
  components: {
    MenuItem,
    LocaleSwitcher,
  },
  methods: {
    updateCollapsedLocal() {
      this.isCollapsedLocal = !this.isCollapsedLocal
      this.$emit('toggleMenu', this.isCollapsedLocal)
    },
  },
}
</script>
<style scoped>
.nav {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 230px;
  background: #2c2738;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-content: space-between;
  z-index: 100;
}

.nav.collapsed {
  width: 50px;
}

.nav ul {
  width: 100%;
  padding: 0;
  margin: 0;
}

.nav ul li {
  list-style: none;
}

.nav ul li:hover {
  background: #393546;
}

.nav ul li.link:hover,
.nav ul li.link.checked {
  background: #393546;
}

.nav ul li > * {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  font-size: 14px;
  line-height: 19px;
  color: #aab2ba;
  height: 100%;
  white-space: nowrap;
}

.nav ul li.link:hover > *,
.nav ul li.link.checked > * {
  color: #ffffff;
}

.nav ul li.logo {
  height: 64px;
  cursor: pointer;
}

.icon {
  margin-right: 25px;
}

.help {
  border-top: 1px solid #565163;
}
.divider {
  height: 1px;
  width: 100%;
  background: #565163;
  margin-bottom: 13px;
}
</style>
