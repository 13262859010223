<template>
  <div class="indicator_background" :class="[type]">
    <span>
      {{ value }}
    </span>
    <div class="indicator" :style="{ width }"></div>
  </div>
</template>

<script>
export default {
  props: ['value', 'width', 'type'],
  components: {},
  methods: {},
}
</script>

<style scoped>
.indicator_background {
  width: 100%;
  padding: 3px 12px;
  margin: -10px;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0e0f10;
}

.marketing-channels {
  padding: 14px 20px 13px 20px;
  margin: 0px;
}

.indicator_background:first-child {
  z-index: 3;
}

.indicator {
  background: #deeeff;
  border-radius: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
</style>
