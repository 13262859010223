<template>
  <WidgetWrapper>
    <div
      v-if="!loading"
      class="d-flex wrapper_title flex-column flex-md-row justify-content-center justify-content-md-between"
    >
      <WidgetTitle
        :title="$t('main.traffic.title')"
        :description="$t('main.traffic.description')"
        :hintOptions="hintOptions"
      />

      <div class="d-flex">
        <DownloadFiles
          class="d-none d-md-flex chart_menu_items"
          :refContent="$refs.content"
        />
        <PeriodPicker />
      </div>
    </div>
    <div class="chart content" ref="content">
      <template v-if="isData">
        <template v-if="isEnoughVisits">
          <LineChart
            :chartData="periodFilter(dataset, traffic.activePeriod)"
            :key="getChartKey(traffic, dataset)"
            :options="{ activePeriod: traffic.activePeriod }"
          >
            <template v-slot:legend>
              <LegendDomains :dataset="dataset" chartName="traffic" />
            </template>
          </LineChart>
        </template>
        <template v-else>
          <NotFoundData v-if="estimatedVisits === 0" />
          <SmallSiteView
            v-else
            :domain="domains[0]"
            :visits="estimatedVisits"
          />
        </template>
      </template>
    </div>
    <WidgetError v-if="!isData && !loading" />
    <SkeletonLoading v-if="loading" name="traffic" />
  </WidgetWrapper>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import WidgetTitle from '@/components/widgets/widgets-helpers/WidgetTitle.vue'
import LineChart from '@/components/line-chart/LineChart.vue'
import LegendDomains from '@/components/line-chart/legends/LegendDomains.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import WidgetError from '@/components/errors/WidgetError.vue'
import WidgetWrapper from '@/components/widgets/widgets-helpers/WidgetWrapper.vue'
import NotFoundData from '@/components/shared/NotFoundData.vue'
import nFormatter from '@/helpers/processing/nFormatter'
import periodFilter from '@/helpers/filters/period-filter'
import PeriodPicker from './PeriodPicker.vue'
import DownloadFiles from './DownloadFiles.vue'
import SmallSiteView from './small-site/SmallSiteView.vue'

export default {
  data() {
    return {}
  },
  components: {
    WidgetTitle,
    LineChart,
    SkeletonLoading,
    WidgetError,
    PeriodPicker,
    DownloadFiles,
    WidgetWrapper,
    SmallSiteView,
    NotFoundData,
    LegendDomains,
  },
  computed: {
    ...mapGetters(['domains']),
    ...mapGetters('traffic', ['dataset']),
    ...mapState('traffic', {
      traffic: state => state,
    }),
    hintOptions() {
      return {
        name: 'metric-traffic',
        title: this.$t('faq.metric-traffic'),
        actualDate: this.traffic.actualDate,
        platforms: this.traffic.platforms,
      }
    },
    isData() {
      return (
        !this.loading && !this.traffic.error.status && this.traffic.data?.length
      )
    },
    isEnoughVisits() {
      if (this.domains.length > 1) return true
      const isPredicted = this.dataset.datasets[0]?.isPredicted
      if (this.visits.length === 0) return false
      if (this.visits.length < 6 && isPredicted) return false
      return true
    },
    estimatedVisits() {
      if (!this.visits?.length) return 0
      return nFormatter(this.visits[this.visits.length - 1])
    },
    visits() {
      return this.dataset.datasets[0]?.data.filter(item => item !== null)
    },
    loading() {
      return this.traffic.loading
    },
  },
  methods: {
    periodFilter,
    getChartKey(traffic, dataset) {
      const datasetDomains = dataset.datasets.map(item => {
        return item.label
      })
      return traffic.activePeriod + datasetDomains.join() + this.$i18n.locale
    },
  },
}
</script>

<style scoped>
.wrapper_title {
  margin-bottom: 16px;
  align-items: center;
}

@media (min-width: 768px) {
  .wrapper_title {
    margin-bottom: 0;
    align-items: flex-start;
  }
  h3 {
    margin-bottom: 24px;
  }
}
.info {
  font-size: 12px;
  color: #a6a6a6;
  font-style: italic;
}
h2 {
  margin-bottom: 8px;
  font-weight: bold;
}
h3 {
  margin-bottom: 24px;
}
.chart_menu_items {
  margin-right: 5px;
}
</style>
