<template>
  <div>
    <template v-if="format === 'absolute'">
      {{ data.value > 0 ? nFormatter(data.value) : '-' }}
    </template>
    <template v-if="format === 'share'">
      {{ data.value }}%
      <div v-if="data.value" class="indicator_background">
        <div
          class="indicator_active"
          :style="{ width: data.value + '%' }"
        ></div>
      </div>
    </template>
  </div>
</template>

<script>
import nFormatter from '@/helpers/processing/nFormatter'

export default {
  components: {},
  props: {
    data: Object,
    format: {
      type: String || Number,
      default: 'absolute',
    },
  },
  data() {
    return {}
  },
  methods: {
    nFormatter,
  },
}
</script>

<style scoped>
/* div {
  color: #0e0f10;
} */
.indicator_background {
  background: #f6f7fa;
  border-radius: 7px;
  height: 4px;
  width: 56px;
  margin-top: 2px;
}
.indicator_active {
  background: #2684fe;
  height: 4px;
  border-radius: 7px;
}
</style>
