<template>
  <div id="app">
    <router-view />
    <Authorization />
    <SubscribeCancel />
    <SubscribeConfirmModal />
    <AddDomainModal />
    <NotifyWindow v-if="notifications.isVisible" />
    <!-- <Modal
      options="full"
      :closeHandler="close"
      :isOpen="trialEndedPopupModule.isOpen"
    >
      <template v-slot:body>
        <div class="p-5"><PricesPage /></div>
      </template>
    </Modal> -->
    <div id="modals"></div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import Authorization from '@/components/auth/Authorization.vue'
import SubscribeCancel from '@/components/subscription/SubscribeCancel.vue'
import SubscribeConfirmModal from '@/components/subscription/SubscribeConfirmModal.vue'
import AddDomainModal from '@/components/add-domain/AddDomainModal.vue'
import NotifyWindow from '@/components/notifications/NotifyWindow.vue'
import PricesPage from '@/views/PricesPage.vue'
import Modal from '@/components/modals/Modal.vue'

import { configureAmplitude } from './helpers/base/amplitude'

export default {
  name: 'App',
  computed: {
    ...mapGetters('userInfo', ['userInfo']),
    ...mapState('trialEndedPopupModule', {
      trialEndedPopupModule: state => state,
    }),
    ...mapState('notifications', {
      notifications: state => state,
    }),
  },
  created() {
    configureAmplitude(this.userInfo?.userId)
    this.loadSubscription()
  },
  components: {
    Authorization,
    SubscribeCancel,
    SubscribeConfirmModal,
    AddDomainModal,
    Modal,
    PricesPage,
    NotifyWindow,
  },
  methods: {
    ...mapMutations('trialEndedPopupModule', ['close']),
    ...mapActions('subscription', ['loadSubscription']),
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap');
@import './assets/styles/variables.css';
@import './assets/styles/metrics.css';

:root {
  scroll-behavior: auto;
}

html {
  touch-action: none;
}
#app {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: white;
  overflow-x: hidden;
  color: var(--primary-color);
  font-size: 14px;
}
button {
  outline: none;
  border: none;
  color: #0e0f10;
}
button:focus {
  outline: none;
}
a,
button:focus-visible {
  outline-offset: 0px;
  outline: none;
}
input {
  outline: none;
  border: none;
  font-size: 12px;
}
h2 {
  font-weight: 500;
  font-size: 24px;
}
h3 {
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  color: var(--text-secondary2);
}
a {
  color: var(--main-color);
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.table {
  margin-bottom: 0;
  color: #0e0f10;
}
.table > :not(:first-child) {
  border-top: none;
}
.table > :not(caption) > * > * {
  border-bottom-width: 0;
  padding: 0.5rem 0;
}
.table tr,
.table th,
.table td {
  padding: 0.5rem;
}
dl,
ol,
ul {
  margin-bottom: 0;
}
</style>
