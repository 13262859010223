<template>
  <Modal2 :closeHandler="close" :isOpen="notifications.isVisible">
    <div class="wrapper">
      <div class="pb-3">
        <ShareInfo class="share_info" />
      </div>
      <div class="title mb-3">
        {{ $t(`components.notifications.notifications`) }}
      </div>
      <div v-if="notifications.data.length">
        <div>
          <NotificationItem
            v-for="item of notifications.data"
            class="notification-item"
            :key="item"
            :title="getText(item, 'title')"
            :description="getText(item, 'description')"
            :date="getDate(item.date)"
            :link="getLink(item)"
          />
        </div>
      </div>
      <SkeletonLoading v-if="notifications.isLoading" name="notifications" />
      <div
        class="not_found text-center"
        v-if="!notifications.isLoading && !notifications.data.length"
      >
        {{ $t('error.notFoundByRequest.title') }}
      </div>
    </div>
  </Modal2>
</template>

<script>
import { mapState } from 'vuex'
import Modal2 from '@/components/modals/Modal2.vue'
import ShareInfo from '@/components/shared/ShareInfo.vue'
import NotificationItem from '@/components/notifications/NotificationItem.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'

export default {
  components: {
    Modal2,
    ShareInfo,
    NotificationItem,
    SkeletonLoading,
  },
  mounted() {
    this.$store.dispatch('notifications/load')
  },
  computed: {
    ...mapState('notifications', {
      notifications: state => state,
    }),
  },
  methods: {
    close() {
      this.$store.dispatch('notifications/hide')
    },
    getText(item, key) {
      if (item.type === 'changelog') {
        const version = item.version.replace('.', '')
        return this.$t(
          `components.notifications.types.changelog.v${version}.${key}`
        )
      }
      if (item.type === 'rewardSubscription')
        if (key === 'title')
          return `${this.$tc(`helpers.date.days`, item.countDays)} ${this.$t(
            `components.notifications.types.rewardSubscription.${key}`
          )}`
      return this.$t(`components.notifications.types.rewardSubscription.${key}`)
    },
    getLink(item) {
      if (item.type === 'changelog') {
        const version = item.version.replace('.', '')
        return this.$t(
          `components.notifications.types.changelog.v${version}.link`
        )
      }
    },
    getDate(date) {
      const newData = new Date(date)
      return `${newData.getDate()}.${newData.getMonth() +
        1}.${newData.getFullYear()}`
    },
  },
}
</script>

<style scoped>
.wrapper {
  background: white;
  position: absolute;
  right: 19%;
  top: 76px;
  width: 472px;
  padding: 20px;
  box-shadow: 0px 4px 20px rgba(200, 210, 236, 0.2);
  border-radius: 7px;
  max-height: 520px;
  overflow-y: auto;
}
.title {
  color: #aab2ba;
  font-weight: bold;
}
.notification-item {
  margin-bottom: 10px;
}
.not_found {
  color: #aab2ba;
}
</style>
