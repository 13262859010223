/* eslint-disable no-undef */
import amplitude from 'amplitude-js'
import { AMPLITUDE_KEY } from '../variables'

export const makeEvent = (key, value, callback) => {
  try {
    amplitude.getInstance().logEvent(key, value, callback)
  } catch (err) {
    //
  }
}

export const setProperties = properties => {
  try {
    amplitude.getInstance().setUserProperties(properties)
  } catch (err) {
    //
  }
}

window.amplitude = amplitude

export const logoutUserAmplitude = () => {
  try {
    amplitude.getInstance().setUserId(null) // not string 'null'
    amplitude.getInstance().regenerateDeviceId()
  } catch (err) {
    //
  }
}

export const setUserIdAmplitude = userId => {
  try {
    if (userId) {
      amplitude.getInstance().setUserId(userId)
    }
  } catch (err) {
    //
  }
}

export const configureAmplitude = userId => {
  try {
    const API_KEY = AMPLITUDE_KEY

    amplitude.getInstance().init(API_KEY, null, {
      includeUtm: true,
      includeReferrer: true,
      includeGclid: true,
    })

    const identify = new amplitude.Identify()
    amplitude.getInstance().identify(identify)
    setUserIdAmplitude(userId)
    makeEvent('session start')
  } catch (err) {
    //
    console.log(err)
  }
}
