<template>
  <div class="add_domain">
    <svg
      id="plus_icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      preserveAspectRatio="none"
    >
      <circle cx="12" cy="12" r="12" fill="rgba(0,0,0,0)" />
      <path
        d="M12 17L12 7M7 12L17 12"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
    {{ $t('buttons.addSite') }}
  </div>
</template>

<script>

export default {
  data() {
    return {}
  },
  components: {},
  computed: {},
  mounted() {},
  methods: {},
}
</script>

<style scoped>
.add_domain {
  cursor: pointer;
  position: fixed;
  right: 0;
  left: 0;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 14px;
  border-radius: 4px;
  bottom: 0;
  background: #ff72b2;
  z-index: 99;
}
.plus_icon {
  width: 12px;
  height: 12px;
  margin-right: 10px;
  stroke: white;
}
/* .add_site span {
  margin-top: 1px;
}

.plus_icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  stroke: white;
} */
</style>
