<template>
  <div class="col">
    <div class="d-flex flex-column">
      <div class="wrapper_select">
        <img :src="getFavicon(picked)" class="favicon" />
        <select :value="picked" @change="onChange($event)">
          <option
            v-for="(geoItemOption, domain) in dataset"
            :key="domain"
            :value="domain"
          >
            {{ domain }}
          </option>
        </select>
      </div>
      <div
        v-if="dataset[picked] && Object.keys(dataset[picked]).length"
        class="wrapper_domain_content"
      >
        <WidgetListTitle
          class="topics"
          :firstName="$t('main.geo.widgetListTitle')"
          :secondName="$t('main.geo.widgetListValue')"
        />
        <template
          v-for="(value, country) in dataset[picked]"
          :key="'indicator' + country"
        >
          <div class="country">
            <Flag :country="country" />
            <span class="country">{{ country }}</span>
          </div>
          <Indicator :width="value.share" class="w-100" />
          <div class="traffic text-end">{{ value.share }}%</div>
        </template>
      </div>
      <div v-else>
        <WidgetError />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import getFavicon from '@/helpers/domains/getFavicon'
import Indicator from '@/components/widgets/widgets-helpers/Indicator.vue'
import WidgetError from '@/components/errors/WidgetError.vue'
import WidgetListTitle from '@/components/widgets/widgets-helpers/WidgetListTitle.vue'
import Flag from '@/components/shared/Flag.vue'

export default {
  data() {
    return {}
  },
  components: {
    Indicator,
    WidgetError,
    WidgetListTitle,
    Flag,
  },
  props: ['picked', 'mutation', 'dataset'],
  methods: {
    getFavicon,
    onChange(event) {
      const option = event.target.options[event.target.selectedIndex]
      const value = option.getAttribute('value')
      this.mutation(value)
    },
  },
}
</script>

<style scoped>
.title_country {
  font-size: 13px;
  color: #aeb0ba;
  margin-bottom: 7px;
}

.point {
  position: relative;
  max-width: 110px;
}

.indicator {
  margin: 0;
}

.item {
  margin-bottom: 8px;
  margin-top: 8px;
  white-space: nowrap;
}

.wrapper_select {
  position: relative;
  margin: 10px 0 25px;
}
.favicon {
  width: 16px;
  height: 16px;
  top: 14px;
  left: 15px;
  position: absolute;
}

.traffic {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #0e0f10;
}

.country {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

select {
  background: white;
  color: #2c2738;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #eaeef8;
  padding: 10px 40px 10px 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 14px;
  appearance: none;
  background-image: url('/arrow_gray.svg');
  background-size: 12px;
  width: 100%;

  background-repeat: no-repeat;
  background-position: center right 14px;
}

select:focus {
  outline: none;
}
select > option {
  color: #151515;
  border: none;
  font-size: 14px;
}

.wrapper_domain_content {
  display: grid;
  grid: min-content / max-content 1fr max-content;
  gap: 15px 20px;
  align-items: center;
}

.topics {
  grid-column: span 3;
}
</style>
