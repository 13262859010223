<template>
  <div class="page page-price">
    <h1 class="prices_title text-center">{{ $t('pricePage.title') }}</h1>
    <div class="api-btn">
      <a
        class="api_link"
        href="https://tranquil-houseboat-b66.notion.site/Metrica-API-V2-7a362f0f3fd64be4ab6623670293fec9"
        target="_blank"
      >
        <Button color="link">
          {{ $t('pricePage.API') }}
        </Button>
      </a>
    </div>
    <div class="page-price__nav">
      <PriceNavigationItem
        @changeActive="navigationHandler"
        :isActive="navigationActive == item.id"
        :item="item"
        v-for="item in navigationData"
        :key="item.duration + item.id"
      />
    </div>
    <div class="badges">
      <!-- @TODO loading -->
      <!-- <div v-if="isLoading">{{ $t('components.loading.title') }}</div> -->
      <div class="table">
        <div class="table-header">
          <div class="tr">
            <div class="td">
              <div class="open-mobile">
                <strong>{{ $t('pricePage.opportunities') }}</strong>
              </div>
            </div>
            <div class="td text-muted">
              {{ $t('pricePage.noSubscription') }}
            </div>
            <div class="td"><PrimeText text="Metrica +" /></div>
          </div>
        </div>
        <div class="table-body">
          <div class="tr price-row b-line hide-mobile">
            <div class="td">
              <strong>{{ $t('pricePage.price') }}</strong>
            </div>
            <div class="td mons-price">0 ₽</div>
            <div class="td">
              <div class="mons-price">
                {{ getActiveMonsPrice() }}
                ₽ / {{ $t('pricePage.month') }}
              </div>
              <div class="total-price">
                {{ $t('pricePage.total') }}
                {{ navigationData[navigationActive - 1].price }} ₽
              </div>
            </div>
          </div>
          <div class="tr">
            <div class="td">{{ $tc('pricePage.advantages.traffic', 1) }}</div>
            <div class="td text-muted">
              {{ $tc('helpers.date.year', 1) }}
            </div>
            <div class="td">
              {{ $tc('helpers.date.year', 3) }}
            </div>
          </div>
          <div class="tr">
            <div class="td">
              <span class="pb-2">{{
                $t('pricePage.advantages.historicalData')
              }}</span>
              <ul>
                <li>{{ $t('pricePage.advantages.marketingChannels') }}</li>
                <li>{{ $t('pricePage.advantages.engagement') }}</li>
                <li>{{ $t('pricePage.advantages.geo') }}</li>
              </ul>
            </div>
            <div class="td text-muted">
              {{ $tc('helpers.date.month', 1) }}
            </div>
            <div class="td">
              {{ $t('pricePage.advantages.marketingPeriod') }}
            </div>
          </div>
          <div class="tr">
            <div class="td">
              {{ $t('pricePage.advantages.uniqueUsers') }}
            </div>
            <div class="td text-muted">—</div>
            <div class="td checked-arrow">
              <img :src="CheckedIcon" />
            </div>
          </div>
          <div class="tr">
            <div class="td">
              {{ $t('pricePage.advantages.compare') }}
            </div>
            <div class="td text-muted">
              {{ $tc('pricePage.advantages.sites', 3) }}
            </div>
            <div class="td">
              {{ $tc('pricePage.advantages.sites', 50) }}
            </div>
          </div>
          <div class="tr">
            <div class="td">
              {{ $t('pricePage.advantages.unload') }}
            </div>
            <div class="td text-muted">—</div>
            <div class="td checked-arrow">
              <img :src="CheckedIcon" />
            </div>
          </div>
          <div class="tr">
            <div class="td">
              {{ $t('pricePage.advantages.countries') }}
            </div>
            <div class="td text-muted">5</div>
            <div class="td">
              {{ $t('pricePage.advantages.all') }}
            </div>
          </div>
          <div class="tr">
            <div class="td">
              {{ $t('pricePage.advantages.referrals') }}
            </div>
            <div class="td text-muted">5</div>
            <div class="td">
              {{ $t('pricePage.advantages.all') }}
            </div>
          </div>
          <div class="tr">
            <div class="td">
              {{ $t('pricePage.advantages.ecosystem') }}
            </div>
            <div class="td text-muted">6</div>
            <div class="td">
              {{ $t('pricePage.advantages.all') }}
            </div>
          </div>
          <div class="tr">
            <div class="td">
              {{ $t('pricePage.advantages.keywords') }}
            </div>
            <div class="td text-muted">5</div>
            <div class="td">
              {{ $t('pricePage.advantages.all') }}
            </div>
          </div>
          <div class="tr">
            <div class="td">
              {{ $t('pricePage.advantages.similarSites') }}
            </div>
            <div class="td text-muted">16</div>
            <div class="td">
              {{ $t('pricePage.advantages.all') }}
            </div>
          </div>
          <div class="tr b-line">
            <div class="td">
              <span class="pb-2 text-center">
                <a :href="CHROME_EXTENSION_LINK" target="_blank">{{
                  $t('pricePage.advantages.extension.title')
                }}</a></span
              >
              <ul>
                <li>{{ $t('pricePage.advantages.extension.revenue') }}</li>
                <li>
                  {{ $t('pricePage.advantages.extension.historicalData') }}
                </li>
              </ul>
            </div>
            <div class="td text-muted">
              —
            </div>
            <div class="td checked-arrow">
              <img :src="CheckedIcon" />
            </div>
          </div>
          <div class="tr price-row">
            <div class="td hide-mobile"></div>
            <div class="td hide-mobile"></div>
            <div class="td">
              <Button @click="openModal" class="submit_btn" color="blue">
                {{ $t('buttons.subscribe') }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      :styleModal="{ minHeight: 'auto' }"
      :closeHandler="closeCancelModal"
      :isOpen="isCancelModalOpen"
    >
      <template v-slot:title>
        {{ $t('pricePage.cancelSubscription') }}
      </template>
      <template v-slot:body
        >{{ $t('pricePage.subscriptionSuccess') }}
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Button from '@/components/buttons/Button.vue'
import Modal from '@/components/modals/Modal.vue'
import { api } from '@/helpers/base/api'
import { TELEGRAM_SUPPORT, CHROME_EXTENSION_LINK } from '@/helpers/variables'
import CheckedIcon from '@/assets/icons/base/checkmark.svg'
import PriceNavigationItem from '@/views/price-page/PriceNavigationItem.vue'
import PrimeText from '@/components/shared/PrimeText.vue'

export default {
  data() {
    return {
      CheckedIcon,
      isCancelModalOpen: false,
      isLoading: false,
      email: '',
      TELEGRAM_SUPPORT,
      CHROME_EXTENSION_LINK,
      navigationActive: 2,
    }
  },
  name: 'Prices',
  components: {
    Button,
    Modal,
    PriceNavigationItem,
    PrimeText,
  },
  async mounted() {
    this.isLoading = true

    const { success, data } = await api.get(
      '/subscription/getSubscriptionPlans'
    )

    if (success) this.setNavigationData(data)
    this.isLoading = false
  },
  computed: {
    ...mapGetters('userInfo', ['isLoggedIn']),
    ...mapGetters('subscription', ['isTrial', 'navigationData']),
  },
  methods: {
    getActiveMonsPrice() {
      return Math.trunc(
        this.navigationData[this.navigationActive - 1].price /
          Math.trunc(
            this.navigationData[this.navigationActive - 1].duration / 30
          )
      )
    },
    navigationHandler(idx) {
      this.navigationActive = idx
    },
    openModal() {
      if (this.isLoggedIn) {
        const actualPlan = this.navigationData[this.navigationActive - 1]
        // @TODO
        this.close()
        this.setOptionToConfirm(actualPlan)
        this.openConfirmModal({
          plan: actualPlan.plan,
          period: actualPlan.duration,
        })
      } else {
        this.setStep(1)
      }
    },
    closeCancelModal() {
      this.isCancelModalOpen = false
    },
    ...mapMutations('subscription', [
      'openCancelSubscriptionModal',
      'openConfirmModal',
      'setOptionToConfirm',
      'setNavigationData',
    ]),
    ...mapMutations('trialEndedPopupModule', ['close']),
    ...mapMutations('authModule', ['setStep']),
  },
}
</script>

<style scoped>
.page :deep(.text-transparent) {
  color: transparent !important;
}
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 79px;
  padding-top: 40px;
  min-height: 100vh;
  background: #ffffff;
  border-radius: 7px;
  position: relative;
}
.api-btn {
  position: absolute;
  right: 50px;
  top: 50px;
}
.api_link:focus-visible {
  border: none;
  outline: none;
}
.prices_title {
  font-weight: bold;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: #0e0f10;
  margin-bottom: 20px;
}
.page-price__nav {
  background: #f5f6fa;
  border: 1px solid #eaeef8;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 4px;
  width: 100%;
  max-width: 707px;
}
.badges {
  width: 990px;
  max-width: 100%;
}
.table {
  width: 100%;
}
.table-header .tr {
  /* border-bottom: 1px solid #eaeef8; */
  padding: 15px 20px 0;
}
.table-header .tr,
.table-body .tr {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.b-line {
  border-bottom: 1px solid #eaeef8;
}
.table-body .tr {
  transition: all 0.2s linear;
  padding: 0;
  /* margin: 4px 0; */
}
.table-body .tr:not(.price-row):hover {
  background: #f9f9fc;
  border-radius: 7px;
}
.table .td {
  width: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 9px;
}
.table-header .td {
  font-weight: 600;
  font-size: 16px;
}
.table .tr .td:first-child {
  align-items: flex-start;
}
.table .tr .td:last-child {
  align-items: center;
  text-align: center;
}
.table-body .tr:first-child {
  border-top: 1px solid #eaeef8;
  border-bottom: 1px solid #eaeef8;
}
.table-body .tr:first-child .td:first-child {
  font-size: 16px;
}
.table-body .tr .td:nth-child(2) {
  border-left: 1px solid #eaeef8;
  border-right: 1px solid #eaeef8;
}
.table-body .tr .td:first-child {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #0e0f10;
  padding-left: 30px;
}
.table-body .tr:last-child .td {
  border: none;
}
.table-body .tr .td:last-child {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #0e0f10;
}
.checked-arrow :deep(path) {
  fill: #2684fe;
}
.mons-price {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #0e0f10;
}
.total-price {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #aab2ba;
}
.open-mobile {
  display: none;
}
.table .tr .td ul {
  text-align: left;
}
@media (max-width: 785px) {
  .table .table-header {
    margin-top: 20px;
  }
  /* .table .td:first-child {
    align-items: center;
    text-align: center;
  } */
  .table .tr .td:first-child {
    align-items: center;
    text-align: center;
  }
  .open-mobile {
    display: unset;
  }
  .prices_title {
    margin-bottom: 0;
  }
  .api-btn {
    position: unset;
  }
  .hide-mobile,
  .tr .text-muted {
    display: none !important;
  }
  .tr .td {
    width: 50%;
  }
  .page-price__nav {
    flex-wrap: wrap;
    position: fixed;
    border: none;
    border-top: 3px solid #f8f8fa;
    bottom: 45px;
    background: white;
    z-index: 98;
    left: 0;
    right: 0;
    border-radius: 7px 7px 0 0;
    margin-bottom: 0;
    max-width: 100%;
  }
  .table-header .tr {
    padding: 13px;
  }
  .submit_btn {
    position: fixed;
    bottom: 0;
    z-index: 98;
    left: 0;
    right: 0;
  }
  .main_btn.link {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
  }
  .table-body .price-row {
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 350px) {
  .nav-item {
    padding-right: 0;
  }
}
</style>
