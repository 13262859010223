<template>
  <div
    class="wrapper_labels col d-flex flex-wrap justify-content-center"
    :class="[type]"
  >
    <div
      class="d-flex"
      :class="{ legend_spaces: legends.length > 1 }"
      v-for="legend of legends"
      :key="legend.label"
    >
      <Label
        :removeButton="removeButton"
        :legend="legend"
        :type="type"
        :link="link"
        :chartName="chartName"
        class="legend"
        @updateDataset="updateDataset"
        @removeDomain="removeDomainHandler"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import getColor from '@/helpers/store/getColor'
import Label from './Label.vue'

export default {
  data() {
    return {
      removeLoading: false,
    }
  },
  props: {
    dataset: Object,
    removeButton: {
      type: Boolean,
      default: true,
    },
    link: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'links',
    },
    chartName: String,
  },
  components: {
    Label,
  },
  computed: {
    legends() {
      const labels = this.dataset.datasets.map((el, index) => {
        return {
          label: el.label,
          index,
          color: el.borderColor,
        }
      })
      return labels
    },
  },
  methods: {
    ...mapActions(['removeDomain']),
    getColor,
    updateDataset(index) {
      this.$parent.$emit('updateDataset', index)
    },
    async removeDomainHandler(domain) {
      if (this.removeLoading) return
      this.removeLoading = true
      await this.removeDomain({ domains: [domain] })
      this.removeLoading = false
    },
  },
}
</script>

<style scoped>
.legend_spaces {
  margin: 8px 34px 8px 0;
}
.legend_spaces:last-child {
  margin-right: 0;
}

@media (max-width: 768px) {
  .legend_spaces {
    margin-right: 16px;
  }
}

.checkbox_button .legend_spaces {
  margin-right: 10px;
}
</style>
