<template>
  <WidgetWrapper>
    <WidgetTitle
      v-if="!loading"
      :title="$t('main.organicVsPaid.title')"
      :description="$t('main.organicVsPaid.description')"
    />
    <div v-if="isData" class="content" ref="content">
      <div class="wrapper_domains d-flex flex-wrap g-0">
        <div class="invisible col-4">empty row</div>
        <WidgetListTitle
          class="col"
          :firstName="$t('main.organicVsPaid.widgetListTitle')"
          :secondName="$t('main.organicVsPaid.widgetListValue')"
        />
        <div
          class="d-flex item align-items-center col-12 g-0"
          v-for="item of dataOnActualDate"
          :key="item.domainName"
        >
          <div
            class="wrapper_domain d-flex flex-nowrap align-items-center col-4"
          >
            <Domain maxWidth="maxWidth" :domain="item.domainName" />
          </div>
          <Indicator2
            :share1="getShare(item, 'organic')"
            :share2="getShare(item, 'paid')"
          />
        </div>
      </div>
    </div>
    <WidgetError v-if="!isData && !loading" />
    <SkeletonLoading v-if="loading" name="organicPaid" />
  </WidgetWrapper>
</template>

<script>
import WidgetWrapper from '@/components/widgets/widgets-helpers/WidgetWrapper.vue'
import WidgetTitle from '@/components/widgets/widgets-helpers/WidgetTitle.vue'
import WidgetListTitle from '@/components/widgets/widgets-helpers/WidgetListTitle.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import WidgetError from '@/components/errors/WidgetError.vue'
import Domain from '@/components/widgets/widgets-helpers/Domain.vue'
import Indicator2 from '@/components/widgets/widgets-helpers/Indicator2.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {}
  },
  components: {
    WidgetTitle,
    SkeletonLoading,
    WidgetError,
    WidgetListTitle,
    WidgetWrapper,
    Domain,
    Indicator2,
  },
  computed: {
    ...mapGetters('paidOrganic', ['dataOnActualDate']),
    ...mapState('additionalData', {
      additionalData: state => state,
    }),
    isData() {
      return !this.loading && !this.additionalData.error.status
    },
    loading() {
      // return true
      return this.additionalData.loading
    },
  },
  methods: {
    getShare(item, type) {
      const share = Math.round(item?.data?.[0]?.data?.[type])
      if (share === 0) return 0
      return share || null
    },
  },
}
</script>

<style scoped>
.item {
  padding-bottom: 8px;
  padding-top: 16px;
}

.item + .item {
  padding-top: 8px;
}
.item:last-child {
  padding-bottom: 16px;
}

.wrapper_domains {
  min-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
