<template>
  <div class="d-flex">
    <DropDown class="cloud" type="origin" title="">
      <template v-slot:items>
        <DropDownItem
          @selectItem="selectItemExcel"
          value="downloadExcel"
          :isLocked="!subscriptionActive"
        >
          <a
            :href="excelLink"
            class="d-flex w-100"
            @click="selectItemExcel"
            target="_blank"
            >{{ $t('main.traffic.downloadExcel') }}
          </a>
        </DropDownItem>
        <span @click="onPictureDownloadEvent">
          <DropDownItem
            @selectItem="onPictureDownload"
            :text="$t('main.traffic.downloadPicture')"
            value="downloadImage"
            :isLocked="!subscriptionActive"
          />
        </span>
      </template>
    </DropDown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { API_SERVER } from '@/helpers/variables'
import DropDown from '@/components/shared/drop-down/DropDown.vue'
import DropDownItem from '@/components/shared/drop-down/DropDownItem.vue'
import { makeEvent } from '@/helpers/base/amplitude'

export default {
  data() {
    return {}
  },
  components: {
    DropDown,
    DropDownItem,
  },
  props: ['refContent'],
  computed: {
    ...mapGetters(['viewId']),
    ...mapGetters(['subscriptionActive']),
    excelLink() {
      return `${API_SERVER}/service/getExcelVisits?id=${this.viewId}`
    },
  },
  methods: {
    selectItemExcel(event) {
      makeEvent('document download', {
        type: 'excel',
      })
      if (!this.subscriptionActive) {
        event.preventDefault()
        return this.showBitePopup()
      }
    },
    async onPictureDownload() {
      const options = {
        type: 'dataURL',
      }
      this.output = await this.$html2canvas(this.refContent, options)
      const link = document.createElement('a')
      link.download = 'metrica_visits.png'
      link.href = this.output
      link.click()
    },
    onPictureDownloadEvent() {
      makeEvent('document download', {
        type: 'picture',
      })
    },
    showBitePopup() {
      this.$store.dispatch(
        'trialEndedPopupModule/getPlanAndOpen',
        'download_document'
      )
    },
  },
}
</script>

<style scoped>
:deep(.cloud) {
  border: none !important;
}
:deep(.cloud button) {
  color: white;
  background-image: url('/download_icon.svg') !important;
  background-size: 24px !important;
  background-repeat: no-repeat !important;
  background-position: center 11px !important;
}
:deep(.cloud .toggle) {
  padding: 0 !important;
  height: 100%;
}

:deep(.cloud .arrow) {
  opacity: 0;
}
</style>
