<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th class="table_first_row" style="width: 20%" scope="col"></th>
          <template v-for="data of dataset" :key="data.datasets[0].label">
            <th style="width: 13%" scope="col">
              <img :src="channelsInfo[data.datasets[0].label].icon" />
              <span
                class="name"
                v-html="channelsInfo[data.datasets[0].label].title"
              ></span>

              <WidgetHint
                :hintOptions="{
                  name: channelsInfo[data.datasets[0].label].hintName,
                  title: channelsInfo[data.datasets[0].label].hintTitle,
                  actualDate,
                  platforms,
                }"
              />
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(domain, siteIndex) in dataset[0].labels" :key="siteIndex">
          <th class="table_first_row" style="width: 20%" scope="row">
            <Domain :maxWidth="'maxWidth'" :domain="domain" />
          </th>
          <template v-for="channel of dataset" :key="channel.datasets[0].label">
            <td>
              <IndicatorTable
                :value="valueHandler(getValue(channel, siteIndex, value_type))"
                :width="getWidth(channel, siteIndex, value_type)"
                type="marketing-channels"
              />
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Favicon from '@/components/shared/Favicon.vue'
import Domain from '@/components/widgets/widgets-helpers/Domain.vue'
import WidgetHint from '@/components/widgets/widgets-helpers/WidgetHint.vue'
import IndicatorTable from '@/components/widgets/widgets-helpers/IndicatorTable.vue'
import nFormatter from '@/helpers/processing/nFormatter'

export default {
  data() {
    return {}
  },
  components: {
    Favicon,
    Domain,
    IndicatorTable,
    WidgetHint,
  },
  props: [
    'channelsInfo',
    'valueHandler',
    'value_type',
    'dataset',
    'platforms',
    'actualDate',
  ],
  methods: {
    nFormatter,
    getValue(channel, siteIndex, value_type = 'percent') {
      const value = channel.datasets[0].data[siteIndex][value_type]
      if (!value) return null
      const postFix = value_type === 'percent' ? '%' : ''
      const formattedValue = this.nFormatter(value)
      return postFix ? value + postFix : formattedValue
    },
    getWidth(channel, siteIndex, value_type) {
      const value = channel.datasets[0].data[siteIndex][value_type]
      if (!value) return 0
      if (value_type === 'absolute')
        return `${channel.datasets[0].data[siteIndex].absolute_percent}%`
      return `${channel.datasets[0].data[siteIndex][value_type]}%`
    },
  },
}
</script>

<style scoped>
table {
  font-size: 14px;
}
table th,
table td {
  font-weight: 400;
  border-top: 1px solid #eaeef8;
}

thead tr {
  background: #f9f9fc;
  border-radius: 4px;
  color: #808493;
  font-size: 13px;
  white-space: nowrap;
}

thead th {
  vertical-align: middle !important;
  padding: 14px;
}

thead th div {
  display: table-cell;
  vertical-align: middle;
}

thead th div svg {
  cursor: pointer;
}

thead th .name {
  padding-left: 7px;
}

thead tr th,
tbody tr:first-child th,
tbody tr td {
  border: none;
}

tbody th,
tbody td {
  padding-top: 4px;
  padding-bottom: 2px;
  vertical-align: middle;
}

tbody td span {
  margin-bottom: 5px;
}

.table-responsive::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar-thumb {
  height: 10px;
  border-radius: 50px;
  background-clip: padding-box;
  border: 3px solid white;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #d6dadf;
}
</style>
