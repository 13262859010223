<template>
  <div class="indicator_background">
    <div class="indicator" :style="{ width }"></div>
  </div>
</template>

<script>
export default {
  props: ['width'],
  components: {},
  methods: {},
}
</script>

<style scoped>
.indicator_background {
  width: 100%;
  /* padding: 10px 12px;
  margin: -10px; */
  padding: 3px;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0e0f10;
  background: #deeeff;
  border-radius: 4px;
}

.indicator {
  background: #2684fe;
  border-radius: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
