<template>
  <div>
    <GrowRate class="grow_rate ps-2" :change="data.value" />
  </div>
</template>

<script>
import GrowRate from '@/components/shared/GrowRate.vue'

export default {
  components: {
    GrowRate,
  },
  props: ['data'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
:deep(.grow_rate) {
  font-size: 16px;
}
</style>
