<template>
  <WidgetWrapper>
    <WidgetTitle
      v-if="!loading"
      :title="$t('main.referrals.title')"
      :description="$t('main.referrals.description')"
      :hintOptions="hintOptions"
    />
    <template v-if="isData">
      <div v-if="isData" class="content" ref="content">
        <WidgetListTitle
          class="title mb-2 mt-2"
          :firstName="$t('main.referrals.widgetListTitle')"
          :secondName="$t('main.referrals.widgetListValue')"
        />
        <template
          v-for="item of dataOnActualDateAllLocal.slice(0, 5)"
          :key="item.domainName"
        >
          <DomainShareItem
            :domain="processLink(item.referralUrl)"
            :share="nFormatter(item.traffic)"
            :indicator="false"
          />
        </template>
      </div>
      <ShowMoreButton
        class="show_more_button"
        @clickCustom="onModalOpen()"
        :totalCount="totalCount"
      />
    </template>
    <Modal
      type="table"
      :closeHandler="close => (isModalOpen = false)"
      :isOpen="isModalOpen"
    >
      <template v-slot:title>
        {{ $t('main.referrals.title') }}
      </template>
      <template v-slot:body> <TableRefferal /> </template>
    </Modal>
    <WidgetError v-if="!isData && !loading" />
    <SkeletonLoading v-if="loading" name="sources" />
  </WidgetWrapper>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import WidgetTitle from '@/components/widgets/widgets-helpers/WidgetTitle.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import WidgetError from '@/components/errors/WidgetError.vue'
import WidgetListTitle from '@/components/widgets/widgets-helpers/WidgetListTitle.vue'
import DomainShareItem from '@/components/widgets/traffic-sources/DomainShareItem.vue'
import ShowMoreButton from '@/components/buttons/ShowMoreButton.vue'
import WidgetWrapper from '@/components/widgets/widgets-helpers/WidgetWrapper.vue'
import TableRefferal from '@/components/tables/TableRefferal.vue'
import nFormatter from '@/helpers/processing/nFormatter'
import Modal from '@/components/modals/Modal.vue'

export default {
  data() {
    return {
      isModalOpen: false,
    }
  },
  components: {
    WidgetTitle,
    SkeletonLoading,
    WidgetError,
    WidgetListTitle,
    DomainShareItem,
    ShowMoreButton,
    WidgetWrapper,
    TableRefferal,
    Modal,
  },
  computed: {
    ...mapGetters(['subscriptionActive']),
    ...mapGetters('referrals', ['dataOnActualDateAll', 'dataOnActualDate']),
    ...mapState('referrals', {
      referrals: state => state,
    }),
    hintOptions() {
      return {
        name: 'metric-referral-sites',
        title: this.$t('faq.metric-referral-sites'),
        platforms: this.referrals.platforms,
      }
    },
    dataOnActualDateAllLocal() {
      const allData = this.dataOnActualDateAll[0]?.data
      const data = this.dataOnActualDate[0]?.data?.[0]?.data
      return allData?.length ? allData : data
    },
    isData() {
      return (
        !this.loading &&
        !this.referrals.error.status &&
        this.dataOnActualDateAllLocal
      )
    },
    totalCount() {
      return this.dataOnActualDateAll[0]?.totalCount || 0
    },
    loading() {
      // return true
      return this.referrals.loading
    },
  },
  methods: {
    nFormatter,
    processLink(link) {
      return link
        .toLowerCase()
        .replace('www.', '')
        .replace('https://', '')
        .replace('http://', '')
    },
    onModalOpen() {
      if (!this.subscriptionActive)
        return this.$store.dispatch(
          'trialEndedPopupModule/getPlanAndOpen',
          'referrals'
        )
      this.isModalOpen = true
    },
    fix(value) {
      /** temporary fix */
      // if (value > 100) return 100
      return value
    },
  },
}
</script>

<style scoped>
.content {
  margin: 0 -20px;
}
.title {
  padding: 0 20px;
}
.show_more_button {
  margin-top: 15px;
}
</style>
