<template>
  <WidgetWrapper>
    <WidgetTitle
      v-if="!loading"
      :title="$t('main.engagement.title')"
      :description="$t('main.engagement.description')"
    >
      <template v-slot:dropDown v-if="isData">
        <div class="d-flex">
          <Date
            :actualDate="engagement.actualDate"
            :options="{ showFullDate: false, oldCalendar: true }"
          />
          <DropDown
            class="value_picker"
            type="origin"
            :title="getFormattedDate(engagement.actualDate)"
          >
            <template v-slot:items>
              <DropDownItem
                v-for="date of getListDates(engagement.data, {
                  subscriptionActive,
                })"
                v-show="date !== engagement.actualDate"
                @selectItem="updateActualDate"
                :text="getFormattedDate(date)"
                :value="date"
                :key="date"
                :isLocked="!subscriptionActive"
              />
            </template>
          </DropDown>
        </div>
      </template>
    </WidgetTitle>
    <div class="content" ref="content" v-if="isData">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th class="table_first_row" style="width: 27%" scope="col"></th>
              <th class="table_first_row" scope="col">
                {{ $t('main.engagement.bounceRate') }}

                <WidgetHint :hintOptions="hintOptions.bounceRate" />
              </th>
              <th class="table_first_row" scope="col">
                {{ $t('main.engagement.averageTimeVisit') }}

                <WidgetHint :hintOptions="hintOptions.averageVisitDuration" />
              </th>
              <th class="table_first_row" scope="col">
                {{ $t('main.engagement.pagePerVisit') }}

                <WidgetHint :hintOptions="hintOptions.pagesPerVisit" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item of dataOnActualDate" :key="item.domainName">
              <th class="table_first_column" scope="row">
                <Domain :maxWidth="'maxWidth'" :domain="item.domainName" />
              </th>
              <td>
                {{ getEngagementValue('bounceRate', item) }}
              </td>
              <td>
                {{ getEngagementValue('averageDurationSession', item) }}
              </td>
              <td>
                {{ getEngagementValue('pageViews', item) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <WidgetError v-if="!isData && !loading" />
    <SkeletonLoading v-if="loading" name="engagement" />
  </WidgetWrapper>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import WidgetTitle from '@/components/widgets/widgets-helpers/WidgetTitle.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import getFavicon from '@/helpers/domains/getFavicon'
import nFormatter from '@/helpers/processing/nFormatter'
import getListDates from '@/helpers/date/getListDates'
import WidgetError from '@/components/errors/WidgetError.vue'
import WidgetWrapper from '@/components/widgets/widgets-helpers/WidgetWrapper.vue'
import Domain from '@/components/widgets/widgets-helpers/Domain.vue'
import DropDown from '@/components/shared/drop-down/DropDown.vue'
import DropDownItem from '@/components/shared/drop-down/DropDownItem.vue'
import getFormattedDate from '@/helpers/date/getFormattedDate'
import Date from '@/components/shared/Date.vue'
import WidgetHint from './widgets-helpers/WidgetHint.vue'

export default {
  data() {
    return {
      data: {},
    }
  },
  components: {
    WidgetTitle,
    SkeletonLoading,
    WidgetError,
    WidgetWrapper,
    Domain,
    DropDown,
    DropDownItem,
    WidgetHint,
    Date,
  },
  computed: {
    ...mapGetters('engagement', ['dataOnActualDate']),
    ...mapGetters(['subscriptionActive']),
    ...mapState('mainData', {
      mainData: state => state,
    }),
    ...mapState('engagement', {
      engagement: state => state,
    }),
    hintOptions() {
      return {
        bounceRate: {
          name: 'metric-bounce-rate',
          title: this.$t('faq.metric-bounce-rate'),
          actualDate: this.engagement.actualDate,
          platforms: this.engagement.platforms,
        },
        averageVisitDuration: {
          name: 'metric-average-visit-duration',
          title: this.$t('faq.metric-average-visit-duration'),
          actualDate: this.engagement.actualDate,
          platforms: this.engagement.platforms,
        },
        pagesPerVisit: {
          name: 'metric-pages-per-visit',
          title: this.$t('faq.metric-pages-per-visit'),
          actualDate: this.engagement.actualDate,
          platforms: this.engagement.platforms,
        },
      }
    },
    isData() {
      return !this.loading && !this.mainData.error.status
    },
    loading() {
      return this.mainData.loading
    },
  },
  methods: {
    ...mapMutations('engagement', ['updateActualDate']),
    getFavicon,
    nFormatter,
    getListDates,
    getFormattedDate,
    getEngagementValue(key, item) {
      const value = item.data[0]?.data?.[key]
      if (!value || value === '0:00') return '—'
      if (key === 'bounceRate') return `${value}%`
      if (key === 'averageDurationSession') {
        const minutes = Math.floor(value / 60)
        const seconds =
          value - minutes * 60 < 10
            ? `0${value - minutes * 60}`
            : value - minutes * 60
        return `${minutes}:${Math.round(seconds)} мин.`
      }
      return value
    },
  },
}
</script>

<style scoped>
table {
  font-size: 14px;
}
tbody th,
tbody td {
  vertical-align: middle;
  font-size: 16px;
  line-height: 22px;
  color: #2c2738;
}

table th,
table td {
  font-weight: 600;
  padding: 16px 8px;
  border-top: 1px solid #eaeef8;
}
/* thead th {
  padding: 14px;
} */

.table-responsive {
  margin-bottom: 0;
}
.table_first_row {
  background: #f9f9fc;
  border-radius: 4px;
  border: none;
  color: #808493;
  font-size: 13px;
  white-space: nowrap;
}
.table_first_column {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #0e0f10;
}

tbody tr:first-child th,
tbody tr:first-child td {
  border: none;
}
</style>
