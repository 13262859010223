<template>
  <span class="wrapper_domain" :class="[maxWidth]">
    <Favicon :domain="domain" :marginRight="true" />
    <a v-if="isLink" class="domain" :href="'//' + domain" target="_blank"
      >{{ domain }}
    </a>
    <span v-else class="domain">{{ domain }}</span>
  </span>
</template>

<script>
import Favicon from '@/components/shared/Favicon.vue'

export default {
  data() {
    return {}
  },
  props: {
    domain: String,
    isLink: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: '',
    },
  },
  components: {
    Favicon,
  },
}
</script>

<style scoped>
.wrapper_domain {
  display: inline-flex;
}
.maxWidth {
  max-width: 180px;
}
.domain {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a {
  color: #5c5f6c;
}

span {
  color: #0e0f10;
}
</style>
