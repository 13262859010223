export default function extractDomain(url, withPage) {
  let hostname
  // find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf('//') > -1) {
    if (withPage) hostname = url.split('//')[1]
    else hostname = url.split('/')[2]
  } else {
    hostname = url.split('/')[0]
  }

  if (hostname.indexOf('www.') > -1) return url.split('www.')[1]

  // find & remove port number
  hostname = hostname.split(':')[0]
  // find & remove "?"
  hostname = hostname.split('?')[0]

  return hostname
}
