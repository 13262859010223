<template>
  <div class="d-flex flex-column g-0">
    <div class="text-center clip_financial_img">
      <slot name="picture"></slot>
    </div>
    <div class="text-center order_list">
      <slot name="points"></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
}
</script>

<style scope>
.clip_financial_img {
  margin-bottom: 30px;
}
.order_list div {
  margin-bottom: 7px;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #5c5f6c;
}
</style>
