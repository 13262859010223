export default function fillGapsDate(data) {
  if (!data.length) return data

  const dataRestructed = {}
  data.forEach(item => {
    dataRestructed[item.date] = item.data
  })

  const dateArray = []
  const result = []
  // const startDate =
  //   data.length === 1
  //     ? new Date(data[0].date).setFullYear(
  //         new Date(data[0].date).getFullYear() - 3
  //       )
  //     : data[0].date
  // const stopDate = new Date(data[data.length - 1].date)

  const startDate = new Date('2009-01-01')
  const stopDate = new Date(data[data.length - 1].date)
  const currentDate = new Date(startDate)
  while (currentDate <= stopDate) {
    const month =
      currentDate.getMonth() + 1 < 10
        ? `0${currentDate.getMonth() + 1}`
        : currentDate.getMonth() + 1
    const newDateFormatted = `${currentDate.getFullYear()}-${month}-01`
    dateArray.push(newDateFormatted)

    result.push({
      date: newDateFormatted,
      data: dataRestructed[newDateFormatted] || null,
    })

    currentDate.setMonth(currentDate.getMonth() + 1)
  }
  return result
}
