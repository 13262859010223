export default function getChannelSortIndex(nameChannel) {
  switch (nameChannel) {
    case 'Direct':
      return 1
    case 'OrganicSearch':
      return 2
    case 'PaidSearch':
      return 3
    case 'Referrals':
      return 4
    case 'Paid Referrals':
      return 5
    case 'Social':
      return 6
    case 'Mail':
      return 7
    default:
      return 8
  }
}
