import capitalizeFirstLetter from '@/helpers/processing/capitalizeFirstLetter'
import isMobile from '@/helpers/base/isMobile'
import getFormattedDate from '@/helpers/date/getFormattedDate'
import nFormatter from '@/helpers/processing/nFormatter'

export default function buildTooltip(context, $t, $tc) {
  const { chartState } = window
  const tooltipModel = context.tooltip

  let tooltipEl = document.getElementById('chartjs-tooltip')
  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.id = 'chartjs-tooltip'
    tooltipEl.innerHTML = `<table
      style="background: white; padding: 16px; border-collapse: separate; border: 1px solid #d9e0f2; border-radius: 7px;">
      </table>`
    document.body.appendChild(tooltipEl)
  }
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0
    return
  }

  tooltipEl.classList.remove('above', 'below', 'no-transform')
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign)
  } else {
    tooltipEl.classList.add('no-transform')
  }

  if (tooltipModel.body) {
    getTooltipBody(
      tooltipModel,
      tooltipEl,
      chartState,
      this._chart.data,
      $t,
      $tc
    )
  }

  // POSITION TOOLTIP
  const position = this._chart.canvas.getBoundingClientRect()
  tooltipEl.style.opacity = 1
  tooltipEl.style.position = 'absolute'
  tooltipEl.style.zIndex = 101
  const isShift = this._chart.width / 2 > tooltipModel.caretX
  const shift = isShift ? 0 : -tooltipEl.offsetWidth
  const left = isMobile()
    ? (window.innerWidth - tooltipEl.offsetWidth) / 2
    : position.left + window.pageXOffset + tooltipModel.caretX + shift
  tooltipEl.style.left = `${left}px`

  const fromTooltipToBottom =
    window.innerHeight - (position.top + tooltipModel.caretY)
  const shiftTop =
    fromTooltipToBottom < tooltipEl.offsetHeight
      ? fromTooltipToBottom - tooltipEl.offsetHeight - 25
      : 0

  tooltipEl.style.top = `${position.top +
    window.pageYOffset +
    tooltipModel.caretY +
    shiftTop}px`
  tooltipEl.style.font = {
    family: tooltipModel._bodyFontFamily,
    size: `${tooltipModel.bodyFontSize}px`,
    style: tooltipModel._bodyFontStyle,
  }
  tooltipEl.style.pointerEvents = 'none'
}

function getTooltipBody(tooltipModel, tooltipEl, chartState, data, $t, $tc) {
  // const isChangePopover = false // chartState.state.firstClickedIndex && chartState.state.secondClickedIndex

  const titleLines = tooltipModel.title || []
  const bodyFiltered = tooltipModel.body.filter(item => {
    if (item.lines[0].search(':') > -1) return true
    return false
  })
  const bodyLines = bodyFiltered.map(item => item.lines)
  let innerHtml = ''
  titleLines.forEach(title => {
    const titleFinal = getFormattedDate(title)
    innerHtml += `<thead>
      <tr style="font-weight: bold; font-size: 17px; margin-bottom: 9px; margin-right: -200px">
        <th scope="col">${capitalizeFirstLetter(titleFinal)}</th> 
      </tr> 
    </thead>`
  })
  innerHtml += `
  <tbody>
    <tr style="font-weight: 400; color: #AAB2BA; text-transform: uppercase; font-size: 12px;">
      <td style="font-weight: 400; padding-right: 30px;">${$t(
        'main.traffic.popupTitle'
      )}</td>
      <td class="text-end" style="padding: 0px;">${$t(
        'main.traffic.popupValue'
      )}</td>`

  const month = getFormattedDate(data.labels[data.labels.length - 1], {
    year: null,
  })
  innerHtml += `<td class="text-end" style="padding-left: 20px;">${month}</td>`
  innerHtml += `<td class="text-end" style="padding-left: 20px;">${$tc(
    'helpers.date.year',
    1
  )}</td>`
  innerHtml += `</tr>`

  bodyLines.forEach((body, i) => {
    const colors = tooltipModel.labelColors[i]
    innerHtml += '<tr>'
    const label = body[0].split(':')
    const labelName = label[0]
    // const labelValue = new Intl.NumberFormat().format(label[1])

    const labelValue = +label[1]?.replaceAll(',', '')?.replaceAll(' ', '')
    innerHtml += `<td style="font-size: 14px;color: #0E0F10;padding-right: 30px; white-space:nowrap;">
      <span style="display: inline-block; width:12px; height:12px; border-radius: 4px; margin-right: 4px; background:${colors.borderColor};"></span>
      ${labelName} 
    </td>`

    innerHtml += `<td align="right" style="color: #0E0F10;font-weight: 500;white-space:nowrap;padding: 0px">
      ${nFormatter(labelValue)}</td>`
    innerHtml += getHTMLGrowth({ data, label: labelName, period: 1 })
    innerHtml += getHTMLGrowth({ data, label: labelName, period: 12 })
    innerHtml += '</tr>'
  })
  innerHtml += '</tbody>'

  const tableRoot = tooltipEl.querySelector('table')
  tableRoot.innerHTML = innerHtml
}

function getHTMLGrowth(options) {
  const dataset = options.data.datasets.filter(
    item => item.label === options.label
  )

  const len = dataset[0].data.length - 1
  const max = dataset[0].data[len]
  const min = dataset[0].data[len - options.period]

  const growth = getGrowth(max, min)

  let growthOptions
  if (growth > 0) growthOptions = { color: '#04B42B', symbol: '+', last: '%' }
  else if (growth <= 0)
    growthOptions = { color: '#F82128', symbol: '', last: '%' }
  else growthOptions = { color: '#AAB2BA', symbol: '', last: '' }

  return `<td align="right" style="padding-left: 20px; color: 
    ${growthOptions.color}">${growthOptions.symbol}${growth}${growthOptions.last}
  </td>`
}

function getGrowth(max, min) {
  if (!max || !min) return '-'
  const diff = max - min
  const growth = (diff / min) * 100
  if (growth > 99999 || growth < -99999) return '-'
  return growth ? ((diff / min) * 100).toFixed(1) : '-'
}
