<template>
  <div class="wrapper d-flex justify-content-between">
    <div class="text">{{ getLink }}</div>
    <div class="copy_wrapper">
      <img :src="CopyIcon" @click="copy()" />
      <img v-if="isCopied" class="checked" :src="CheckedIcon" />
    </div>
  </div>
</template>

<script>
import CopyIcon from '@/assets/icons/copy.svg'
import CheckedIcon from '@/assets/icons/check.svg'
import copyTextToClipboard from '@/helpers/base/copyTextToClipboard'

export default {
  data() {
    return {
      CopyIcon,
      CheckedIcon,
      isCopied: false,
    }
  },
  computed: {
    getLink() {
      return window.location.href
    },
  },
  components: {},
  props: [],
  methods: {
    copyTextToClipboard,
    copy() {
      this.isCopied = true
      this.copyTextToClipboard(this.getLink)
    },
  },
}
</script>

<style scoped>
.wrapper {
  padding: 10px 20px;
  background: #f6f7fa;
  border: 1px solid #e0e5f2;
  box-sizing: border-box;
  border-radius: 7px;
}
.text {
  color: #808493;
}
.copy_wrapper {
  position: relative;
}
.checked {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 3px;
  right: -2px;
}
</style>
