<template>
  <div class="d-flex flex-column align-items-center wrapper_error">
    <b-toast
      role="alert"
      :title="$t(`error.${errorData.name}.title`)"
      :visible="true"
      static
      auto-hide-delay="6000"
    >
      <div class="toast-body" v-html="$t(`error.${errorData.name}.body`)"></div>
    </b-toast>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    errorData: {
      type: Object,
      default: () => {
        return {
          name: 'notFoundByRequest',
          specialTitleText: '',
        }
      },
    },
  },
  methods: {},
}
</script>

<style scoped>
.b-toast {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 100;
}
.toast-body {
  opacity: 0.9;
  padding: 0;
}
</style>
