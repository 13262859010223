<template>
  <div class="wrapper">
    <div class="label" v-if="label">{{ label }}</div>
    <input
      @focus="isFocused = true"
      @blur="isFocused = false"
      :value="value"
      @input="handleInput"
      class="input"
      :type="type"
      autocomplete="on"
    />
    <!-- :autocomplete="autocomplete" -->
    <div v-if="hasError && value && !isFocused" class="error">
      <slot name="error" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyInput',
  props: {
    value: String,
    label: String,
    hasError: Boolean,
    autocomplete: String,
    type: {
      default: 'text',
      type: String,
    },
  },
  data() {
    return {
      isFocused: false,
    }
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event.target.value)
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
}
.label {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 19px;
  color: #808493;
}
.input {
  background: #ffffff;
  border: 1px solid #d9e0f2;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  height: 48px;
  padding: 0 8px;
  font-size: 18px;
}

.error {
  margin-top: 10px;
  font-size: 14px;
  line-height: 16px;
  color: #ff5f7b;
}
</style>
