import { api } from '@/helpers/base/api'

export const notifications = {
  namespaced: true,
  actions: {
    async load(ctx) {
      ctx.commit('setLoading', true)

      try {
        const result = await api.get('/service/getNotifications')
        ctx.commit('setData', result.data)
        ctx.commit('setBadgeStatus', false)
      } catch (err) {
        console.error(err)
      }

      ctx.commit('setLoading', false)
    },
    async loadNotificationStatus(ctx, params) {
      try {
        const result = await api.get('/service/getNotificationsStatus')
        ctx.commit('setBadgeStatus', result.isMessagesChecked)
      } catch (err) {
        console.error(err)
      }
    },
    show(ctx, params) {
      ctx.commit('setVisibility', true)
    },
    hide(ctx, params) {
      ctx.commit('setVisibility', false)
    },
    toggle(ctx, params) {
      ctx.commit('setVisibility', !ctx.state.isVisible)
    },
  },
  state: () => ({
    isLoading: false,
    isVisible: false,
    isShowBadge: false,
    data: [],
  }),
  mutations: {
    setData(state, data) {
      state.data = data.map(item => {
        return {
          type: item.type,
          version: item.version,
          date: item.date,
          countDays: item.countDays,
        }
      })
    },
    setBadgeStatus(state, isShowBadge) {
      state.isShowBadge = isShowBadge
    },
    setVisibility(state, isVisible) {
      state.isVisible = isVisible
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading
    },
  },
}
