<template>
  <Tooltip :text="$t('hint.click')">
    <button class="hint-btn" @click="isHintOpen = true" />
  </Tooltip>

  <HintDrawer v-model:open="isHintOpen" :hintOptions="hintOptions" />
</template>

<script>
import Tooltip from '@/components/shared/Tooltip.vue'
import HintDrawer from './HintDrawer.vue'

export default {
  components: { HintDrawer, Tooltip },

  props: {
    hintOptions: { type: Object },
  },

  data() {
    return {
      isHintOpen: false,
    }
  },
}
</script>

<style scoped>
.hint-btn {
  padding: 2px 0;
  background-color: transparent;
  background-image: url(~@/assets/icons/hint.svg);
  width: 16px;
  height: 16px;
}

.hint-btn:hover {
  background-image: url(~@/assets/icons/hint-blue.svg);
}
</style>
