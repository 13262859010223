<template>
  <div class="d-flex flex-column align-items-center wrapper_local">
    <NotFoundData />
  </div>
</template>

<script>
import { LANDING_URL } from '@/helpers/variables'
import { makeEvent } from '@/helpers/base/amplitude'
import NotFoundData from '@/components/shared/NotFoundData.vue'

export default {
  data() {
    return {}
  },
  components: {
    NotFoundData,
  },
  computed: {
    landingUrl() {
      return LANDING_URL
    },
  },
  mounted() {
    makeEvent('not found page')
  },
  props: {
    errorData: {
      type: Object,
      default: () => {
        return {
          name: 'pageNonExist',
        }
      },
    },
  },
  methods: {
    onClick() {},
  },
}
</script>

<style scoped>
.wrapper_local {
  background: white;
  height: 100vh;
  padding: 0 20px;
  margin: 100px 0 0;
}
@media (max-width: 992px) {
  .wrapper_local {
    margin: 10px 0 0;
  }
}
.title {
  font-size: 23px;
  margin-top: 100px;
  margin-bottom: 25px;
}
.linkMain:hover {
  text-decoration: none;
}
.body {
  color: #8f8f8f;
  text-align: center;
}
.image_error {
  width: 125px;
  margin-bottom: 15px;
}
.specialTitleText {
  color: #2196f3;
  font-weight: 400;
}
</style>
