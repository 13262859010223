<template>
  <Teleport to="#drawer">
    <Transition name="drawer">
      <div v-if="open" class="hint-drawer">
        <button class="hint-drawer-overlay" @click="close" />
        <div class="hint-drawer-main">
          <button class="hint-drawer-close" @click="close">
            <img
              class="hint-drawer-close-icon"
              src="~@/assets/icons/base/close.svg"
            />
          </button>

          <h2 class="hint-drawer-title">{{ hintOptions.title }}</h2>

          <div
            class="hint-drawer-filters"
            v-if="hintOptions.platforms || hintOptions.actualDate"
          >
            <button class="hint-drawer-filter" v-if="hintOptions.platforms">
              <span class="hint-drawer-filter-txt">
                {{ $t(`hint.platforms.${hintOptions.platforms}`) }}
              </span>
            </button>
            <button class="hint-drawer-filter" v-if="hintOptions.actualDate">
              <Date
                class="date"
                :actualDate="hintOptions.actualDate"
                :options="{ showFullDate: true, oldCalendar: true }"
              />
              <!-- <img
              class="hint-drawer-filter-icon"
              src="~@/assets/icons/calendar.svg"
            />
            <span class="hint-drawer-filter-txt">
              {{ getFormattedDate(hintOptions.actualDate) }}
            </span> -->
            </button>
          </div>

          <div class="hint-drawer-content" v-html="hintContent" />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
import getFormattedDate from '@/helpers/date/getFormattedDate'
import Date from '@/components/shared/Date.vue'

export default {
  props: {
    open: { type: Boolean, required: true },
    // title: { type: String, required: true },
    // name: { type: String, required: true },
    hintOptions: { type: Object },
  },
  components: {
    Date,
  },

  computed: {
    hintContent() {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return require(`@/assets/docs/faq/${this.$i18n.locale}/${this.hintOptions.name}.md`)
    },
  },

  methods: {
    getFormattedDate,
    close() {
      this.$emit('update:open', false)
    },
  },
}
</script>

<style scoped>
.hint-drawer-overlay,
.hint-drawer-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(39, 50, 64, 0.2);
  z-index: 9999;
}

.hint-drawer-main {
  left: unset;
  right: 0;
  width: 600px;
  background-color: #ffffff;
  box-shadow: 0 4px 20px rgba(232, 232, 232, 0.2);
  padding: 65px 60px;
  overflow-y: auto;
  white-space: initial;
  text-align: left;
  font-family: var(--font-family);
}

.hint-drawer-main::-webkit-scrollbar {
  display: none;
}

.hint-drawer-close {
  position: absolute;
  top: 35px;
  right: 35px;
  background-color: transparent;
  padding: 0;
}

.hint-drawer-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  color: var(--text-primary);
}

.hint-drawer-filters {
  margin-top: 10px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 15px;
  width: max-content;
}

.hint-drawer-filter {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 7px;
  background-color: transparent;
  padding: 0;
}

.hint-drawer-filter-txt {
  font-size: 14px;
  line-height: 16px;
  color: var(--text-secondary3);
}

.hint-drawer-content {
  margin-top: 40px;
}

.drawer-enter .hint-drawer-overlay,
.drawer-leave-to .hint-drawer-overlay {
  opacity: 0;
}

.drawer-enter .hint-drawer-main,
.drawer-leave-to .hint-drawer-main {
  transform: translateX(100%);
}

.drawer-enter-active,
.drawer-leave-active,
.drawer-enter-active .hint-drawer-overlay,
.drawer-leave-active .hint-drawer-overlay {
  transition: opacity 200ms ease-out;
}

.drawer-enter-active .hint-drawer-main,
.drawer-leave-active .hint-drawer-main {
  transition: transform 200ms ease-out;
}

@media (max-width: 768px) {
  .hint-drawer-main {
    width: 100%;
    padding: 25px 15px;
  }

  .hint-drawer-close {
    top: 15px;
    right: 15px;
  }

  .hint-drawer-title {
    font-size: 30px;
    line-height: 42px;
  }

  .hint-drawer-content {
    margin-top: 25px;
  }
}
</style>

<style>
.hint-drawer-content h1 {
  display: none;
}

.hint-drawer-content h3 {
  margin-top: 35px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: var(--text-primary);
}

.hint-drawer-content p,
.hint-drawer-content li {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: var(--text-secondary);
}

.hint-drawer-content ul,
.hint-drawer-content ol {
  display: grid;
  gap: 15px;
  list-style: none;
  padding-left: 0;
}

.hint-drawer-content ol {
  counter-reset: item;
}

.hint-drawer-content ul li {
  margin-left: 26px;
}

.hint-drawer-content ul li::before {
  content: '\2022';
  color: var(--main-color);
  display: inline-block;
  font-size: 22px;
  margin-left: -21px;
  margin-right: 7px;
}

.hint-drawer-content ol li {
  margin-left: 34px;
}

.hint-drawer-content ol li::before {
  counter-increment: item;
  content: counters(item, '.') '.';
  suffix: '.';
  color: var(--text-primary);
  display: inline-block;
  font-size: 16px;
  width: 10px;
  line-height: 22px;
  margin-right: 11px;
  margin-left: -24px;
}

@media (max-width: 1024px) {
  .hint-drawer-content h3 {
    margin-top: 15px;
    margin-bottom: 7px;
    font-size: 16px;
    line-height: 22px;
  }

  .hint-drawer-content p,
  .hint-drawer-content li {
    font-size: 14px;
    line-height: 19px;
  }

  .hint-drawer-content ul,
  .hint-drawer-content ol {
    gap: 10px;
  }

  .hint-drawer-content ul li {
    margin-left: 16px;
  }

  .hint-drawer-content ul li::before {
    display: inline-flex;
    margin-left: -16px;
    font-size: 19px;
    line-height: 19px;
    margin-right: 4px;
  }

  .hint-drawer-content ol li {
    margin-left: 20px;
  }

  .hint-drawer-content ol li::before {
    width: 10px;
    font-size: 14px;
    line-height: 19px;
    margin-right: 6px;
    margin-left: -16px;
  }
}
</style>
