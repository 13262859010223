import extractDomain from './extractDomain'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default function getSourceName(domain) {
  if (!domain) return domain
  domain = extractDomain(domain)
  const splittedArray = domain.split('.')
  return splittedArray.length > 2
    ? `${capitalizeFirstLetter(splittedArray[0])} ${capitalizeFirstLetter(
        splittedArray[1]
      )}`
    : capitalizeFirstLetter(splittedArray[0])
}
