import requestHelper from '@/helpers/store/requestHelper'

export const additionalData = {
  namespaced: true,
  actions: {
    async load(ctx) {
      const result = await requestHelper(
        {
          url: '/data/getAdditionalData',
          viewId: ctx.rootState.view.viewId,
        },
        ctx,
        null
      )
      if (result) {
        ctx.dispatch('social/set', result.data, { root: true })
        ctx.dispatch('adNetworks/set', result.data, { root: true })
        ctx.dispatch('mobileApps/set', result.data, { root: true })
        ctx.dispatch('keywords/set', result.data, { root: true })
        ctx.dispatch('referrals/set', result.data, { root: true })
        ctx.dispatch('paidOrganic/set', result.data, { root: true })
      }
    },
  },
  state: {
    loading: true,
    error: { status: false },
  },
  mutations: {
    updateLoading(state, status) {
      state.loading = status
    },
    updateError(state, errorData) {
      state.error = errorData
    },
  },
  getters: {
    isData(state) {
      return !state.loading && !state.error.status && state.data.length
    },
  },
}
