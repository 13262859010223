import { filterOnActualDate } from '@/helpers/store/filterOnActualDate'
import { setActualDate } from '@/helpers/store/setActualDate'
import { saveRightOrderDomains } from '@/helpers/store/saveRightOrderDomains'

export const paidOrganic = {
  namespaced: true,
  actions: {
    set(ctx, data) {
      ctx.commit('updateData', data)
      ctx.commit('updateActualDate')
    },
  },
  state: {
    data: [],
    platforms: 'desktop',
    actualDate: null,
  },
  mutations: {
    updateData(state, data) {
      const result = data.map(dataItem => {
        return {
          domainName: dataItem.domainName,
          data: dataItem?.search?.map(item => {
            return {
              data: item.data?.distribution,
              date: item.date,
            }
          }),
        }
      })
      state.data = result
    },
    updateActualDate(state, value) {
      state.actualDate = setActualDate(state.data, value)
    },
  },
  getters: {
    dataOnActualDate(state, getters, rootState, rootGetters) {
      const { domains } = rootGetters
      const data = filterOnActualDate(state.data, state.actualDate)
      return saveRightOrderDomains(data, domains)
    },
  },
}
