// import Vue from 'vue'
// import Vuex from 'vuex'

import { createStore } from 'vuex'
import view from '@/store/modules/view'
import { navigation } from '@/store/modules/navigation'

import { traffic } from '@/store/modules/widgets-others/traffic'
import { deviceDistribution } from '@/store/modules/widgets-others/deviceDistribution'
import { similarSites } from '@/store/modules/widgets-others/similarSites'
import { uniqueVisitors } from '@/store/modules/widgets-others/uniqueVisitors'
import { referrals } from '@/store/modules/widgets-others/referrals'
import { ecosystem } from '@/store/modules/widgets-others/ecosystem'

import { mainData } from '@/store/modules/widgets-main-data/mainData'
import { marketingChannels } from '@/store/modules/widgets-main-data/marketingChannels'
import { geo } from '@/store/modules/widgets-main-data/geo'
import { engagement } from '@/store/modules/widgets-main-data/engagement'
import { searchTraffic } from '@/store/modules/widgets-main-data/searchTraffic'

import { additionalData } from '@/store/modules/widgets-additional-data/additionalData'
import { social } from '@/store/modules/widgets-additional-data/social'
import { adNetworks } from '@/store/modules/widgets-additional-data/adNetworks'
import { mobileApps } from '@/store/modules/widgets-additional-data/mobileApps'
import { paidOrganic } from '@/store/modules/widgets-additional-data/paidOrganic'
import { keywords } from '@/store/modules/widgets-additional-data/keywords'

import { authModule } from './modules/auth'
import { userInfo } from './modules/user-info'
import { subscription } from './modules/subscription'
import { notifications } from './modules/notifications'
import { trialEndedPopupModule } from './modules/trialEndedPopup'
import { addDomainModal } from './modules/addDomainModal'

// Vue.use(Vuex)

const store = createStore({
  strict: process.env.NODE_ENV !== 'production',
  actions: {},
  state: {},
  mutations: {},
  getters: {},

  modules: {
    view,
    mainData,
    additionalData,
    traffic,
    engagement,
    social,
    adNetworks,
    mobileApps,
    referrals,
    paidOrganic,
    searchTraffic,
    keywords,
    ecosystem,
    marketingChannels,
    similarSites,
    uniqueVisitors,
    deviceDistribution,
    geo,
    authModule,
    userInfo,
    subscription,
    trialEndedPopupModule,
    navigation,
    addDomainModal,
    notifications,
  },
})

window._vueXStore = store

export default store
