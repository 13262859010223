<template>
  <div
    class="d-flex align-items-center"
    :class="{ 'old-calendar': showOldCalendar }"
  >
    <Tooltip v-if="showOldCalendar" :text="tooltipText">
      <img class="date_icon" :src="CalendarOldIcon" />
    </Tooltip>
    <img
      v-if="options?.showFullDate && !showOldCalendar"
      class="date_icon"
      :src="CalendarIcon"
    />
    <span class="formatted-date" v-if="options?.showFullDate">{{
      getFormattedDate(actualDate)
    }}</span>
  </div>
</template>

<script>
import { numberWithSpaces } from '@/helpers/processing/numbers'
import getFormattedDate from '@/helpers/date/getFormattedDate'
import CalendarIcon from '@/assets/icons/base/calendar.svg'
import CalendarOldIcon from '@/assets/icons/base/calendar-old.svg'
import isOldData from '@/helpers/isOldData'
import Tooltip from '@/components/shared/Tooltip.vue'

export default {
  data() {
    return {
      CalendarIcon,
      CalendarOldIcon,
    }
  },
  computed: {
    tooltipText() {
      return `${this.$t('hint.oldData')} ${this.getFormattedDate(
        this.actualDate
      )}`
    },
    showOldCalendar() {
      return this.isOldData(this.actualDate) && this.options?.oldCalendar
    },
  },
  components: {
    Tooltip,
  },
  name: 'Currency',
  props: {
    actualDate: String,
    options: Object,
  },
  methods: {
    numberWithSpaces,
    getFormattedDate,
    isOldData,
  },
}
</script>

<style scoped>
.date_icon {
  margin-right: 8px;
}
.formatted-date {
  color: var(--text-secondary3);
  font-size: 14px;
}
.old-calendar .formatted-date {
  color: #ff9d44;
}
</style>
