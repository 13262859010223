/* eslint-disable camelcase */
import { i18n } from '@/lang'
import capitalizeFirstLetter from '../processing/capitalizeFirstLetter'

export default function getFormattedDate(date, options = {}) {
  const localOptions = {
    month: options.month || 'long',
    year: options.year || 'numeric',
    capitalizeFirstLetter: options.capitalizeFirstLetter
      ? options.capitalizeFirstLetter
      : true,
  }
  if (options.month === null) delete localOptions.month
  if (options.year === null) delete localOptions.year

  const dateLocal = new Date(date)
  const formattedDate = dateLocal
    .toLocaleString(i18n.global.locale, localOptions)
    .replaceAll('.', '')
    .replaceAll('г', '')
  if (!localOptions.capitalizeFirstLetter) return formattedDate
  return capitalizeFirstLetter(formattedDate)
}
