<template>
  <div class="wrapper_show_more_button" @click="$emit('clickCustom')">
    {{ $t('buttons.showMore')
    }}<span class="type_name">
      {{ $tc(`buttons.postText.${type}`, { count: totalCount }) }}
    </span>
    <img :class="{ isShow: false }" src="/arrow_blue.svg" />
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    type: {
      type: String,
      default: 'sources',
    },
    totalCount: Number || String,
  },
  components: {},
  methods: {},
}
</script>

<style scoped>
.wrapper_show_more_button {
  color: #2684fe;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  text-align: right;
}

.wrapper_show_more_button .type_name {
  margin: 0 5px 0 3px;
}

.wrapper_show_more_button img {
  height: 8px;
  width: 8px;
  transform: rotate(-90deg);
  margin-bottom: 1px;
}

.isShow {
  transform: rotate(90deg) !important;
}
</style>
