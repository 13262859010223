<template>
  <div class="d-flex g-0">
    <div class="wrapper_graph d-flex align-items-center col-auto pe-3">
      <img
        v-if="iconUrl"
        width="36"
        height="36"
        :src="iconUrl"
        class="favicon"
      />
      <img :src="NoAppIcon" v-else />
    </div>
    <div class="wrapper_statistics item col">
      <span class="app" v-html="name"> </span>
      <div
        class="wrapper_point_traffic d-flex align-items-center justify-content-between"
      >
        <div class="rating d-flex">
          {{ rating }}
          <StarRate :rating="rating" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRate from '@/components/widgets/mobile-apps/StarRate.vue'
import NoAppIcon from '@/assets/icons/mobile-apps/no-app.svg'

export default {
  data() {
    return {
      NoAppIcon,
    }
  },
  components: { StarRate },
  props: ['iconUrl', 'name', 'rating'],
}
</script>

<style scoped>
.app {
  font-size: 14px;
  line-height: 19px;
  color: #5c5f6c;
}
.rating {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #0e0f10;
}
.wrapper_statistics {
  margin: 15px 0;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
