import { makeEvent } from '@/helpers/base/amplitude'
import { api } from '@/helpers/base/api'
import getTrialPeriod from '@/helpers/payment/getTrialPeriod'

export const subscription = {
  namespaced: true,
  actions: {
    async loadSubscription(ctx) {
      ctx.commit('setLoading', true)

      try {
        const result = await api.get('/subscription/getInfo')
        ctx.commit('setSubscribe', result.data)
      } catch (err) {
        console.error(err)
      }

      ctx.commit('setLoading', false)
    },
    async cancelSubscription() {
      await api.post('/subscription/cancel')
      setTimeout(() => {
        window.location.reload()
      }, 0)
    },
    async restoreSubscription() {
      await api.post('/subscription/restore')
      setTimeout(() => {
        window.location.reload()
      }, 0)
    },
  },
  state: () => ({
    isLoading: false,
    subscription: null,
    isSubscriptionModalCancelOpen: false,
    isSubscriptionModalConfirmOpen: false,
    from: '',
    optionToConfirm: {},
    navigationData: [
      {
        id: 1,
        duration: 30,
        price: 1990,
        plan: 'metrica+',
      },
      {
        id: 2,
        price: 3990,
        duration: 90,
        plan: 'metrica+',
      },
      {
        id: 3,
        duration: 365,
        price: 9990,
        plan: 'metrica+',
      },
    ],
  }),
  mutations: {
    setLoading(state, isLoading) {
      state.isLoading = isLoading
    },
    setOptionToConfirm(state, option) {
      state.optionToConfirm = option
    },
    setSubscribe(state, subscriptionParam) {
      state.subscription = subscriptionParam
    },
    openCancelSubscriptionModal(state) {
      makeEvent('cancel subscription', {
        button: 'cancel_subsrciption',
      })
      state.isSubscriptionModalCancelOpen = true
    },
    closeCancelSubscriptionModal(state) {
      state.isSubscriptionModalCancelOpen = false
    },
    setNavigationData(state, data) {
      state.navigationData = data
    },
    openConfirmModal(state, from) {
      makeEvent('subscription', {
        button: 'open_email',
        period: from.period,
        plan: from.plan,
      })
      state.from = from
      state.isSubscriptionModalConfirmOpen = true
    },
    closeConfirmModal(state) {
      state.isSubscriptionModalConfirmOpen = false
    },
  },
  getters: {
    isLoggedIn(state) {
      return !!state.jwt
    },
    isSubscribeActive(state) {
      return state.subscription?.isActive
    },
    navigationData(state) {
      return state.navigationData
    },
    isTrial(state) {
      return state.subscription?.isTrial
    },
    trialPeriod(state) {
      return getTrialPeriod(state.subscription?.validTill)
    },
    validTill(state) {
      return state.subscription?.validTill
    },
    priceSubscription(state) {
      return state.subscription?.plan?.price
    },
    isAutoRenewed(state) {
      return state.subscription?.autoRenewed
    },
    paymentData(state) {
      return state.subscription?.paymentData
    },
    isTrialStarted(state) {
      return !!state.subscription?.trialStarted
    },
    parentSubscriptionId(state) {
      return state.subscription?.parentId
    },
    isTrialEnded(state, getters) {
      return (
        !getters.paymentData &&
        getters.isTrialStarted &&
        !getters.isSubscribeActive
      )
    },
  },
}
