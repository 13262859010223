<template>
  <button
    :disabled="disabled"
    @click="$emit('click', $event)"
    :class="['main_btn', color]"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'blue',
    },
    disabled: Boolean,
  },
}
</script>

<style>
.main_btn {
  width: 100%;
  height: 48px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
}

.main_btn:disabled,
.main_btn[disabled] {
  opacity: 0.6;
}

.blue {
  color: #ffffff;
  background: var(--button-primary);
}
.blue:hover {
  background: var(--button-primary-hover);
}

.white {
  background: var(--button-secondary);
  color: var(--text-secondary2);
  font-weight: 400;
  border: 1px solid #dadce0;
  box-sizing: border-box;
}
.white:hover {
  background: var(--button-secondary-hover);
}

.pink {
  background: var(--button-pink);
  color: #ffffff;
}
.pink:hover {
  background: var(--button-pink-hover);
}

.link {
  background: transparent;
  color: #2684fe;
  border: none;
}
</style>
