import { createI18n } from 'vue-i18n'
import ru from '@/locales/ru'
import en from '@/locales/en'

import { getCookie } from '@/helpers/base/cookie'

export const i18n = createI18n({
  locale:
    getCookie('i18n_user_locale') ||
    (navigator.language || navigator.userLanguage).split('-')[0],
  fallbackLocale: 'ru',
  pluralizationRules: {
    ru: customRu
  },
  messages: {
    en,
    ru,
  },
})

function customRu(choice, choicesLength, orgRule) {
  if (choice === 0) {
    return 0
  }

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1
  if (!teen && endsWithOne) {
    return 1
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }

  return choicesLength < 4 ? 2 : 3
}
