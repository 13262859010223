<template>
  <div class="page">
    <div v-if="!isLoading" class="subscribe_block">
      <div class="subscribe_title">{{ $t('personalArea.title') }}</div>
      <div class="subscribe_status_block">
        <div class="logo">
          <PrimeText class="metrica_text" text="Metrica+" />
          <div v-if="isSubscribeActive" class="subscription_status_true">
            <div class="green_circle" />
            {{
              isSubscribeActive && isTrial
                ? $t('personalArea.isTrial')
                : $t('personalArea.active')
            }}
          </div>
          <div v-else class="subscription_status_false">
            <div class="red_circle" />
            {{ $t('personalArea.notActive') }}
          </div>
          <a
            href="https://pro.metrica.guru/tos.pdf"
            target="_blank"
            rel="noreferrer"
            class="terms"
          >
            {{ $t('footer.tos') }}
          </a>
        </div>
        <div class="subscription_till">
          {{ textDescription }}
        </div>
        <div v-if="!!paymentData" class="card-data">
          <img
            :src="VisaIcon"
            v-if="paymentData.pan && isVisaCard(paymentData.pan)"
          />
          <img
            :src="MasterCardIcon"
            v-if="paymentData.pan && isMasterCard(paymentData.pan)"
          />
          <img
            :src="MirIcon"
            v-if="paymentData.pan && isMirCard(paymentData.pan)"
          />
          <div class="card-number">
            **** **** ****
            {{ paymentData.pan ? paymentData.pan.slice(-4) : '0000' }}
          </div>
        </div>
      </div>
      <div class="buttons_page">
        <!-- <router-link class="link" to="/pricing">
          <Button v-if="!isSubscribeActive" color="blue">
            {{ $t('buttons.subscribe') }}
          </Button>
        </router-link>
        <Button
          @click="restoreSubscription"
          v-if="
            !isAutoRenewed &&
              !isTrial &&
              isSubscribeActive &&
              !parentSubscriptionId
          "
          color="blue"
        >
          {{ $t('buttons.restoreSubscription') }}
        </Button>
        <Button v-if="isTrial" @click="openModal" color="blue">
          {{ $t('buttons.buySubscription') }} {{ navigationData[1].price }} ₽ /
          {{ $tc('helpers.date.month', 3) }}
        </Button>
        <router-link v-if="isSubscribeActive" class="link" to="/pricing">
          <Button color="blue">
            {{ $t('buttons.advantages') }}
          </Button>
        </router-link> -->
        <Button
          v-if="isSubscribeActive && isAutoRenewed && !isTrial"
          @click="clickCancelSubscription"
          color="white"
        >
          {{ $t('buttons.cancelSubscription') }}
        </Button>
        <!-- <router-link class="link" to="/pricing">
          <Button v-if="!isSubscribeActive" color="white">
            {{ $t('buttons.moreAboutMetrica') }}
          </Button>
        </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import Button from '@/components/buttons/Button.vue'
import VisaIcon from '@/assets/icons/payments/visa.svg'
import MasterCardIcon from '@/assets/icons/payments/mastercard.svg'
import MirIcon from '@/assets/icons/payments/mir.svg'
import PrimeText from '@/components/shared/PrimeText.vue'
import { isVisaCard, isMasterCard, isMirCard } from '@/helpers/payment/cards'
import { dateParser } from '@/helpers/date/date'

export default {
  data() {
    return {
      VisaIcon,
      MasterCardIcon,
      MirIcon,
    }
  },
  name: 'personalArea',
  components: {
    Button,
    PrimeText,
  },
  async mounted() {
    this.loadSubscription()
    const { success, data } = await api.get(
      '/subscription/getSubscriptionPlans'
    )
    if (success) this.setNavigationData(data)
  },
  methods: {
    ...mapActions('subscription', ['restoreSubscription', 'loadSubscription']),
    ...mapMutations('subscription', [
      'openCancelSubscriptionModal',
      'openConfirmModal',
      'setOptionToConfirm',
      'setNavigationData',
    ]),
    clickCancelSubscription() {
      this.openCancelSubscriptionModal()
    },
    openModal() {
      const actualPlan = this.navigationData[1]
      this.setOptionToConfirm(actualPlan)
      this.openConfirmModal({
        plan: actualPlan.plan,
        period: actualPlan.duration,
      })
    },
    isVisaCard,
    isMirCard,
    isMasterCard,
  },
  computed: {
    ...mapGetters('userInfo', ['userInfo', 'isLoggedIn']),
    ...mapGetters('subscription', [
      'isSubscribeActive',
      'isTrialEnded',
      'paymentData',
      'trialPeriod',
      'isTrial',
      'validTill',
      'priceSubscription',
      'isAutoRenewed',
      'parentSubscriptionId',
      'navigationData',
    ]),
    ...mapState('subscription', ['isLoading']),
    dateDiff() {
      if (!this.validTill) {
        return { years: 0, days: 0, months: 0 }
      }
      const diff = Math.floor(Date.parse(this.validTill) - Date.now())

      const day = 1000 * 60 * 60 * 24

      const days = Math.ceil(diff / day)
      const months = Math.floor(days / 31)
      const years = Math.floor(months / 12)

      return { years, days, months }
    },
    textDescription() {
      const { days } = this.dateDiff
      if (this.isTrial && this.isSubscribeActive) {
        return (
          this.$tc('helpers.date.days', days) +
          this.$t('personalArea.timeForTrialEnded')
        )
      }

      if (this.isSubscribeActive && !this.isAutoRenewed) {
        return (
          this.$tc('helpers.date.days', days) +
          this.$t('personalArea.subscriptionTimeLeft')
        )
      }

      if (this.isSubscribeActive && this.isAutoRenewed) {
        return this.$t('personalArea.nextCharge', {
          price: this.priceSubscription,
          date: dateParser(this.validTill).format('DD.MM.YYYY'),
        })
      }

      if (this.isTrialEnded) {
        return this.$t('personalArea.trialEnded')
      }

      if (!this.isSubscribeActive) {
        return this.$t('personalArea.expired')
      }

      return ''
    },
  },
}
</script>

<style scoped>
.page {
  width: 100%;
  height: 100%;
  display: flex;
}
.subscribe_block {
  margin: 40px auto;
  background: #ffffff;
  border: 1px solid #eaeef8;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(232, 232, 232, 0.2);
  border-radius: 4px;
  padding: 40px 30px 45px 30px;
  display: flex;
  width: 508px;
  flex-direction: column;
}
.subscribe_title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #0e0f10;
  margin-bottom: 29px;
}
.subscribe_status_block {
  background: #ffffff;
  border: 1px solid #e2e6ea;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 25px 20px 25px;
}
.buttons_page {
  margin-top: 30px;
  display: grid;
  row-gap: 10px;
}
.link {
  text-decoration: none;
}
.metrica_text {
  display: inline-flex;
  background: url('/background_gradient.png');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
  font-weight: 500;
}
.subscription_status_true {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--success-color);
  margin-left: 15px;
  display: flex;
  align-items: center;
}
.centred_loader {
  margin: auto;
}
.green_circle {
  width: 4px;
  height: 4px;
  background: var(--success-color);
  border-radius: 50%;
  margin-right: 5px;
}
.subscription_status_false {
  font-size: 14px;
  line-height: 16px;
  color: var(--danger-color);
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.red_circle {
  width: 4px;
  height: 4px;
  background: var(--danger-color);
  border-radius: 50%;
  margin-right: 5px;
}

.logo {
  display: flex;
  align-items: center;
}
.terms {
  margin-left: auto;
  font-size: 12px;
  line-height: 14px;
  color: #aab2ba;
  text-decoration: none;
}
.subscription_till {
  margin-top: 14px;
  font-size: 14px;
  line-height: 125%;
  color: #808493;
}

.card-data {
  border-top: 1px solid #e2e6ea;
  padding-top: 14px;
  display: flex;
  align-items: center;
  margin-top: 19px;
}

.card-number {
  margin-left: 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 125%;
  color: #808493;
}

.features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 40px;
  row-gap: 12px;
}
.feature {
  font-size: 14px;
  line-height: 16px;
  color: #0e0f10;
}
.feature > svg {
  margin-right: 10px;
}
</style>
