export default function isOldData(actualDate) {
  const today = new Date(Date.now())
  const oneDayMs = 1000 * 60 * 60 * 24
  const daysInPreviosMonth = new Date(
    today - oneDayMs * today.getDate()
  ).getDate()

  /** every month up to 10 days data appers on the site */
  const maxDays = 10
  const days = oneDayMs * (daysInPreviosMonth + maxDays)
  const expirationDate = today - days
  const dateActualDate = new Date(actualDate)
  const oneMonth =
    new Date(
      dateActualDate.getFullYear(),
      dateActualDate.getMonth() + 1,
      0
    ).getDate() * oneDayMs
  if (new Date(+dateActualDate + +oneMonth) > expirationDate) return false
  return true
}
