<template>
  <div>
    <Flag :country="data.value" />
    {{ data.value }}
  </div>
</template>

<script>
import Flag from '@/components/shared/Flag.vue'

export default {
  components: { Flag },
  props: ['data'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped></style>
