<template>
  <div class="indicator">
    <div
      class="indicator_active"
      :style="{ width: getWidthValue(width) }"
    ></div>
  </div>
</template>

<script>
export default {
  props: ['width'],
  methods: {
    getWidthValue(value) {
      if (value) return `${value}%`
      return '0%'
    },
  },
}
</script>

<style scoped>
.indicator {
  height: 10px;
  width: 100%;
  margin: 0 16px;
  background: #f6f7fa;
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 0;
}
.indicator_active {
  height: 10px;
  background: #2684fe;
  border-radius: 0.5rem;
}
</style>
