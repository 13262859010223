<template>
  <div class="header_wrapper">
    <header class="d-flex justify-content-center w-100">
      <Menu
        v-if="isMenuShowed"
        :isCollapsed="false"
        @toggleMenu="setVisibilityMenu()"
      />
      <div v-if="isMenuShowed" @click="setVisibilityMenu(!isMenuShowed)">
        <Overlay type="transparent" />
      </div>
      <div class="w-100 header_content d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <img
            class="burger_dark_icon"
            :src="BurgerDarkIcon"
            alt="my-logo"
            @click="setVisibilityMenu(!isMenuShowed)"
          />
          <a
            class="d-lg-flex logo_link"
            :class="{ 'd-none': isMainPage }"
            @click="goToMain()"
          >
            <img class="logo" src="/logo.svg" />
          </a>
          <template v-if="isMainPage && domains.length">
            <div class="d-none divider d-lg-flex"></div>
            <div class="menu_links">
              <DomainPicker />
              <div class="d-none d-sm-flex date" v-if="mainData.actualDate">
                <Date
                  :actualDate="mainData.actualDate"
                  :options="{ showFullDate: true }"
                />
              </div>
            </div>
          </template>
        </div>
        <div class="d-flex justify-content-between wrapper_header">
          <template v-if="isMainPage">
            <div class="d-flex wrapper_add_site align-items-center">
              <!-- <template v-if="isLoggedIn">
                <div
                  class="d-none d-md-flex"
                  @click="$store.dispatch('notifications/toggle')"
                >
                  <NotificationBell />
                </div>
                <div class="d-none divider d-md-flex"></div>
              </template> -->
              <div
                class="d-none d-md-flex"
                @click="$store.dispatch('addDomainModal/show')"
              >
                <AddDomainButton />
              </div>
            </div>
            <div class="d-none divider d-md-flex"></div>
          </template>
          <button v-if="!isLoggedIn" @click="openLogin" class="login">
            {{ $t('buttons.signInButton') }}
          </button>
          <div class="wrapper_my_account d-flex align-items-center" v-else>
            <Account />
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import DomainPicker from '@/components/DomainPicker.vue'
import { TELEGRAM_SUPPORT } from '@/helpers/variables'
import Date from '@/components/shared/Date.vue'
import getTrialPeriod from '@/helpers/payment/getTrialPeriod'
import InlineButton from '@/components/buttons/InlineButton.vue'
import Menu from '@/views/header/menu/Menu.vue'
import Account from '@/views/header/Account.vue'
import BurgerDarkIcon from '@/assets/icons/menu/BurgerDark.svg'
import Overlay from '@/components/add-domain/Overlay.vue'
import NotificationBell from '@/components/notifications/NotificationBell.vue'
import AddDomainButton from '@/components/add-domain/AddDomainButton.vue'

export default {
  data() {
    return {
      TELEGRAM_SUPPORT,
      tooltip: {
        isOpen: false,
        info: {},
      },
      BurgerDarkIcon,
      isMenuShowed: false,
    }
  },
  components: {
    DomainPicker,
    InlineButton,
    Menu,
    Overlay,
    Account,
    AddDomainButton,
    Date,
    NotificationBell,
  },
  computed: {
    isMainPage() {
      const splitedPath = this.$route.path.split('/')
      return splitedPath.includes('id')
    },
    ...mapState('mainData', {
      mainData: state => state,
    }),
    ...mapState(['view']),
    ...mapState('subscription', ['isLoading']),
    ...mapGetters('userInfo', ['isLoggedIn']),
    ...mapGetters('subscription', [
      'isSubscribeActive',
      'isTrial',
      'trialPeriod',
      'isTrialStarted',
      'paymentData',
      'isTrialEnded',
    ]),
    ...mapGetters(['domains']),
  },
  methods: {
    getTrialPeriod,
    setVisibilityMenu(value) {
      this.isMenuShowed = value
    },
    closeTooltip(id) {
      this.tooltip.isOpen = false
    },
    openLogin() {
      this.setFrom('header_login_button')
      this.setStep(1)
    },
    ...mapMutations('authModule', ['setStep', 'setFrom']),
    ...mapMutations('userInfo', ['logout']),
    goToMain() {
      const id = this.view.viewId
      if (id) {
        this.$router.push(`/id/${id}`)
      } else {
        this.$router.push('/')
      }
    },
  },
}
</script>
<style scoped>
.header_wrapper {
  height: 64px;
}
header {
  background: white;
  padding: 14px 0;
  position: fixed;
  z-index: 99;
  filter: drop-shadow(0px 4px 10px rgba(237, 237, 237, 0.25));
  box-shadow: 0px 4px 20px rgba(232, 232, 232, 0.2);
}

.header_content {
  max-width: 1905px;
  position: relative;
  margin-left: -50px;
  padding: 0 85px;
}

.burger_dark_icon {
  display: none;
  margin-right: 15px;
}

@media (max-width: 992px) {
  .header_content {
    margin-left: 0;
    padding: 0 20px;
  }
  .add_domain_button {
    display: none;
  }
  .burger_dark_icon {
    display: flex;
  }
}

@media (max-width: 662px) {
  .header_content {
    padding: 0 10px;
  }
  .wrapper_add_site {
    margin: 0;
  }
  .burger_dark_icon {
    margin-right: 10px;
  }
}

.logo_link {
  cursor: pointer;
}
.logo {
  width: 120px;
  margin-right: 24px;
}

.item_menu {
  margin-right: 30px;
  color: #5c5f6c;
  display: inline-flex;
  align-items: center;
}

.item_menu svg {
  margin-right: 5px;
}

.wrapper_add_site {
  color: #4e8cf9;
}

.divider {
  height: 20px;
  width: 1px;
  background: #d9e0f2;
  margin: auto 25px auto;
}

.item_menu:hover {
  cursor: pointer;
  text-decoration: underline;
}

.wrapper_my_account {
  position: relative;
}

.status_subscription {
  position: absolute;
  z-index: 1;
  left: 33px;
  font-size: 12px;
  cursor: pointer;
}

.metrica_plus {
  bottom: -1px;
}
.trial {
  background: url('/background_gradient_2.png');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  bottom: -7px;
}
.trial_ended {
  color: #aab2ba;
  font-size: 12px;
  bottom: -7px;
}

.login {
  padding: 6px 21px 7px 21px;
  border-radius: 7px;
  background: #ff72b2;
  color: white;
}

.menu_links {
  display: flex;
  align-items: center;
}

.lab_link span {
  margin-left: 8px;
  margin-bottom: 2px;
}

.drop_down.active button::after {
  margin-left: 15px;
}

.container_tooltip {
  margin-bottom: 4px;
}

@media (max-width: 1200px) {
  .item_menu {
    margin-right: 10px;
  }
}

@media (max-width: 992px) {
  .lab_link {
    display: none;
  }
}

.date {
  color: #aab2ba;
  font-size: 14px;
  margin-left: 1.5rem;
  /* display: flex; */
}
</style>
