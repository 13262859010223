<template>
  <div class="faq">
    <div v-if="!isMobile || !$route.hash" class="faq-menu" id="faq-accordeon">
      <div
        v-for="(page, i) in pages"
        :key="i"
        class="faq-item"
        :class="{ active: $route.hash === `#${page.name}` }"
      >
        <template v-if="page.children">
          <h2 class="accordion-header" :id="`heading-${page.name}`">
            <button
              class="faq-button accordion-button"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="`#${page.name}`"
              aria-expanded="true"
              :aria-controls="`#${page.name}`"
            >
              {{ page.title }}
            </button>
          </h2>

          <div
            :id="page.name"
            class="faq-collapse accordion-collapse collapse show"
            :aria-labelledby="`heading-${page.name}`"
            data-bs-parent="#faq-accordeon"
          >
            <a
              v-for="(child, j) in page.children"
              :key="j"
              class="faq-link"
              :class="{ active: $route.hash === `#${child.name}` }"
              :href="`#${child.name}`"
              @click="scrollToTop"
            >
              {{ child.title }}
            </a>
          </div>
        </template>

        <a
          v-else
          class="faq-link"
          :class="{ active: $route.hash === `#${page.name}` }"
          :href="`#${page.name}`"
          @click="scrollToTop"
        >
          {{ page.title }}
        </a>
      </div>
    </div>

    <div v-if="!isMobile || $route.hash" class="faq-main">
      <a class="faq-back-link" href="#">
        <img
          class="faq-back-link-icon"
          src="~@/assets/icons/base/arrow-left.svg"
          alt=""
        />

        <p class="faq-back-link-txt">
          {{ $t('faq.title') }}
        </p>
      </a>

      <div class="faq-content" v-html="pageContent" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedPage: '',
      isMobile: false,
    }
  },

  computed: {
    pages() {
      return [
        { name: 'data-origin', title: this.$t('faq.data-origin') },
        { name: 'new-data', title: this.$t('faq.new-data') },
        {
          name: 'traffic-difference',
          title: this.$t('faq.traffic-difference'),
        },
        { name: 'countries', title: this.$t('faq.countries') },
        {
          name: 'metrics',
          title: this.$t('faq.metrics'),
          children: [
            { name: 'metric-traffic', title: this.$t('faq.metric-traffic') },
            {
              name: 'metric-unique-visitors',
              title: this.$t('faq.metric-unique-visitors'),
            },
            {
              name: 'metric-bounce-rate',
              title: this.$t('faq.metric-bounce-rate'),
            },
            {
              name: 'metric-average-visit-duration',
              title: this.$t('faq.metric-average-visit-duration'),
            },
            {
              name: 'metric-pages-per-visit',
              title: this.$t('faq.metric-pages-per-visit'),
            },
            { name: 'metric-direct', title: this.$t('faq.metric-direct') },
            { name: 'metric-search', title: this.$t('faq.metric-search') },
            {
              name: 'metric-referral-sites',
              title: this.$t('faq.metric-referral-sites'),
            },
            {
              name: 'metric-social-media',
              title: this.$t('faq.metric-social-media'),
            },
            {
              name: 'metric-display-ads',
              title: this.$t('faq.metric-display-ads'),
            },
            { name: 'metric-email', title: this.$t('faq.metric-email') },
            {
              name: 'metric-keywords-volume',
              title: this.$t('faq.metric-keywords-volume'),
            },
            {
              name: 'metric-keywords-position',
              title: this.$t('faq.metric-keywords-position'),
            },
            {
              name: 'metric-keywords-cpc',
              title: this.$t('faq.metric-keywords-cpc'),
            },
            {
              name: 'metric-keywords-traffic',
              title: this.$t('faq.metric-keywords-traffic'),
            },
          ],
        },
      ]
    },

    pageContent() {
      if (!this.$route.hash) return ''

      const name = this.$route.hash.replace('#', '')
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return require(`@/assets/docs/faq/${this.$i18n.locale}/${name}.md`)
    },
  },

  created() {
    this.setMobile()
    if (this.$route.hash || this.isMobile) return

    this.$router.replace({ hash: `#${this.pages[0].name}` })
  },

  mounted() {
    window.addEventListener('resize', this.setMobile)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.setMobile)
  },

  methods: {
    setMobile() {
      this.isMobile = window.innerWidth <= 1024
    },

    scrollToTop() {
      window.scrollTo(0, 0)
    },
  },
}
</script>

<style scoped>
.faq {
  margin-top: -20px;
  margin-left: -30px;
  display: grid;
  grid-template-columns: minmax(500px, 1fr) 3fr;
  gap: 50px;
  min-height: 100%;
}

.faq-menu {
  background-color: #fff;
  box-shadow: 0px 4px 50px rgba(232, 232, 232, 0.7);
  padding: 35px 20px;
}

.faq-item {
  border-radius: 7px;
}

.faq-item:not(:first-child) {
  border-top: 1px solid #eaeef8;
}

.faq-item.active {
  border-color: var(--main-color);
}

.faq-item.active + .faq-item {
  border-color: transparent;
}

.faq-item:not(:first-child) .faq-link.active .faq-collapse .faq-link {
  border: none !important;
}

.faq-link,
.faq-button {
  cursor: pointer;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--text-primary);
  text-decoration: none;
  border-radius: 7px;
  padding: 20px;
}

.faq-collapse .faq-link {
  padding: 15px 20px;
  margin: 0 20px;
}

.faq-collapse .faq-link:not(.active) {
  font-weight: 400;
}

.faq-button:focus,
.faq-button:not(.collapsed) {
  border: none;
  box-shadow: none;
  background-color: #fff;
  color: var(--text-primary);
}

.faq-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.faq-link.active {
  background-color: var(--main-color);
  color: #fff;
}

.faq-main {
  padding: 45px 0;
}

.faq-back-link {
  display: none;
}

@media (max-width: 1024px) {
  .faq {
    grid-template-columns: 1fr;
    margin-left: 0;
  }

  .faq-menu {
    padding: 15px;
  }

  .faq-link,
  .faq-button {
    padding: 15px 0;
  }

  .faq-collapse .faq-link {
    padding: 15px 0;
    margin: 0;
  }

  .faq-main {
    padding: 15px;
  }

  .faq-back-link {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 6px;
    align-items: center;
    margin-bottom: 26px;
  }

  .faq-back-link-txt {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--main-color);
  }
}
</style>

<style>
.faq-content h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  color: var(--text-primary);
  border-bottom: 2px solid #eaeef8;
  padding-bottom: 25px;
  margin-bottom: 30px;
}

.faq-content *:not(h1) {
  max-width: 860px;
}

.faq-content h3 {
  margin-top: 40px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: var(--text-primary);
}

.faq-content h1 + h3 {
  margin-top: 30px;
}

.faq-content p,
.faq-content li {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--text-secondary);
}

.faq-content ul,
.faq-content ol {
  display: grid;
  gap: 15px;
  list-style: none;
  padding-left: 0;
}

.faq-content ol {
  counter-reset: item;
}

.faq-content ul li {
  margin-left: 26px;
}

.faq-content ul li::before {
  content: '\2022';
  color: var(--main-color);
  display: inline-block;
  font-size: 22px;
  margin-left: -21px;
  margin-right: 7px;
}

.faq-content ol li {
  margin-left: 34px;
}

.faq-content ol li::before {
  counter-increment: item;
  content: counters(item, '.') '.';
  suffix: '.';
  color: var(--text-primary);
  display: inline-block;
  font-size: 16px;
  width: 10px;
  line-height: 22px;
  margin-right: 11px;
  margin-left: -24px;
}

@media (max-width: 1024px) {
  .faq-content h1 {
    font-size: 24px;
    line-height: 33px;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .faq-content h3 {
    margin-top: 15px;
    margin-bottom: 7px;
    font-size: 16px;
    line-height: 22px;
  }

  .faq-content h1 + h3 {
    margin-top: 15px;
  }

  .faq-content p,
  .faq-content li {
    font-size: 14px;
    line-height: 19px;
  }

  .faq-content ul,
  .faq-content ol {
    gap: 10px;
  }

  .faq-content ul li {
    margin-left: 16px;
  }

  .faq-content ul li::before {
    display: inline-flex;
    margin-left: -16px;
    font-size: 19px;
    line-height: 19px;
    margin-right: 4px;
  }

  .faq-content ol li {
    margin-left: 20px;
  }

  .faq-content ol li::before {
    width: 10px;
    font-size: 14px;
    line-height: 19px;
    margin-right: 6px;
    margin-left: -16px;
  }
}
</style>
