<template>
  <div class="dropdown">
    <button
      class="dropdown-toggle drop_down"
      type="button"
      id="localeSwitcher"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <Flag :country="countries[$i18n.locale]" />
    </button>
    <ul class="dropdown-menu" aria-labelledby="localeSwitcher">
      <li
        v-for="local of locales.filter(el => el !== $i18n.locale)"
        :key="local"
        @click="changeLocal(local)"
      >
        <Flag :country="countries[local]" />
      </li>
    </ul>
  </div>
</template>

<script>
import { storage } from '@/helpers/base/localstorage'
import Flag from '@/components/shared/Flag.vue'
import { setCookie } from '@/helpers/base/cookie'

export default {
  data() {
    return {
      countries: {
        en: 'United Kingdom',
        ru: 'Russian Federation',
      },
    }
  },
  components: { Flag },
  computed: {
    locales() {
      return process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
    },
  },
  mounted() {
    // setCookie
    this.changeLocal(this.$i18n.locale)
  },
  methods: {
    changeLocal(locale) {
      this.$i18n.locale = locale
      setCookie('i18n_user_locale', locale, 360)
    },
  },
}
</script>

<style scoped>
span {
  color: white;
}
.dropdown {
  text-align: center;
}
button {
  background: none;
  text-align: center;
}
button :deep(.flag),
li :deep(.flag) {
  margin: 0;
  width: 20px;
  height: 20px;
}
button::after {
  display: none;
}
.dropdown-menu {
  width: 42px;
  min-width: auto;
  text-align: center;
  left: -4px !important;
}
li {
  cursor: pointer;
}
li:hover {
  background: #f9f9fc;
  border-radius: 7px;
}
</style>
