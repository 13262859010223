import { parseJwt } from '@/helpers/base/jwt'
import { deleteCookie } from '@/helpers/base/cookie'
import { logoutUserAmplitude } from '@/helpers/base/amplitude'

export const userInfo = {
  namespaced: true,
  state: () => ({
    jwt: null,
    isLoading: false,
  }),
  mutations: {
    setJwt(state, jwt) {
      state.jwt = jwt
    },
    logout(state) {
      state.jwt = null
      deleteCookie('jwt')
      window.location.reload()
      logoutUserAmplitude()
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading
    },
  },
  getters: {
    userInfo(state) {
      if (state.jwt) {
        return parseJwt(state.jwt)
      }

      return {}
    },
    isLoggedIn(state) {
      return !!state.jwt
    },
  },
}
