<template>
  <div class="d-flex align-items-center w-100">
    <div class="share">{{ getValue(share1, '%') }}</div>
    <div class="indicator">
      <div class="indicator2" :style="{ width: share1 + '%' }"></div>
    </div>
    <div class="share share2">{{ getValue(share2, '%') }}</div>
  </div>
</template>

<script>
import getValue from '@/helpers/processing/getValue'

export default {
  props: ['share1', 'share2'],
  methods: {
    getWidthValue(value) {
      if (value) return `${value}%`
      return '0%'
    },
    getValue,
  },
}
</script>

<style scoped>
.indicator {
  height: 10px;
  width: 100%;
  margin: 0 16px;
  background: #a6d6ff;
  border-radius: 0.5rem;
  overflow: hidden;
}
.indicator2 {
  height: 10px;
  border-radius: 0.5rem;
  background: #2684fe;
}
.share {
  min-width: 36px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0e0f10;
  text-align: left;
}

.share2 {
  text-align: right;
}
</style>
