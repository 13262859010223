<template>
  <WidgetWrapper>
    <WidgetTitle
      v-if="!loading"
      :title="$t('main.ecosystem.title')"
      :description="$t('main.ecosystem.description')"
    />
    <div v-if="isData" class="content text-center text-md-start" ref="content">
      <div class="d-flex justify-content-center justify-content-md-between">
        <div>
          <template
            v-for="item of dataOnActualDate.slice(0, 5)"
            :key="item.domainName"
          >
            <EcosystemItem
              :domain="item.domainName"
              :visits="item.traffic"
              :change="item.change"
            />
          </template>
        </div>
        <div class="d-none d-md-block mt-4">
          <img class="ecosystem_svg" :src="EcosystemIcon" />
        </div>
      </div>
      <ShowMoreButton2 @clickButton="onDynamicOpen" />
    </div>
    <Modal
      type="table"
      :closeHandler="close => (isOpenDynamic = false)"
      :isOpen="isOpenDynamic"
    >
      <template v-slot:title>
        {{ $t('main.ecosystem.title') }}
      </template>
      <template v-slot:body> <TableEcosystem /> </template>
    </Modal>
    <WidgetError v-if="!isData && !loading" />
    <SkeletonLoading v-if="loading" name="ecosystem" />
  </WidgetWrapper>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import WidgetTitle from '@/components/widgets/widgets-helpers/WidgetTitle.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import WidgetError from '@/components/errors/WidgetError.vue'
import EcosystemIcon from '@/assets/illustrations/Ecosystem.svg'
import EcosystemItem from '@/components/widgets/ecosystem/EcosystemItem.vue'
import WidgetWrapper from '@/components/widgets/widgets-helpers/WidgetWrapper.vue'
import ShowMoreButton2 from '@/components/buttons/ShowMoreButton2.vue'
import TableEcosystem from '@/components/tables/TableEcosystem.vue'
import Modal from '@/components/modals/Modal.vue'

export default {
  data() {
    return {
      isOpenDynamic: false,
      EcosystemIcon,
    }
  },
  components: {
    WidgetTitle,
    SkeletonLoading,
    WidgetError,
    EcosystemItem,
    ShowMoreButton2,
    WidgetWrapper,
    TableEcosystem,
    Modal,
  },
  computed: {
    ...mapGetters(['subscriptionActive']),
    ...mapGetters('ecosystem', ['dataOnActualDate', 'isData']),
    ...mapState('ecosystem', {
      ecosystem: state => state,
    }),
    loading() {
      return this.ecosystem.loading
    },
  },
  methods: {
    onDynamicOpen() {
      if (!this.subscriptionActive)
        return this.$store.dispatch(
          'trialEndedPopupModule/getPlanAndOpen',
          'ecosystem'
        )
      this.isOpenDynamic = true
    },
  },
}
</script>

<style scoped>
.wrapper_template_geo {
  display: none;
}

.wrapper_col_title {
  padding-bottom: 16px;
  margin-bottom: 12px;
  border-bottom: 1px solid #eaeef8;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

.template_geo {
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  height: 259px;
  padding: 5px 0 10px;
}

.indicator {
  margin: 0;
}

.domain {
  font-size: 14px;
  line-height: 19px;
  color: #5c5f6c;
}
</style>
