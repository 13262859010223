<template>
  <div
    class="d-flex wrapper_title flex-column flex-md-row justify-content-center align-items-md-start justify-content-md-between"
  >
    <div class="d-flex flex-column title">
      <!-- text-center text-md-start title -->
      <h2 class="d-flex title justify-content-center justify-content-md-start">
        {{ title }}
        <WidgetHint
          v-if="hintOptions?.title && hintOptions?.name"
          :hintOptions="hintOptions"
        />
      </h2>
      <h3 class="text-center text-md-start">
        {{ description }}
      </h3>
    </div>
    <Date
      class="date"
      v-if="actualDate"
      :actualDate="actualDate"
      :options="{ showFullDate: false, oldCalendar: true }"
    />
    <slot name="dropDown"></slot>
  </div>
</template>

<script>
import Date from '@/components/shared/Date.vue'
import WidgetHint from './WidgetHint.vue'

export default {
  components: { WidgetHint, Date },

  props: {
    title: String,
    description: String,
    hintOptions: { type: Object },
    actualDate: String,
  },
}
</script>

<style scoped>
.wrapper_title {
  margin-bottom: 20px;
  align-items: center;
}

.title {
  font-weight: bold;
}

.date {
  margin-top: 6px;
}

@media (max-width: 992px) {
  h3 {
    margin-bottom: 24px;
  }
}

h2 {
  margin-bottom: 8px;
}
</style>
