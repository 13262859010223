import { makeEvent } from '@/helpers/base/amplitude'
import { api } from '@/helpers/base/api'

export const trialEndedPopupModule = {
  namespaced: true,
  state: () => ({
    isLoading: false,
    isOpen: false,
    plans: [],
    name: '',
  }),
  mutations: {
    close(state) {
      state.isOpen = false
    },
    setName(state, name) {
      state.name = name
    },
    open(state) {
      state.isOpen = true
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading
    },
    setPlans(state, plans) {
      state.plans = plans
    },
  },
  actions: {
    async getPlanAndOpen(ctx, name) {
      const { rootState } = ctx

      if (!rootState.userInfo.jwt) {
        ctx.commit('authModule/setFrom', name, { root: true })
        return ctx.commit('authModule/setStep', 1, { root: true })
      }

      ctx.commit('setLoading', true)
      ctx.commit('open')
      ctx.commit('setName', name)

      makeEvent('more data', { feature: name })

      try {
        const { data } = await api.get('/subscription/getSubscriptionPlans')
        ctx.commit('setPlans', Object.values(data))
      } catch (err) {
        console.error(err)
      }

      ctx.commit('setLoading', false)
    },
  },
}
