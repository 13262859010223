<template>
  <WidgetWrapper>
    <WidgetTitle
      v-if="!loading"
      class="mb-35"
      :title="$t('main.keywords.title')"
      :description="$t('main.keywords.description')"
    >
      <template v-if="countryName && isData" v-slot:dropDown>
        <div class="country">
          <Flag :country="country" />
          {{ country }}
        </div>
      </template>
    </WidgetTitle>
    <div
      v-if="isData"
      class="content h-100 d-flex flex-column justify-content-between"
      ref="content"
    >
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th class="table_first_row" style="width: 28%" scope="col"></th>
              <th class="table_first_row" scope="col">
                <KeywordsRowHeadItem
                  :hintOptions="hintOptions.volume"
                  :name="$t('analytics.volume.title')"
                  iconLink="/keywords-icon/Diagram_perspective_matte.png"
                />
              </th>
              <th class="table_first_row" scope="col">
                <KeywordsRowHeadItem
                  :hintOptions="hintOptions.position"
                  :name="$t('analytics.position.title')"
                  iconLink="/keywords-icon/Chart_perspective_matte.png"
                />
              </th>
              <th class="table_first_row" scope="col">
                <KeywordsRowHeadItem
                  :hintOptions="hintOptions.cpc"
                  :name="$t('analytics.cpc.title')"
                  iconLink="/keywords-icon/Coin_perspective_matte.png"
                />
              </th>
              <th class="table_first_row" scope="col">
                <KeywordsRowHeadItem
                  :hintOptions="hintOptions.traffic"
                  :name="$t('analytics.traffic.title')"
                  iconLink="/keywords-icon/Browser_perspective_matte.png"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="keyword of keywords" :key="keyword.key">
              <th class="table_first_column" scope="row">
                {{ keyword.key }}
              </th>
              <td>
                <IndicatorTable
                  :value="nFormatter(getValue(keyword.vol))"
                  :width="Math.round(keyword.volShare) + '%'"
                />
              </td>
              <td>{{ getValue(keyword.pos) }}</td>
              <td>{{ getValue(keyword.cpc, '$') }}</td>
              <td>
                {{ nFormatter(getValue(keyword.traffic)) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ShowMoreButton
        class="mt-2"
        @clickCustom="onModalOpen()"
        :totalCount="totalCount"
        type="keywords"
      />
    </div>
    <Modal
      type="table"
      :closeHandler="close => (isModalOpen = false)"
      :isOpen="isModalOpen"
    >
      <template v-slot:title>
        {{ $t('main.keywords.title') }}
      </template>
      <template v-slot:body> <TableKeywords /> </template>
    </Modal>
    <WidgetError v-if="!isData && !loading" />
    <SkeletonLoading v-if="loading" name="keywords" />
  </WidgetWrapper>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import WidgetWrapper from '@/components/widgets/widgets-helpers/WidgetWrapper.vue'
import WidgetTitle from '@/components/widgets/widgets-helpers/WidgetTitle.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import WidgetError from '@/components/errors/WidgetError.vue'
import nFormatter from '@/helpers/processing/nFormatter'
import IndicatorTable from '@/components/widgets/widgets-helpers/IndicatorTable.vue'
import ShowMoreButton from '@/components/buttons/ShowMoreButton.vue'
import getValue from '@/helpers/processing/getValue'
import TableKeywords from '@/components/tables/TableKeywords.vue'
import Modal from '@/components/modals/Modal.vue'
import Flag from '@/components/shared/Flag.vue'

import KeywordsRowHeadItem from './KeywordsRowHeadItem.vue'

export default {
  data() {
    return {
      isModalOpen: false,
    }
  },
  components: {
    WidgetTitle,
    SkeletonLoading,
    WidgetError,
    WidgetWrapper,
    ShowMoreButton,
    IndicatorTable,
    KeywordsRowHeadItem,
    TableKeywords,
    Flag,
    Modal,
  },
  computed: {
    ...mapGetters(['subscriptionActive']),
    ...mapGetters('keywords', ['dataOnActualDateAll']),
    ...mapGetters('keywords', ['dataOnActualDate']),
    ...mapState('keywords', {
      keywordsState: state => state,
    }),
    hintOptions() {
      return {
        volume: {
          name: 'metric-keywords-volume',
          title: this.$t('faq.metric-keywords-volume'),
        },
        cpc: {
          name: 'metric-keywords-cpc',
          title: this.$t('faq.metric-keywords-cpc'),
        },
        position: {
          name: 'metric-keywords-position',
          title: this.$t('faq.metric-keywords-position'),
        },
        traffic: {
          name: 'metric-keywords-traffic',
          title: this.$t('faq.metric-keywords-traffic'),
        },
      }
    },
    isData() {
      return (
        !this.loading &&
        !this.keywordsState.error.status &&
        this.dataOnActualDateAllLocal?.keywords?.length
      )
    },
    loading() {
      // return true
      return this.keywordsState.loading
    },
    dataOnActualDateAllLocal() {
      const keywordsAll = this.dataOnActualDateAll[0]?.data[0]
      const keywords = this.dataOnActualDate[0]?.data?.[0]
      return keywordsAll?.keywords?.length ? keywordsAll : keywords
    },
    totalCount() {
      return this.dataOnActualDateAll[0]?.data[0]?.totalCount || 0
    },
    keywords() {
      return this.dataOnActualDateAllLocal?.keywords?.slice(0, 5)
    },
    countryName() {
      return this.dataOnActualDateAllLocal?.countryName?.toUpperCase()
    },
    country() {
      return this.dataOnActualDateAllLocal?.countryName
    },
  },
  methods: {
    nFormatter,
    getValue,
    onModalOpen() {
      if (!this.subscriptionActive)
        return this.$store.dispatch(
          'trialEndedPopupModule/getPlanAndOpen',
          'keywords'
        )
      this.isModalOpen = true
    },
  },
}
</script>

<style scoped>
.mb-35 {
  margin-bottom: 35px;
}
.pr-35 {
  padding-right: 35px;
}
.pl-35 {
  padding-left: 35px;
}
table {
  font-size: 14px;
}

tbody th,
tbody td {
  vertical-align: middle;
  font-size: 16px;
  line-height: 22px;
  color: #2c2738;
}

tbody {
  padding-top: 10px;
}

table th,
table td {
  font-weight: 600;
  border: none;
  padding: 11px;
}

.table-responsive {
  margin-bottom: 0;
}
.table_first_row {
  border-radius: 4px;
  border: none;
  border-bottom: 1px solid #eaeef8;
  color: #5c5f6c;
  font-size: 13px;
}
.table_first_column {
  padding-left: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #5c5f6c;
  max-width: 150px;
}

tbody tr:first-child th,
tbody tr:first-child td {
  border: none;
  padding-top: 16px;
}
thead th {
  padding: 14px;
}

/* .icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
} */
/* .country img {
  margin-bottom: 2px;
} */
.country {
  border: 1px solid #eaeef8;
  border-radius: 7px;
  padding: 8px 17px;
  font-size: 14px;
  color: #808493;
}
</style>
