<template>
  <div class="d-flex flex-column">
    <div class="image_wrapper text-center">
      <img :src="NoFoundDataIllustration" />
    </div>
    <div class="text_wrapper text-center">
      <h2 class="text_title">{{ $t('components.notFoundData.title') }}</h2>
      <h3 class="text_body">
        {{ $t('components.notFoundData.lowTraffic') }}<br />
        {{ $t('components.notFoundData.anotherSites') }}
      </h3>
    </div>
    <div class="button_wrapper d-flex justify-content-center">
      <Button
        class="button"
        color="blue"
        @click="$store.dispatch('addDomainModal/show')"
      >
        {{ $t('buttons.find') }}
      </Button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

import NoFoundDataIllustration from '@/assets/illustrations/NotFoundData.svg'
import Button from '@/components/buttons/Button.vue'

export default {
  data() {
    return {
      isAddModalShowed: false,
      NoFoundDataIllustration,
    }
  },
  computed: {},
  components: {
    Button,
  },
  methods: {},
}
</script>

<style scoped>
.button {
  max-width: 243px;
  height: 37px;
}
.text_wrapper,
.image_wrapper {
  margin-bottom: 45px;
}
.text_title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.text_body {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}
</style>
