<template>
  <WidgetWrapper>
    <WidgetTitle
      v-if="!loading"
      :title="$t('main.displayNetwork.title')"
      :description="$t('main.displayNetwork.description')"
      :actualDate="isData ? adNetwork.actualDate : null"
    />
    <div class="content" v-if="isData" ref="content">
      <WidgetListTitle
        class="title mb-2 mt-2"
        :firstName="$t('main.displayNetwork.widgetListTitle')"
        :secondName="$t('main.displayNetwork.widgetListValue')"
      />
      <template v-for="item of mainDomainData" :key="item.networkName">
        <DomainShareItem
          :domain="getNetworkName(item)"
          :name="item.networkName"
          :share="item.share + '%'"
          :change="0"
        />
      </template>
    </div>
    <WidgetError v-if="!isData && !loading" />
    <SkeletonLoading v-if="loading" name="sources" />
  </WidgetWrapper>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import WidgetTitle from '@/components/widgets/widgets-helpers/WidgetTitle.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import WidgetError from '@/components/errors/WidgetError.vue'
import WidgetListTitle from '@/components/widgets/widgets-helpers/WidgetListTitle.vue'
import DomainShareItem from '@/components/widgets/traffic-sources/DomainShareItem.vue'
import WidgetWrapper from '@/components/widgets/widgets-helpers/WidgetWrapper.vue'
import { adNetworksList } from '@/helpers/adNetworksList'

export default {
  data() {
    return {
      title: 'Рекламные сети',
      description: 'Баннерная, PPC, видео и др. реклама на сайтах через сети',
      adNetworksList,
    }
  },
  components: {
    WidgetTitle,
    SkeletonLoading,
    WidgetError,
    WidgetListTitle,
    DomainShareItem,
    WidgetWrapper,
  },
  computed: {
    ...mapGetters('adNetworks', ['dataOnActualDate', 'mainDomainData']),
    ...mapState('additionalData', {
      additionalData: state => state,
    }),
    ...mapState('adNetworks', {
      adNetwork: state => state,
    }),
    isData() {
      return (
        !this.loading &&
        !this.additionalData.error.status &&
        this.mainDomainData?.length
      )
    },
    loading() {
      return this.additionalData.loading
    },
  },
  methods: {
    getNetworkName(item) {
      return this.adNetworksList[item.networkName?.toLowerCase()]
    },
  },
}
</script>

<style scoped>
.content {
  margin: 0 -20px;
}
.title {
  padding: 0 20px;
}
</style>
