export const navigation = {
  namespaced: true,
  state: () => ({
    URLSearchParams: new URLSearchParams(window.location.search),
  }),
  getters: {
    domains(state) {
      const domains = state.URLSearchParams.get('domains')
        ? state.URLSearchParams.get('domains').split(',')
        : []
      return domains
    },
  },
}
