import requestHelper from '@/helpers/store/requestHelper'
import { filterOnActualDate } from '@/helpers/store/filterOnActualDate'
import { setActualDate } from '@/helpers/store/setActualDate'
import { filterBySelectedDomain } from '@/helpers/store/filterBySelectedDomain'
import { i18n } from '@/lang'

export const keywords = {
  namespaced: true,
  actions: {
    // All keys
    async load(ctx) {
      const { selectedDomain } = ctx.rootGetters
      await requestHelper(
        {
          url: '/data/getKeywords',
          domains: selectedDomain,
          viewId: ctx.rootState.view.viewId,
        },
        ctx,
        'updateDataAll'
      )
    },
    // Only 5 keys
    set(ctx, data) {
      ctx.commit('updateData', data)
      ctx.commit('updateActualDate')
    },
  },
  state: {
    dataAll: [],
    data: [],
    actualDate: null,
    platforms: 'desktop',
    loading: true,
    error: { status: false },
  },
  mutations: {
    updateData(state, data) {
      const adaptedData = data.map(dataItem => {
        return {
          domainName: dataItem.domainName,
          data: dataItem?.search?.map(dataSearch => {
            return {
              countryName: 'World',
              language: 'World',
              date: dataSearch.date,
              keywords: dataSearch.data?.keywords?.organic?.map(keyword => {
                return {
                  cpc: null,
                  traffic: null,
                  share: keyword.share,
                  volShare: null,
                  vol: null,
                  pos: null,
                  key: keyword.keyword,
                  page: null,
                }
              }),
            }
          }),
        }
      })
      state.data = adaptedData
    },
    updateDataAll(state, data) {
      const adaptedData = [
        {
          domainName: data.domainName,
          data: data[0].data
            .map(dataItem => {
              if (!dataItem) return {}
              const { totalTraffic } = dataItem
              return {
                countryName: dataItem.countryName,
                language: dataItem.language,
                totalCount: dataItem.totalCount,
                keywords: dataItem.keywords.map(keyword => {
                  return {
                    cpc: keyword.cpc,
                    traffic: keyword.traffic,
                    vol: keyword.vol,
                    pos: keyword.pos,
                    key: keyword.key,
                    page: keyword.page,
                  }
                }),
              }
            })
            .reverse(),
        },
      ]
      state.dataAll = adaptedData
    },
    updateActualDate(state, value) {
      state.actualDate = setActualDate(state.data, value)
    },
    updateLoading(state, status) {
      state.loading = status
    },
    updateError(state, errorData) {
      state.error = errorData
    },
  },
  getters: {
    dataOnActualDate(state, getters, rootState, rootGetters) {
      const { selectedDomain } = rootGetters
      const data = filterOnActualDate(state.data, state.actualDate)
      return filterBySelectedDomain(data, selectedDomain)
    },
    dataOnActualDateAll(state) {
      return state.dataAll
    },
    tableData(state, getters, rootState, rootGetters) {
      const fields = [
        {
          key: 'key',
          label: i18n.global.t('analytics.keyword.title'),
          sortable: true,
        },
        {
          key: 'vol',
          label: i18n.global.t('analytics.volume.title'),
          sortable: true,
        },
        {
          key: 'pos',
          label: i18n.global.t('analytics.position.title'),
          sortable: true,
        },
        {
          key: 'cpc',
          label: i18n.global.t('analytics.cpc.title'),
          sortable: true,
        },
        {
          key: 'traffic',
          label: i18n.global.t('analytics.traffic.title'),
          sortable: true,
        },
        {
          key: 'page',
          label: i18n.global.t('analytics.page.title'),
          sortable: true,
        },
      ]
      return {
        items: getters.dataOnActualDateAll[0].data,
        fields,
      }
    },
  },
}
