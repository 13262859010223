import getChannelSortIndex from '@/helpers/store/getChannelSortIndex'
import { filterOnActualDate } from '@/helpers/store/filterOnActualDate'
import { setActualDate } from '@/helpers/store/setActualDate'
import { saveRightOrderDomains } from '@/helpers/store/saveRightOrderDomains'
import {
  calcSumChannelTraffic,
  getChannelData,
  getChannelValue,
  getTrafficValue,
} from '@/helpers/store/calcSumChannelTraffic'
import fillGapsDate from '@/helpers/store/fillGapsDate'

import getColor from '@/helpers/store/getColor'

export const marketingChannels = {
  namespaced: true,
  actions: {
    async set(ctx, data) {
      ctx.commit('updateData', data)
      ctx.commit('updateActualDate')
    },
  },
  state: {
    data: [],
    actualDate: null,
    platforms: 'desktop',
    activeChannel: 'Social',
  },
  mutations: {
    updateData(state, data) {
      const result = data.map((dataItem, index) => {
        return {
          domainName: dataItem.domainName,
          data: fillGapsDate(dataItem.marketingMix, data).map(item => {
            return {
              date: item.date,
              data: {
                Direct: item.data?.Direct || null,
                Search: item.data?.Search || null,
                Referrals: item.data?.Referrals || null,
                Social: item.data?.Social || null,
                Mail: item.data?.Mail || null,
                'Paid Referrals':
                  item.data?.['Paid Referrals'] || item.data?.Display || null,
              },
            }
          }),
        }
      })
      state.data = result
    },
    setActiveChannel(state, channel) {
      state.activeChannel = channel
    },
    updateActualDate(state, value) {
      console.log(state.data, value)
      state.actualDate = setActualDate(state.data, value, 'marketing-mix')
    },
  },
  getters: {
    dataOnActualDate(state, getters, rootState, rootGetters) {
      const { domains } = rootGetters
      const data = filterOnActualDate(state.data, state.actualDate)
      return saveRightOrderDomains(data, domains)
    },
    channelsName(state) {
      return Object.keys(state.data[0]?.data[0]?.data || {})
    },
    datasetOnActualDate(state, getters, rootState) {
      return getters.channelsName.map(channelName => {
        const channelSumTraffic = calcSumChannelTraffic(
          getters.dataOnActualDate,
          rootState.traffic,
          channelName
        )
        return {
          labels: getters.dataOnActualDate.map(item => item.domainName),
          datasets: [
            {
              sortIndex: getChannelSortIndex(channelName),
              label: channelName,
              data: getChannelData(
                getters.dataOnActualDate,
                rootState.traffic,
                channelName,
                channelSumTraffic
              ),
            },
          ],
        }
      })
    },
    datasetFull(state, getters, rootState, rootGetters) {
      const index = state.data
        .map((item, i) => {
          return { len: item.data.length, i }
        })
        .sort((a, b) => b.len - a.len)[0] || { i: 0 }
      const labels = state.data[index.i].data.map(item => item.date) || []

      const { domains } = rootGetters
      const datasets = domains.map((domainName, index) => {
        const channelSumTraffic = calcSumChannelTraffic(
          getters.dataOnActualDate,
          rootState.traffic,
          state.activeChannel
        )
        const data = state.data.filter(item => item.domainName === domainName)
        const resultData = data[0].data.map(dataItem => {
          const trafficValue = getTrafficValue(
            rootState.traffic,
            domainName,
            dataItem.date
          )
          return getChannelValue(
            dataItem.data[state.activeChannel],
            trafficValue,
            channelSumTraffic
          ).absolute
        })
        return {
          label: domainName,
          pointBackgroundColor: 'rgb(0,0,0,0)',
          borderColor: getColor(index),
          backgroundColor: 'rgba(0,0,0,0)',
          data: resultData.slice(-37),
        }
      })
      return {
        labels: labels.slice(-37),
        datasets,
      }
    },
    datasetOneDomain(state, getters, rootState, rootGetters) {
      const labels = state.data[0].data.map(item => item.date) || []

      const datasets = getters.channelsName.map((channelName, index) => {
        const channelSumTraffic = calcSumChannelTraffic(
          getters.dataOnActualDate,
          rootState.traffic,
          state.activeChannel
        )

        const resultData = state.data[0].data.map(dataItem => {
          const trafficValue = getTrafficValue(
            rootState.traffic,
            state.data[0].domainName,
            dataItem.date
          )
          return getChannelValue(
            dataItem.data[channelName],
            trafficValue,
            channelSumTraffic
          ).absolute
        })
        return {
          label: channelName,
          pointBackgroundColor: 'rgb(0,0,0,0)',
          borderColor: getColor(index),
          backgroundColor: 'rgba(0,0,0,0)',
          data: resultData.slice(-37),
        }
      })
      return {
        labels: labels.slice(-37),
        datasets,
      }
    },
  },
}
