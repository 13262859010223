/* eslint-disable max-len */
export default function(value, format) {
  const { datasets } = value
  const { labels } = value
  if (!datasets || !labels) return

  const presets = [
    { months: 6, period: '6months' },
    { months: 13, period: '1year' },
    { months: 37, period: '3years' },
    { months: 999, period: 'all' },
  ]

  for (const preset of presets) {
    if (preset.period === format) {
      const filteredDatasets = []
      datasets.forEach(dataset => {
        const sliceAt =
          dataset.data.length < preset.months
            ? 0
            : dataset.data.length - preset.months
        const sliceEnd = dataset.data.length
        const filteredDataset = {
          label: dataset.label,
          borderColor: dataset.borderColor,
          backgroundColor: dataset.backgroundColor,
          data: dataset.data.slice(sliceAt, sliceEnd),
        }
        filteredDatasets.push(filteredDataset)
      })
      const sliceAt =
        labels.length < preset.months ? 0 : labels.length - preset.months
      const sliceEnd = labels.length
      return {
        datasets: filteredDatasets,
        labels: labels.slice(sliceAt, sliceEnd),
      }
    }
  }
}
