// Components
import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/layouts/Home.vue'
import MainPage from '@/views/MainPage.vue'
import PricesPage from '@/views/PricesPage.vue'
import PersonalArea from '@/views/PersonalArea.vue'
import NotFoundPage from '@/views/NotFoundPage.vue'
import SuccessPaidPage from '@/views/SuccessPaidPage.vue'
import FaqPage from '@/views/Faq.vue'
import store from '@/store'

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({ top: 0, left: 0 }),
  routes: [
    {
      path: '/',
      component: Home,
      children: [
        {
          path: '/',
          component: MainPage,
          beforeEnter(to, from, next) {
            window.location.href = process.env.VUE_APP_LANDING
          },
        },
        {
          path: '/id',
          component: MainPage,
        },
        {
          path: '/id/:id',
          name: 'main',
          component: MainPage,
        },
        {
          path: '/pricing',
          name: 'pricing',
          component: PricesPage,
          alias: '/prices',
        },
        {
          path: '/account',
          component: PersonalArea,
          meta: {
            requiresAuth: true,
          },
          alias: '/subscribe',
        },
        {
          path: '/faq',
          name: 'faq',
          component: FaqPage,
        },
        {
          path: '/success_paid',
          component: SuccessPaidPage,
        },
        {
          path: '/r/:id',
          component: NotFoundPage,
          props: {
            errorData: {
              name: 'oldLink',
            },
          },
        },
        /** support old links */
        {
          path: '/engagement',
          component: MainPage,
          beforeEnter(to, from, next) {
            const urlSearchParams = new URLSearchParams(to.fullPath)
            const sites = urlSearchParams.get('sites')
            if (!sites) return
            window.location.href = `/id?domains=${sites}`
          },
        },
      ],
    },
  ],
  components: {
    Home,
    MainPage,
  },
})

router.beforeEach((to, from, next) => {
  document.body.setAttribute('route', to.name || '')

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['userInfo/isLoggedIn']) {
      store.commit('authModule/setStep', 1)
      // setCookie('redirectTo', to.fullPath, 1)
      next({
        path: '/id/1',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
