<template>
  <div class="head">
    <img class="logo" :src="iconLink" />
    {{ name }}
    <WidgetHint :hintOptions="hintOptions" />
  </div>
</template>

<script>
import WidgetHint from '@/components/widgets/widgets-helpers/WidgetHint.vue'

export default {
  components: { WidgetHint },
  props: ['name', 'iconLink', 'hintOptions'],
}
</script>

<style scoped>
.logo {
  width: 13px;
  height: 13px;
  margin-right: 4px;
}
.head {
  white-space: nowrap;
}
</style>
