<template>
  <Modal
    :styleModal="{ width: '500px' }"
    :closeHandler="closeCancelSubscriptionModal"
    :isOpen="isSubscriptionModalCancelOpen"
  >
    <template v-slot:title>
      <div class="title_modal">
        {{ $t('modals.subscriptionCancel.youActually') }} <br />
        {{ $t('modals.subscriptionCancel.wantCancel') }}
      </div>
    </template>
    <template v-slot:body>
      <div class="buttons">
        <Button @click="cancelSub" :color="'pink'">
          {{ $t('modals.subscriptionCancel.cancel') }}
        </Button>
        <Button @click="continueToUse" :color="'white'">
          {{ $t('modals.subscriptionCancel.keep') }}
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { makeEvent } from '@/helpers/base/amplitude'
import Modal from '@/components/modals/Modal.vue'
import Button from '../buttons/Button.vue'

export default {
  name: 'SubscriptionCancel',
  components: {
    Modal,
    Button,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('subscription', ['isSubscriptionModalCancelOpen']),
  },
  methods: {
    ...mapMutations('subscription', ['closeCancelSubscriptionModal']),
    ...mapActions('subscription', ['cancelSubscription']),
    continueToUse() {
      makeEvent('cancel subscription', {
        button: 'continue to use',
      })
      this.closeCancelSubscriptionModal()
    },
    cancelSub() {
      makeEvent('cancel subscription', {
        button: 'cancel_subsrciption_accept',
      })
      this.cancelSubscription()
    },
  },
}
</script>

<style scoped>
.title_modal {
  /* width: 275px; */
  text-align: center;
}
.modal_description {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #5c5f6c;
}
.metrica_text {
  display: inline-flex;
  background: url('/background_gradient_2.png');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: 15px;
  font-weight: 500;
}
.buttons {
  display: grid;
  margin-top: 55px;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}
.feature {
  font-size: 14px;
  line-height: 16px;
  color: #0e0f10;
}
.features {
  margin-top: 38px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;
}
</style>
