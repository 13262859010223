<template>
  <div class="wrapper_filters">
    <div
      v-for="item in traffic.periods"
      :key="item.title"
      class="period"
      :class="{ selected: getSelected(item.value, traffic.activePeriod) }"
      @click="onChange(item)"
    >
      {{ $t(`main.traffic.${item.value}`) }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapGetters(['subscriptionActive', 'creatorSubscriptionActive']),
    ...mapState('traffic', {
      traffic: state => state,
    }),
  },
  methods: {
    ...mapActions('traffic', ['changePeriod']),
    ...mapMutations('traffic', ['updateActivePeriod']),
    getSelected: (value, activePeriod) => {
      if (value === '1year' && !activePeriod) return true
      if (value === activePeriod) return true
    },
    onChange(item) {
      if (this.subscriptionActive || this.creatorSubscriptionActive)
        return this.changePeriod(item.value)
      if (item.months > 12) return this.showBitePopup()
      return this.changePeriod(item.value)
    },
    changePeriod(item) {
      this.updateActivePeriod(item)
    },
    showBitePopup() {
      this.$store.dispatch('trialEndedPopupModule/getPlanAndOpen', 'traffic')
    },
  },
}
</script>

<style scoped>
.wrapper_filters {
  display: flex;
  margin: 5px 0;
}
.period {
  background: #f8f9fe;
  border-radius: 8px;
  height: 37px;
  width: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #808493;
  cursor: pointer;
}

.period + .period {
  margin-left: 7px;
}

.period.selected {
  background: #2684fe;
  box-shadow: 0px 4px 20px rgba(232, 232, 232, 0.2);
  color: #ffffff;
}
</style>
