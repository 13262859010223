<template>
  <WidgetWrapper class="justify-content-between">
    <WidgetTitle
      v-if="!loading"
      :title="$t('main.uniqueUsers.title')"
      :description="$t('main.uniqueUsers.description')"
      :hintOptions="hintOptions"
    />
    <div v-if="isData" class="content" ref="content">
      <div>
        <div class="d-flex flex-wrap g-0">
          <WidgetListTitle
            class="widget_title_list col"
            :firstName="$t('main.uniqueUsers.WidgetListTitle')"
            :secondName="$t('main.uniqueUsers.widgetListValue')"
          />
          <div
            class="d-flex item align-items-center col-12 g-0"
            v-for="dataItem of dataOnActualDate"
            :key="dataItem.domainName"
          >
            <Domain
              class="d-flex flex-nowrap align-items-center col-4"
              :domain="dataItem.domainName"
            />

            <div class="d-flex align-items-center col">
              <div class="col">
                <Indicator
                  v-if="
                    Object.keys(dataOnActualDate).length > 1 &&
                      (creatorSubscriptionActive || subscriptionActive)
                  "
                  :width="getIndicatorWidth(dataItem)"
                />
              </div>
              <div class="visitors col-3 text-end px-0">
                <template
                  v-if="creatorSubscriptionActive || subscriptionActive"
                >
                  {{ getUniqueVisitorsValue(dataItem) }}
                </template>
                <template v-else>
                  <button
                    class="buttonShowUniqueUsers"
                    @click="showBitePopup()"
                  >
                    {{ $t('buttons.show') }}
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <WidgetError v-if="!isData && !loading" />
    <SkeletonLoading v-if="loading" name="uniqueUsers" />
  </WidgetWrapper>
</template>

<script>
import WidgetTitle from '@/components/widgets/widgets-helpers/WidgetTitle.vue'
import Indicator from '@/components/widgets/widgets-helpers/Indicator.vue'
import Domain from '@/components/widgets/widgets-helpers/Domain.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import nFormatter from '@/helpers/processing/nFormatter'
import getValue from '@/helpers/processing/getValue'
import WidgetError from '@/components/errors/WidgetError.vue'
import WidgetWrapper from '@/components/widgets/widgets-helpers/WidgetWrapper.vue'
import WidgetListTitle from '@/components/widgets/widgets-helpers/WidgetListTitle.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {}
  },
  components: {
    WidgetTitle,
    SkeletonLoading,
    WidgetError,
    Indicator,
    Domain,
    WidgetWrapper,
    WidgetListTitle,
  },
  computed: {
    ...mapGetters(['creatorSubscriptionActive', 'subscriptionActive']),
    ...mapState('uniqueVisitors', {
      uniqueVisitors: state => state,
    }),
    ...mapGetters('uniqueVisitors', ['dataOnActualDate']),
    isData() {
      return (
        !this.loading &&
        !this.uniqueVisitors.error.status &&
        this.uniqueVisitors.data.length
      )
    },
    hintOptions() {
      return {
        name: 'metric-unique-visitors',
        title: this.$t('faq.metric-unique-visitors'),
        actualDate: this.uniqueVisitors.actualDate,
        platforms: this.uniqueVisitors.platforms,
      }
    },
    loading() {
      return this.uniqueVisitors.loading
    },
  },
  methods: {
    nFormatter,
    getValue,
    getUniqueVisitorsValue(dataItem) {
      const { min } = dataItem?.data || 0
      const { max } = dataItem?.data || 0
      return this.getValue(this.nFormatter((min + max) / 2))
    },
    getIndicatorWidth(dataItem) {
      const { min } = dataItem?.data || 0
      const { max } = dataItem?.data || 0
      const uniqueUsers = (min + max) / 2
      let total = 0

      this.uniqueVisitors.data.forEach(item => {
        if (!item.data) return
        total += (item.data.min + item.data.max) / 2
      })

      const width = Math.round((uniqueUsers / total) * 100)
      return width
    },
    showBitePopup() {
      this.$store.dispatch(
        'trialEndedPopupModule/getPlanAndOpen',
        'unique_users'
      )
    },
  },
}
</script>

<style scoped>
@media (min-width: 768px) {
  .wrapper_title {
    margin-bottom: 0;
  }
}
.points {
  overflow: hidden;
  margin: 0 16px;
  color: #d9e0f2;
}
.item {
  white-space: nowrap;
  position: relative;
  padding: 16px 0;
}
.item + .item {
  border-top: 1px solid #eaeef8;
}
.icon {
  width: 16px;
  height: 16px;
  margin-bottom: 6px;
  margin-left: 8px;
}
.empty {
  margin-bottom: 12px;
  font-size: 13px;
}
.buttonShowUniqueUsers {
  background: none;
  color: #2684fe;
  padding: 0;
  font-weight: 600;
}
.buttonShowUniqueUsers:hover {
  text-decoration: underline;
}

.widget_title_list {
  margin-bottom: 12px;
}
.distribution_name {
  font-size: 13px;
  color: #aeb0ba;
}

.visitors {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #0e0f10;
}
</style>
