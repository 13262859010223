<template>
  <button class="show_more_button mt-4" @click="$emit('clickButton')">
    <span>{{ $t('buttons.showAllProducts') }} </span>
  </button>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {},
  components: {},
}
</script>

<style scoped>
.show_more_button {
  padding: 9px 45px;
  border-radius: 7px;
  background: var(--button-pink);
  color: white;
  font-weight: bold;
}
.show_more_button:hover {
  background: var(--button-pink-hover);
}
</style>
