export default function getTrialPeriod(validTill) {
  if (!validTill) return { years: null, days: null, months: null }
  const diff = Math.floor(Date.parse(validTill) - Date.now())

  const day = 1000 * 60 * 60 * 24

  const days = Math.ceil(diff / day)
  const months = Math.floor(days / 31)
  const years = Math.floor(months / 12)

  return { years, days, months }
}

export const timeDiff = validTill => {
  // get total seconds between the times
  let delta = Math.abs(Date.parse(validTill) - Date.now()) / 1000

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400)
  delta -= days * 86400

  // calculate (and subtract) whole hours
  const hours = Math.ceil(delta / 3600) % 24
  delta -= hours * 3600

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60
  delta -= minutes * 60

  // what's left is seconds
  const seconds = delta % 60

  return {
    seconds,
    minutes,
    hours,
    days,
  }
}
