<template>
  <footer class="footer">
    <div class="footer-main">
      <div class="footer-main-left">
        <router-link class="footer-logo-link" to="/">
          <img class="footer-logo" src="/logo_white.svg" />
        </router-link>

        <router-link class="footer-main-link" to="/faq">
          {{ $t('footer.faq') }}
        </router-link>
      </div>

      <div class="footer-main-right">
        <a class="footer-contact" :href="TELEGRAM_SUPPORT" target="_blank">
          <img src="~@/assets/icons/telegram-blue.svg" />
          <p class="footer-contact-txt">{{ $t('footer.contact') }}</p>
        </a>

        <a
          class="footer-extension"
          href="https://chrome.google.com/webstore/detail/metrica-extension/egbpanhlmgjioikbjgaiihfkpkfafapg"
          target="_blank"
        >
          <img class="footer-extension-icon" src="/extension.png" />
          <p class="footer-extension-title">{{ $t('footer.install') }}</p>
          <p class="footer-extension-desc">{{ $t('footer.extension') }}</p>
        </a>
      </div>
    </div>

    <!-- <div class="footer-extra">
      <p class="footer-copy">{{ $t('footer.copy') }}</p>

      <div class="footer-links">
        <a class="footer-link" target="_blank" href="/tos.pdf">
          {{ $t('footer.tos') }}
        </a>

        <a class="footer-link" target="_blank" href="/privacy-policy.pdf">
          {{ $t('footer.privacyPolicy') }}
        </a>
      </div>
    </div> -->
  </footer>
</template>

<script>
import { TELEGRAM_SUPPORT } from '@/helpers/variables'

export default {
  computed: {
    TELEGRAM_SUPPORT: () => TELEGRAM_SUPPORT,
  },
}
</script>

<style scoped>
.footer {
  background: #2c2738;
  color: var(--text-secondary3);
  position: relative;
}

.footer-main {
  margin: 25px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-main-left {
  display: grid;
  grid-auto-flow: column;
  gap: 60px;
  align-items: center;
}

.footer-logo {
  width: 160px;
}

.footer-main-link {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.footer-main-right {
  display: grid;
  grid-auto-flow: column;
  gap: 50px;
  align-items: center;
}

.footer-contact {
  display: grid;
  grid-auto-flow: column;
  gap: 16px;
  align-items: center;
  color: #ffffff;
}

.footer-contact-txt {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.footer-extension {
  padding: 10px 15px;
  background-color: transparent;
  border-radius: 7px;
  display: grid;
  grid-template: 'icon title' 'icon desc';
  align-items: center;
  column-gap: 15px;
  background-color: rgba(255, 255, 255, 0.07);
  transition: all 200ms ease;
}

.footer-extension:hover {
  background-color: rgba(255, 255, 255, 0.15);
  text-decoration: none;
}

.footer-extension-icon {
  grid-area: icon;
}

.footer-extension-title {
  margin: 0;
  grid-area: title;
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  color: #ffffff;
}

.footer-extension-desc {
  margin: 0;
  grid-area: desc;
  font-size: 11px;
  line-height: 14px;
  color: #ffffff;
}

.footer-extension-icon {
  width: 29px;
  height: 29px;
}

.footer-extra {
  padding: 20px 60px;
  border-top: 1px solid #565163;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-copy {
  margin: 0;
  font-size: 14px;
  line-height: 19px;
  color: var(--text-secondary2);
}

.footer-links {
  display: grid;
  grid-auto-flow: column;
  gap: 40px;
}

.footer-link {
  font-size: 14px;
  line-height: 19px;
  color: var(--text-secondary2);
}

@media (max-width: 1024px) {
  .footer-copy,
  .footer-extension {
    display: none;
  }

  .footer-main {
    margin: 20px;
    flex-direction: column;
  }

  .footer-logo-link {
    display: flex;
    justify-content: center;
  }

  .footer-logo {
    width: 140px;
  }

  .footer-main-left {
    grid-auto-flow: row;
    gap: 20px;
  }

  .footer-main-right {
    margin-top: 20px;
  }

  .footer-links {
    grid-auto-flow: row;
    gap: 15px;
    text-align: center;
    width: 100%;
  }
}
</style>

<style>
@media (max-width: 768px) {
  body[route='main'] .footer {
    margin-bottom: 52px;
  }

  body[route='pricing'] .footer {
    margin-bottom: 208px;
  }
}
</style>
