<template>
  <div class="d-flex">
    <div class="d-flex wrapper_statistics text-center text-md-start item col">
      <Domain :domain="domain" :isLink="true" />
      <GrowRate v-if="change" class="ps-2" :change="change" />
    </div>
  </div>
</template>

<script>
import GrowRate from '@/components/shared/GrowRate.vue'
import Domain from '@/components/widgets/widgets-helpers/Domain.vue'
import nFormatter from '@/helpers/processing/nFormatter'
import options from './options.js'

export default {
  data() {
    return {
      dataCollection: {
        labels: [0, 0, 0, 0],
        datasets: [
          {
            label: 'Data One',
            borderColor: '#2684FE',
            backgroundColor: 'rgba(3,61,223, 0.05)',
            data: [12312, 32212, 122344, 23423],
          },
        ],
      },
      options,
    }
  },
  components: {
    GrowRate,
    Domain,
  },
  props: ['visits', 'change', 'domain'],
  methods: {
    nFormatter,
  },
}
</script>

<style scoped>
.traffic {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0e0f10;
}
.wrapper_statistics {
  white-space: nowrap;
  margin: 12px 0;
}
</style>
