<template>
  <span class="text">{{ text }}</span>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: ['text'],
  components: {},
  methods: {},
}
</script>

<style scoped>
.text {
  background: linear-gradient(
    90deg,
    #ff70a3 0%,
    #fe6c74 25.65%,
    #b260ed 58.98%,
    #a162fa 80.65%,
    #70a8f7 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900 !important;
}
</style>
