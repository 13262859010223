<template>
  <div class="wrapper_search_main">
    <div class="d-flex wrapper_search">
      <div class="wrapper_icons" @click="$refs.search.focus()">
        <div v-if="loading" class="icon_size loader"></div>
        <img
          v-if="addedSuccess"
          class="icon_size success"
          :src="CheckmarkIcon"
        />
        <img
          v-if="!loading && !addedSuccess"
          class="search_icon icon_size"
          :src="SearchIcon"
        />
      </div>
      <input
        type="search"
        :placeholder="$t('components.search.placeholder')"
        v-model="searchQuery"
        ref="search"
        v-on:keyup="addDomainHandler({ event: $event, domain: searchQuery })"
        @input="hideHintsIfEmptySearch()"
      />
      <button class="cancel" @click="$emit('hide', false)">
        {{ $t('components.search.cancel') }}
      </button>
    </div>
    <div class="wrapper_quicksearch">
      <template v-if="searchQuery.length && !error">
        <div class="divider"></div>
        <div class="d-flex flex-column quicksearch">
          <div
            class="quicksearch_item"
            @click="addDomainHandler({ event: $event, domain: searchQuery })"
          >
            <Domain :domain="searchQuery" v-if="searchQuery.includes('.')" />
            <span v-else> {{ searchQuery }}</span>
          </div>
        </div>
      </template>
      <template v-else-if="!searchQuery.length && !error">
        <div class="divider" v-if="dataOnActualDateLocal.length"></div>
        <div class="d-flex flex-column quicksearch">
          <template
            v-for="similarSite of dataOnActualDateLocal.slice(0, 5)"
            :key="similarSite.domainName"
          >
            <div
              @click="
                addDomainHandler({
                  event: $event,
                  domain: similarSite.domainName,
                })
              "
              v-if="!domains.includes(similarSite.domainName)"
              class="quicksearch_item"
            >
              <Domain :domain="similarSite.domainName" />
            </div>
          </template>
        </div>
      </template>
    </div>

    <SearchError v-if="error" :errorData="errorData" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SearchError from '@/components/errors/SearchError.vue'
import Favicon from '@/components/shared/Favicon.vue'
import getFavicon from '@/helpers/domains/getFavicon'
import Domain from '@/components/widgets/widgets-helpers/Domain.vue'
import CheckmarkIcon from '@/assets/icons/base/checkmark.svg'
import SearchIcon from '@/assets/icons/magnifier.svg'

export default {
  data() {
    return {
      searchQuery: '',
      quickSearch: true,
      loading: false,
      addedSuccess: false,
      error: false,
      errorData: {},
      CheckmarkIcon,
      SearchIcon,
    }
  },
  components: {
    SearchError,
    Favicon,
    Domain,
  },
  computed: {
    ...mapGetters(['domains']),
    ...mapGetters('similarSites', ['dataOnActualDate']),
    dataOnActualDateLocal() {
      return this.dataOnActualDate[0]?.data?.data || []
    },
  },
  mounted() {
    this.$refs.search.focus()
  },
  methods: {
    closeSearch() {},
    ...mapActions(['addDomain']),
    getFavicon,
    hideHintsIfEmptySearch() {
      this.addedSuccess = false
      if (!this.searchQuery.length) {
        this.error = false
        this.quickSearch = false
        return
      }

      this.quickSearch = true
    },
    async addDomainHandler(data) {
      if (data.event?.keyCode === 27) {
        return this.$emit('hide', true)
      }
      this.$refs.search.focus()
      this.error = false

      if (
        (data.event.type === 'click' && data.domain) ||
        (data.event.type === 'keyup' &&
          data.event.keyCode === 13 &&
          data.domain)
      ) {
        if (this.loading) return

        this.loading = true

        const result = await this.addDomain({
          domains: [data.domain],
          from: 'search',
        })
        this.loading = false

        if (result && result.error) {
          this.errorData = { name: result.error, from: 'search' }
          this.error = true
          return
        }

        this.searchQuery = ''
        this.addedSuccess = true
      }
    },
  },
}
</script>

<style scoped>
.wrapper_search_main {
  position: fixed;
  z-index: 100;
  background: white;
  padding: 12px;
  border-radius: 7px;
  top: 12px;
  margin-left: 15px;
  margin-right: 15px;
  left: 0;
  right: 0;
  text-align: center;
}

@media (min-width: 768px) {
  .wrapper_search_main {
    position: absolute;
    margin: 0;
    left: auto;
    top: 4px;
    right: 110px;
    width: 316px;
  }
}

.divider {
  height: 1px;
  background: #eaebed;
  margin: 12px 0;
}
.cancel {
  color: #2684fe;
  background: white;
  font-size: 14px;
  font-weight: 600;
  padding: 0px 0 0px 12px;
}
.quicksearch {
  font-size: 14px;
  color: #151515;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}
.quicksearch_item {
  padding: 10px 0px 10px 12px;
}
.quicksearch_item:hover,
.quicksearch_item:hover .search_icon2 {
  background: #f9f9fc;
  border-radius: 7px;
}

.quicksearch .search_icon2 {
  fill: #151515;
  width: 12px;
  margin-right: 6px;
}

.wrapper_search {
  align-items: center;
}

.wrapper_icons {
  margin-right: -28px;
  padding: 0 0 0 11px;
  z-index: 2;
}

.icon_size {
  width: 16px;
  height: 16px;
}

.search_icon {
  margin-bottom: 1px;
  fill: #e4eaf1;
}

.search_icon2 {
  fill: #151515;
}

.success {
  fill: #34a853;
}

input[type='search'] {
  background: #f1f3f8;
  padding: 7px 7px 7px 35px;
  border-radius: 6px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  border-radius: 50em;
  background: url('/clear.svg') no-repeat 50% 50%;
  background-size: contain;
  cursor: pointer;
}

input[type='search']:focus::-webkit-search-cancel-button {
  opacity: 1;
  pointer-events: all;
}

input[type='search'].dark::-webkit-search-cancel-button {
  filter: invert(1);
}

.loader {
  border: 2px solid #eaebed; /* Light grey */
  border-top: 2px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
