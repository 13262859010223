export const adNetworksList = {
  'google display network': 'ads.google.com',
  mgid: 'mgid.com',
  'yandex direct': 'direct.yandex.com',
  clickadu: 'clickadu.com',
  revenuehits: 'revenuehits.com',
  admitad: 'admitad.com',
  adcash: 'adcash.com',
  'direct/advert': 'directadvert.ru',
  'adfox.ru': 'adfox.ru',
  epn: 'epn.bz',
  criteo: 'criteo.com',
  voluum: 'voluum.com',
  'rtb house': 'rtbhouse.com',
  adriver: 'adriver.ru',
  skimlinks: 'skimlinks.com',
  awin: 'awin.com',
  admarketplace: 'admarketplace.com',
  innovid: 'innovid.com',
  'amazon ad system': 'advertising.amazon.com',
  triplelift: 'triplelift.com',
  impact: 'impact.com',
  'cj affiliate': 'cj.com',
  'yahoo advertising': 'adtech.yahooinc.com',
  'yahoo display ads': 'adtech.yahooinc.com',
  appnexus: 'xandr.com',
  'media.net': 'media.net',
  taboola: 'taboola.com',
  outbrain: 'outbrain.com',
  popcash: 'popcash.net',
  'propeller ads media': 'propellerads.com',
  coinzilla: 'coinzilla.com',
  'adf.ly': 'adf.ly',
  'adfoc.us': 'adfoc.us',
  'sh.st': 'sh.st',
  'juicy ads': 'juicyads.com',
  'the trade desk': 'thetradedesk.com',
  exoclick: 'www.exoclick.com',
  shareasale: 'shareasale.com',
  'hstp network': 'www.hstpnetwork.com',
  tradedoubler: 'tradedoubler.com',
  clickbank: 'clickbank.com',
}
