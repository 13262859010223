<template>
  <div class="overlay" :class="[type]" @click="hide()">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['type'],
  computed: {},
  methods: {
    hide() {
      this.$emit('hide', false)
    },
  },
}
</script>

<style>
.overlay {
  position: fixed;
  width: 100%;
  height: 2000px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.overlay.transparent {
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
}
</style>
