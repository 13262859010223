<template>
  <div class="d-flex">
    <Search
      v-if="addDomainModalState.isShow"
      @hide="$store.dispatch('addDomainModal/hide')"
    />
    <Overlay
      v-if="addDomainModalState.isShow"
      @hide="$store.dispatch('addDomainModal/hide')"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import Search from './Search.vue'
import Overlay from './Overlay.vue'

export default {
  data() {
    return {}
  },
  components: {
    Search,
    Overlay,
  },
  computed: {
    ...mapState('addDomainModal', {
      addDomainModalState: state => state,
    }),
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped></style>
