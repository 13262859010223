<template>
  <div class="share_info">
    <div class="mb-1">
      <PrimeText
        class="gradient_text"
        :text="$t(`components.share.title`)"
      />
    </div>
    <div class="mb-3">
      {{ $t(`components.share.description`) }}
    </div>
    <div>
      <LinkBlock />
    </div>
  </div>
</template>

<script>
import PrimeText from '@/components/shared/PrimeText.vue'
import LinkBlock from '@/components/shared/LinkBlock.vue'

export default {
  components: {
    PrimeText,
    LinkBlock,
  },
}
</script>

<style scoped>
.gradient_text {
  font-size: 19px;
}
.share_info {
  border: 1px solid #eaeef8;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(200, 210, 236, 0.2);
  border-radius: 7px;
  padding: 20px;
}
</style>
