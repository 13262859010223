<template>
  <div class="wrapper_local d-flex g-0">
    <div class="col-lg">
      <TrafficBlock class="visits" :domain="domain" :visits="visits" />
      <DescriptionItem
        class="description_item"
        iconLink="/emoji_hmm.png"
        :title="this.$t('main.traffic.smallSite.title1')"
        :description="this.$t('main.traffic.smallSite.description1')"
      />
      <DescriptionItem
        class="description_item"
        iconLink="/emoji_face_with_monocle.png"
        :title="this.$t('main.traffic.smallSite.title2')"
        :description="this.$t('main.traffic.smallSite.description2')"
      />
    </div>
    <div class="col">
      <img :src="TechnicalGirl" class="d-none d-lg-flex technical_girl w-100" />
    </div>
  </div>
</template>

<script>
import TechnicalGirl from '@/assets/illustrations/TechnicalGirl.svg'
import TrafficBlock from './TrafficBlock.vue'
import DescriptionItem from './DescriptionItem.vue'

export default {
  data() {
    return {
      TechnicalGirl,
    }
  },
  props: ['domain', 'visits'],
  computed: {},
  components: {
    TrafficBlock,
    DescriptionItem,
  },
  methods: {},
}
</script>

<style scoped>
.description_item {
  margin-top: 50px;
}
.technical_girl {
  margin-top: 120px;
}
.visits {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .description_item {
    margin-top: 30px;
  }
}
</style>
