import nFormatter from '@/helpers/processing/nFormatter'

export const calcSumChannelTraffic = (data, traffic, channelName) => {
  let channelSumTraffic = 0

  data.forEach(item => {
    const channelValue = item?.data?.[0]?.data?.[channelName] || 0
    const trafficValue = getTrafficValue(
      traffic,
      item.domainName,
      item.data?.[0]?.date
    )
    const channelTraffic = (channelValue / 100) * trafficValue
    channelSumTraffic += channelTraffic
  })
  return channelSumTraffic
}

export const getChannelData = (
  data,
  traffic,
  channelName,
  channelSumTraffic
) => {
  return data.map((item, index) => {
    const channelValue = item.data?.[0]?.data[channelName]
    const trafficValue = getTrafficValue(
      traffic,
      item.domainName,
      item.data?.[0]?.date
    )
    return getChannelValue(channelValue, trafficValue, channelSumTraffic)
  })
}

export const getChannelValue = (
  channelValue,
  trafficValue,
  channelSumTraffic
) => {
  const absolute_value =
    channelValue || channelValue === 0
      ? Math.round(trafficValue * (channelValue / 100))
      : null
  const absolute_percent =
    channelValue || channelValue === 0
      ? Math.round((absolute_value / channelSumTraffic) * 100)
      : null
  return {
    absolute: absolute_value,
    absolute_percent,
    percent: channelValue,
  }
}

export const getTrafficValue = (traffic, domainName, date) => {
  const trafficFiltered = traffic.data.filter(
    trafficItem => trafficItem.domainName === domainName
  )
  const trafficValue = trafficFiltered[0]?.data[date]
  return trafficValue
}
