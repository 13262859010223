<template>
  <div
    class="nav-item"
    @click="$emit('changeActive', item.id)"
    v-if="item"
    :class="{ active: isActive }"
  >
    <div class="open-mobile">
      {{ getMons() }} {{ $t('pricePage.month') }}
      <strong>{{ item.price }} ₽</strong>
      <span class="text-muted" :class="{ 'text-transparent': getMons() < 1 }">
        {{ getMonsPayment() }} ₽ / {{ $t('pricePage.month') }}
      </span>
    </div>
    <div class="hide-mobile">
      {{ TITLE_TEXT[item.duration] }}
      <span v-if="percentSale > 0" class="">
        /
        <span v-if="isActive" class="active_text">{{ getSaleText }}</span>
        <PrimeText v-else :text="getSaleText" />
      </span>
    </div>
  </div>
</template>

<script>
import PrimeText from '@/components/shared/PrimeText.vue'

export default {
  components: {
    PrimeText,
  },
  computed: {
    getSaleText() {
      return `${this.$t('pricePage.sale')} ${this.percentSale}%`
    },
  },
  props: {
    item: {
      type: Object,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TITLE_TEXT: {
        30: this.$t('pricePage.duration.30'),
        90: this.$t('pricePage.duration.90'),
        365: this.$t('pricePage.duration.365'),
      },
      percentSale: 0,
    }
  },
  methods: {
    getSalePercent() {
      const mons = this.getMons()
      if (this.item.duration === 90) return 33
      if (this.item.duration === 365) return 60
      // return 100 - Math.round(this.item.price / ((1990 * mons) / 100))
    },
    getMons() {
      return Math.trunc(this.item.duration / 30)
    },
    getMonsPayment() {
      return Math.trunc(this.item.price / this.getMons())
    },
  },
  mounted() {
    this.percentSale = this.getSalePercent()
  },
}
</script>

<style scoped>
.nav-item {
  /* padding: 9px 55px; */
  padding: 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #5c5f6c;
  transition: all 0.2s linear;
  white-space: nowrap;
  width: 33.33%;
  text-align: center;
}

.nav-item.active {
  background: var(--button-primary);
  color: #ffffff;
  box-shadow: 0px 4px 20px rgba(232, 232, 232, 0.2);
  border-radius: 7px;
}
.nav-item :deep(.text-pink) {
  color: #ff72b2;
  font-weight: 700;
}
.nav-item.active :deep(.text-pink) {
  color: #ffffff;
}
.nav-item :deep(.open-mobile) {
  display: unset;
}
.nav-item :deep(.open-mobile) {
  display: none;
}
.active_text {
  font-weight: 700;
}
@media (max-width: 785px) {
  .nav-item :deep(.open-mobile) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .nav-item :deep(.hide-mobile) {
    display: none !important;
  }
  .nav-item {
    position: relative;
    width: 100%;
    margin: 5px;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #0e0f10;
    transition: none;
    padding: 7px 55px;
  }
  .nav-item strong {
    font-weight: bold;
    font-size: 19px;
    line-height: 26px;
    color: #0e0f10;
  }
  .nav-item:before {
    content: '';
    width: 20px;
    height: 20px;
    background: #ffffff;
    border: 1px solid #dde0e8;
    position: absolute;
    border-radius: 50%;
    left: 5px;
  }
  .nav-item.active {
    background: #f6f6f8;
    box-shadow: 0px 4px 20px rgba(232, 232, 232, 0.2);
    color: #0e0f10;
    border-radius: 7px;
  }
  .nav-item.active:before {
    border: none;
    background: var(--main-color) url('../../assets/icons/checked_arrow.png')
      no-repeat center;
  }
}
</style>
