import { LineController } from 'chart.js'

export class LineCustom extends LineController {
  draw() {
    // Call bubble controller method to draw all the points
    super.draw(arguments)
    // Now we can do some custom drawing for this dataset. Here we'll draw a red box around the first point in each dataset
    const drawDashedLine = ({ x, bottomY, topY }) => {
      const { ctx } = this.chart

      ctx.save()
      ctx.beginPath()
      ctx.setLineDash([11, 14])
      ctx.moveTo(x, bottomY)
      ctx.lineTo(x, topY)
      ctx.lineWidth = 1
      ctx.strokeStyle = '#C3D9F6'
      ctx.stroke()
      ctx.restore()
    }

    if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
      const activePoint = this.chart.tooltip._active[0]
      const { x } = activePoint.element
      const topY = this.chart.legend.bottom
      const bottomY = this.chart.chartArea.bottom
      drawDashedLine({ x, bottomY, topY })
    }
  }
}
LineCustom.id = 'LineWithLine'
LineCustom.defaults = LineController.defaults
