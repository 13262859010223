<template>
  <div class="d-flex" :class="[type]">
    <input
      @click="updateDataset(legend.index)"
      type="checkbox"
      :id="getLabelId(legend)"
      :name="getLabelId(legend)"
      checked
      class="d-flex"
    />
    <label
      class="d-flex"
      :for="getLabelId(legend)"
      :style="
        'border-color:' + legend.color + ';' + 'background: ' + legend.color
      "
      ><span>{{ legend.label }}</span></label
    >
    <a v-if="link" class="link" target="_blank" :href="'//' + legend.label">
      <img :src="LinkOnSiteIcon" />
    </a>
    <button
      v-if="removeButton"
      class="clear_button"
      @click="removeDomain(legend.label)"
    >
      <img :src="ClearIcon" />
    </button>
  </div>
</template>

<script>
import LinkOnSiteIcon from '@/assets/icons/link-on-site.svg'
import ClearIcon from '@/assets/icons/clear.svg'

export default {
  data() {
    return {
      LinkOnSiteIcon,
      ClearIcon,
    }
  },
  props: {
    legend: Object,
    type: {
      type: String,
      default: 'checkbox',
    },
    link: {
      type: Boolean,
      default: true,
    },
    removeButton: Boolean,
    chartName: String,
  },
  components: {},
  methods: {
    updateDataset(index) {
      this.$emit('updateDataset', index)
    },
    removeDomain(domain) {
      this.$emit('removeDomain', domain)
    },
    getLabelId(legend) {
      return legend.label + this.chartName
    },
  },
}
</script>

<style scoped>
.checkbox_button {
  background: #f8f9fe;
  border-radius: 7px;
  padding: 6px 15px 5px;
}

input {
  margin-right: 5px;
  cursor: pointer;
}
label {
  margin: 2px;
  font-size: 14px;
  color: #0e0f10;
  cursor: pointer;
}
label span {
  background: white;
  padding-left: 2em;
}

.checkbox_button label span {
  background: #f8f9fe;
}

.domain label span {
  padding-left: 10px;
}
.link {
  display: block;
  margin-top: -6px;
}
.clear_button {
  background: none;
  margin: 0px 0px 0 4px;
  padding: 0;
}

:v-deep(.clear_button svg) {
  margin-bottom: 1px;
}

[type='checkbox']:not(:checked),
[type='checkbox']:checked {
  opacity: 0;
  width: 0;
  height: 0;
}
[type='checkbox']:not(:checked) + label,
[type='checkbox']:checked + label {
  position: relative;
  cursor: pointer;
}

/* checkbox aspect */
[type='checkbox']:not(:checked) + label:before,
[type='checkbox']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-color: inherit;
  background-color: inherit;
  border-radius: 5px;
  -webkit-transition: all 0.275s;
  transition: all 0.275s;
}

[type='checkbox']:not(:checked) + label:before {
  background-color: white !important;
}

/* checked mark aspect */
[type='checkbox']:not(:checked) + label:after,
[type='checkbox']:checked + label:after {
  content: url('/checkmark.svg');
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 1.2em;
  color: white;
  line-height: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

/* checked mark aspect changes */
[type='checkbox']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0) rotate(45deg);
  transform: scale(0) rotate(45deg);
}

[type='checkbox']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1) rotate(0);
  transform: scale(1) rotate(0);
}

/* Disabled checkbox */
[type='checkbox']:disabled:not(:checked) + label:before,
[type='checkbox']:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #e9e9e9;
}

[type='checkbox']:disabled:checked + label:after {
  color: #777;
}

[type='checkbox']:disabled + label {
  color: #aaa;
}
</style>
