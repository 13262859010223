import requestHelper from '@/helpers/store/requestHelper'
import { saveRightOrderDomains } from '@/helpers/store/saveRightOrderDomains'

export const uniqueVisitors = {
  namespaced: true,
  actions: {
    async load(ctx) {
      await requestHelper(
        {
          url: '/data/getUniqueUsers',
          viewId: ctx.rootState.view.viewId,
        },
        ctx
      )
    },
  },
  state: () => ({
    data: [],
    loading: true,
    platforms: 'all',
    error: { status: false },
  }),
  mutations: {
    updateData(state, data) {
      const adaptedData = data.map(item => {
        return {
          domainName: item.domainName,
          data: item.data,
        }
      })
      state.data = adaptedData
    },
    updateLoading(state, status) {
      state.loading = status
    },
    updateError(state, data) {
      state.error = data
    },
  },
  getters: {
    dataOnActualDate(state, getters, rootState, rootGetters) {
      const { domains } = rootGetters
      return saveRightOrderDomains(state.data, domains)
    },
  },
}
