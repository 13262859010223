import getFormattedDate from '@/helpers/date/getFormattedDate'
import getColor from '@/helpers/store/getColor'
import requestHelper from '@/helpers/store/requestHelper'

export const traffic = {
  namespaced: true,
  actions: {
    async load(ctx) {
      const { view } = ctx.rootState
      if (!ctx.state.activePeriod) {
        if (view.creatorSubscriptionActive || view.subscriptionActive)
          ctx.commit('updateActivePeriod', '3years')
        else {
          ctx.commit('updateActivePeriod', '1year')
        }
      }
      await requestHelper(
        {
          url: '/data/getVisits',
          viewId: ctx.rootState.view.viewId,
        },
        ctx
      )
    },
  },
  state: {
    data: [],
    activePeriod: null,
    platforms: 'all',
    loading: true,
    error: { status: false },
    periods: [
      // { value: '6months', months: 6 },
      { value: '1year', months: 12 },
      { value: '3years', months: 36 },
      { value: 'all', months: 999 },
    ],
  },
  mutations: {
    updateData(state, data) {
      const result = data.map(dataItem => {
        return {
          domainName: dataItem.domainName,
          data: dataItem.data,
          isPredicted: dataItem.isPredicted,
        }
      })
      state.data = result
    },
    updateLoading(state, status) {
      state.loading = status
    },
    updateError(state, visits) {
      state.visitsError = visits
    },
    updateActivePeriod(state, data) {
      state.activePeriod = data
    },
  },
  getters: {
    dataset(state, getters, rootState, rootGetters) {
      // only for labels
      const dataOnActualDate = state.data.filter(
        item => Object.keys(item.data).length
      )
      const labels = Object.keys(dataOnActualDate[0].data)

      const { domains } = rootGetters
      const datasets = domains.map((domainName, index) => {
        const data = state.data.filter(
          dataItem => domainName === dataItem.domainName
        )
        return {
          label: data[0]?.domainName,
          pointBackgroundColor: 'rgb(0,0,0,0)',
          borderColor: getColor(index),
          backgroundColor: 'rgba(0,0,0,0)',
          data: Object.values(data[0]?.data || {}),
          isPredicted: data[0]?.isPredicted,
        }
      })
      return {
        labels,
        datasets,
      }
    },
  },
}
