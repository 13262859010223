<template>
  <div class="d-flex flex-column">
    <div class="row legends justify-content-md-center">
      <slot name="legend" updateDataset="updateDataset"></slot>
    </div>
    <div class="wrapper_line_chart">
      <canvas
        hide-selected="true"
        tabindex="1"
        class="chart"
        id="LineWithLine"
        ref="canvas"
      ></canvas>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import { shallowRef } from 'vue'

import { mapGetters, mapActions } from 'vuex'
import nFormatter from '@/helpers/processing/nFormatter'
import getColor from '@/helpers/store/getColor'
import isMobile from '@/helpers/base/isMobile'
import getFormattedDate from '@/helpers/date/getFormattedDate'
import { LineCustom } from './LineCustom'
import buildTooltip from './buildTooltip'

Chart.register(LineCustom)
Chart.defaults.font = {
  family: 'Manrope',
  size: 12,
  style: 'normal',
}
Chart.defaults.scale.ticks.color = '#AAB2BA'

export default {
  data() {
    const self = this
    return {
      removeLoading: false,
      chartLegend: [],
      chartOptions: {
        layout: {
          padding: 0,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: 'index',
            cornerRadius: 7,
            intersect: false,
            enabled: false,
            external(context) {
              buildTooltip.bind(this, context, self.$t, self.$tc)()
            },
            bodySpacing: 7,
            padding: 20,
            position: 'nearest',
            borderColor: '#d9e0f2',
            borderWidth: 1,
            backgroundColor: 'white',
          },
        },
        events: ['mousemove', 'mouseout', 'click'],
        aspectRatio: this.isMobile() ? 1.25 : 2.2,
        animation: false,
        scales: {
          x: {
            ticks: {
              padding: 0,
              tickLength: 0,
              callback: (value, index, values) => {
                return getFormattedDate(this.chartData.labels[value], {
                  month: 'short',
                })
              },
            },
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              padding: 15,
              callback: (value, index, values) => {
                return this.nFormatter(value)
              },
            },
            grid: {
              borderDash: [7, 7],
              tickLength: 0,
              color: '#E8EBF3',
            },
          },
        },
        elements: {
          point: {
            radius:
              this.isMobile() || this.options?.activePeriod === 'all' ? 0 : 2,
            borderWidth: 5,
          },
          line: {
            tension: 0.3,
            borderWidth: 3,
          },
        },
      },
    }
  },
  props: ['chartData', 'options'],
  components: {},
  mounted() {
    const config = {
      type: 'LineWithLine',
      data: this.chartData,
      options: this.chartOptions,
    }
    this.chart = shallowRef(new Chart(this.$refs.canvas, config))
    // shallowRef()

    this.$on('updateDataset', index => {
      this.updateDataset(index)
    })
  },
  methods: {
    ...mapActions(['removeDomain']),
    nFormatter,
    getColor,
    buildTooltip,
    isMobile,
    updateDataset(index) {
      const ci = this.chart.value.legend.chart
      if (ci.isDatasetVisible(index)) {
        ci.hide(index)
      } else {
        ci.show(index)
      }
    },
  },
}
</script>
<style scoped>
.legends {
  margin: 0 0 10px 0;
  padding: 0px 15px;
}
#LineWithLine:focus {
  outline: none;
}

.wrapper_input {
  align-items: center;
  margin-right: 20px;
  margin-bottom: 15px;
}
.wrapper_input:last-child {
  margin-right: 0px;
}

.wrapper_line_chart {
  position: relative;
  max-height: 548px;
}

canvas {
  position: relative;
  z-index: 3;
}
</style>
