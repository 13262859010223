<template>
  <div>
    <AuthInstruction>
      <template v-slot:picture>
        <img :src="DiscussionIllustration" />
      </template>
      <template v-slot:points>
        <div class="text-muted">
          {{ $t('modals.auth.telegramInstructionStep1') }}
        </div>
        <div class="text-muted">
          {{ $t('modals.auth.telegramInstructionStep2') }}
        </div>
      </template>
    </AuthInstruction>
    <vue-telegram-login
      mode="redirect"
      @click="openOfficalTelegram()"
      :telegram-login="TELEGRAM_BOT_NAME"
      :redirect-url="REDIRECT_URL + '/telegram'"
    />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import { vueTelegramLogin } from 'vue-telegram-login'
import { makeEvent } from '@/helpers/base/amplitude'
import { TELEGRAM_BOT_NAME, REDIRECT_URL } from '@/helpers/variables'
import DiscussionIllustration from '@/assets/illustrations/Discussion.svg'
import AuthInstruction from '@/components/auth/AuthInstruction.vue'

export default {
  data() {
    return {
      DiscussionIllustration,
      TELEGRAM_BOT_NAME,
      REDIRECT_URL,
    }
  },
  components: {
    AuthInstruction,
    vueTelegramLogin,
  },
  computed: {
    ...mapState('authModule', {
      authModule: state => state,
    }),
    ...mapGetters('userInfo', ['userInfo']),
    ...mapState(['view']),
  },
  methods: {
    ...mapMutations('authModule', ['setStep', 'close']),
    openOfficalTelegram() {
      makeEvent('auth popup', {
        button: 'offical_sign_in_telegram',
      })
    },
  },
}
</script>

<style scoped>
.btn_link {
  color: #2684fe;
}
.auth_main_item :v-deep(path) {
  fill: #2684fe;
}
.modal__inner {
  display: flex;
  flex-wrap: wrap;
  width: 1160px;
  background: #ffffff;
  border-radius: 7px;
  max-width: 100vw;
}
.modal__inner .modal_side {
  width: 50%;
  padding: 60px 70px 80px 60px;
  background: #ffffff;
}
.modal__inner .modal_side:last-child {
  background: #f7f7f7;
}
.modal__inner .modal_title_text {
  font-weight: bold;
  font-size: 36px;
  line-height: 49px;
  color: #0e0f10;
  margin-bottom: 10px;
}

.modal__inner .modal_description_text {
  color: #2684fe;
  font-weight: 600;
  font-size: 15px;
}
.auth_main_items {
  padding-top: 10px;
}
.auth_main_item {
  display: flex;
  margin-top: 30px;
}
.auth_main_item_text {
  margin-left: 18px;
}
.auth_main_item .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #0e0f10;
  margin-bottom: 5px;
}
.auth_main_item .desc {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #5c5f6c;
}
.right_side .description {
  margin-bottom: 50px;
}
.tg_icon {
  margin-right: 20px;
  width: 14px;
  height: 14px;
}
.web_tg {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #808493;
}
.web_tg span {
  cursor: pointer;
}
.big_checkbox_arrow_icon {
  margin-top: 3px;
}
.modal_side.right_side svg {
  width: 284px;
  height: 225px;
}
@media (max-width: 1024px) {
  .modal__inner {
    width: auto;
  }
  .modal__inner .modal_side {
    width: 100%;
    padding: 20px;
  }
  .modal__inner .modal_title_text {
    font-weight: bold;
    font-size: 22px;
    line-height: normal;
    text-align: center;
    margin-top: 30px;
  }
  .modal__inner .modal_side:not(.right_side) {
    padding-bottom: 50px;
  }
  .auth_main_items {
    max-width: 550px;
    margin: 0 auto;
  }
  .text_blue {
    font-size: 15px;
    text-align: center;
    display: block;
  }
  .auth_main_items {
    padding-top: 0;
  }
  .auth_main_item {
    margin-top: 15px;
  }
  .auth_main_item .title {
    font-size: 16px;
    line-height: normal;
  }
  .auth_main_item .desc {
    font-size: 15px;
    line-height: normal;
  }
  .modal_side.right_side {
    order: -1;
  }
  .modal_side.right_side svg {
    width: 250px;
  }
  .right_side .description {
    margin-bottom: 25px;
  }
}
</style>
