export function dateParser(_date) {
  if (!(this instanceof dateParser)) {
		return new dateParser(_date) //eslint-disable-line
  }

  const date = new Proxy(new Date(_date), {
    get(target, prop) {
      return typeof target[prop] === 'function'
        ? target[prop].bind(target)
        : target[prop]
    },
  })
  this.date = date

  return this
}

dateParser.prototype = {
  format(_format) {
    const {
      getDate,
      getMonth,
      getFullYear,
      getHours,
      getMinutes,
      getSeconds,
    } = this.date
    const day = getDate()
    const month = getMonth() + 1
    const year = getFullYear()
    const hours = getHours()
    const minutes = getMinutes()
    const seconds = getSeconds()

    const monthNames = [
      'Янв.',
      'Фев.',
      'Март',
      'Апр.',
      'Май',
      'Июнь',
      'Июль.',
      'Авг.',
      'Сен.',
      'Окт.',
      'Ноябрь',
      'Дек.',
    ]

    return _format
      .replace('month', monthNames[getMonth()])
      .replace('DD', day > 9 ? day : `0${day}`)
      .replace('MM', month > 9 ? month : `0${month}`)
      .replace('YYYY', year)
      .replace('YY', year.toString().slice(-2))
      .replace('hh', hours > 9 ? hours : `0${hours}`)
      .replace('mm', minutes > 9 ? minutes : `0${minutes}`)
      .replace('ss', seconds > 9 ? seconds : `0${seconds}`)
  },
  fromNow() {
    let sec = (this.date.getTime() - Date.now()) / 1000
    const isPassed = !(sec > 0)

    if (isPassed) sec = -sec

    const d = Math.floor(sec / 60 / 60 / 24)
    const m = Math.floor(sec / 60)
    const h = Math.floor(sec / 60 / 60)

    if (d) return `${d} d${isPassed ? ' ago' : ''}`
    if (h) return `${h} h${isPassed ? ' ago' : ''}`
    if (m) return `${m} m${isPassed ? ' ago' : ''}`

    return ''
  },
}
