<template>
  <ul class="dropdown-menu show" role="menu" aria-labelledby="dropdownMenu">
    <li>
      <a class="dropdown-item" target="_blank" :href="'//' + domain">
        {{ $t('buttons.contextMobile.goToSite') }}
      </a>
    </li>
    <li
      v-if="!domains.includes(domain)"
      @click="addDomainHandler(domain)"
      class="dropdown-item"
    >
      {{ $t('buttons.contextMobile.toCompare') }}
    </li>
    <ToastError v-if="addDomainError" :errorData="errorData" />
  </ul>
</template>

<script>
/** @TODO */
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ToastError from '@/components/errors/ToastError.vue'

export default {
  data() {
    return {
      addDomainError: false,
    }
  },
  components: {
    ToastError,
  },
  props: ['domain'],
  computed: {
    ...mapGetters(['domains']),
  },
  methods: {
    ...mapMutations('authModule', ['setStep', 'setFrom']),
    ...mapActions(['addDomain']),
    async addDomainHandler(similarSite) {
      if (this.addDomainLoading) return
      this.addDomainError = false

      const result = await this.addDomain({
        domains: [similarSite],
        from: 'similar_sites',
      })
      if (result && result.error) {
        if (result.error === 'addMoreSites') {
          return this.openLogin()
        }
        this.errorData = {
          name: result.error,
          from: 'similar_sites',
        }
        this.addDomainError = true
      }
    },
    openLogin() {
      this.setFrom('header_login_button')
      this.setStep(1)
    },
  },
}
</script>

<style scoped></style>
