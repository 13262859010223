import { filterOnActualDate } from '@/helpers/store/filterOnActualDate'
import { setActualDate } from '@/helpers/store/setActualDate'
import { filterBySelectedDomain } from '@/helpers/store/filterBySelectedDomain'

export const mobileApps = {
  namespaced: true,
  actions: {
    set(ctx, data) {
      ctx.commit('updateData', data)
      ctx.commit('updateActualDate')
    },
  },
  state: {
    data: [],
    actualDate: null,
  },
  mutations: {
    updateData(state, data) {
      const result = data.map(dataItem => {
        return {
          domainName: dataItem.domainName,
          data: dataItem.mobileApps,
        }
      })
      state.data = result
    },
    updateActualDate(state, value) {
      state.actualDate = setActualDate(state.data, value)
    },
  },
  getters: {
    dataOnActualDate(state, getters, rootState, rootGetters) {
      const { selectedDomain } = rootGetters
      const data = filterOnActualDate(state.data, state.actualDate)
      return filterBySelectedDomain(data, selectedDomain)
    },
    mainDomainData(state, getters) {
      return getters.dataOnActualDate?.[0]?.data?.[0]?.data
    },
  },
}
