import { api } from '@/helpers/base/api'
import axios from 'axios'

const { CancelToken } = axios
const cancelSources = {}

export default async function requestHelper(
  params,
  ctx,
  updatedDataName = 'updateData'
) {
  try {
    ctx.commit('updateLoading', true)
    ctx.commit('updateError', { status: false })

    /** Cancel previous request for actual state information */
    if (cancelSources[params.url])
      cancelSources[params.url].cancel('double_request')
    cancelSources[params.url] = CancelToken.source()

    const result = await api.get(
      params.url,
      {
        domains: params?.domains,
        id: params.viewId,
      },
      {
        timeout: params.timeout || 30000,
        cancelToken: cancelSources[params.url].token,
      }
    )

    if (!result.success) throw new Error('cannotLoad')
    if (!result.success || !result.data.length)
      throw new Error('notFoundByRequest')

    if (updatedDataName) ctx.commit(updatedDataName, result.data)
    ctx.commit('updateLoading', false)
    return result
  } catch (error) {
    if (error.message === 'double_request') return
    if (error.message.indexOf('timeout') > 0) return
    console.error(error)
    ctx.commit('updateLoading', false)
    ctx.commit('updateError', {
      status: true,
      name: error.message,
    })
  }
}
