<template>
  <div class="n_wrapper">
    <div class="d-flex justify-content-between mb-1">
      <div class="title">{{ title }}</div>
      <div class="date">{{ date }}</div>
    </div>
    <div class="description mb-1">
      {{ description }}
    </div>
    <div class="text-end">
      <a v-if="link" :href="link" target="_blank" class="link">{{
        $t(`components.notifications.buttonMore`)
      }}</a>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['title', 'description', 'date', 'link'],
  computed: {},
  methods: {},
}
</script>

<style scoped>
.n_wrapper {
  background: #f6f7fa;
  border-radius: 7px;
  padding: 20px;
}
.title {
  font-weight: bold;
  font-size: 15px;
  max-width: 310px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.date {
  color: #aab2ba;
}
</style>
