<template>
  <div class="wrapper padding_spaces w-100 d-flex flex-column">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Modal',
  components: {},
  props: {},
  methods: {},
}
</script>

<style scoped>
.wrapper {
  background: white;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(200, 210, 236, 0.2);
  border-radius: 7px;
  margin-bottom: 20px;
}
.padding_spaces {
  padding: 20px 15px;
}

@media (min-width: 360px) {
  .padding_spaces {
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .padding_spaces {
    padding: 20px;
  }
}
@media (min-width: 992px) {
  .padding_spaces {
    padding: 30px;
  }
}
</style>
