<template>
  <div class="d-flex align-items-center">
    <div class="dropdown" :class="{ active: isSubscribeActive || isTrial }">
      <button
        class="dropdown-toggle drop_down"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <!-- {{ isMobile() ? '' : getSubsctiptionText('short') }} -->
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <!-- <div class="dropdown-header">
          <div class="title">
            <PrimeText text="Metrica +" />
          </div>
          <div class="description">
            {{ getSubsctiptionText() }}
          </div>
        </div> -->
        <!-- <li>
          <router-link class="dropdown-item" to="/account">{{
            $t('header.account.subscriptionManagement')
          }}</router-link>
        </li> -->
        <li>
          <a class="dropdown-item" :href="TELEGRAM_SUPPORT" target="_blank">{{
            $t('header.account.support')
          }}</a>
        </li>
        <li>
          <div class="dropdown-item" @click="logout()">
            {{ $t('header.account.logout') }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { TELEGRAM_SUPPORT, TELEGRAM_URL } from '@/helpers/variables'
import isMobile from '@/helpers/base/isMobile'
import PrimeText from '@/components/shared/PrimeText.vue'

export default {
  data() {
    return {
      TELEGRAM_SUPPORT,
      TELEGRAM_URL,
      tooltip: {
        isOpen: false,
        info: {},
      },
    }
  },
  components: { PrimeText },
  computed: {
    ...mapState('subscription', ['isLoading']),
    ...mapGetters('userInfo', ['isLoggedIn']),
    ...mapGetters('subscription', [
      'isSubscribeActive',
      'isTrial',
      'trialPeriod',
      'isTrialStarted',
    ]),
  },
  methods: {
    isMobile,
    getSubsctiptionText(mode) {
      const days = this.trialPeriod.days || '-'
      if (this.isTrial)
        return (
          this.$i18n.t('subscription.activeTrial') +
          this.$i18n.tc('helpers.date.days', days)
        )
      if (!this.isSubscribeActive && !this.isTrial)
        return this.$i18n.t('subscription.noSubscription')
      return mode === 'short'
        ? this.$i18n.t('subscription.plus')
        : this.$i18n.t('subscription.withSubscription')
    },
    closeTooltip(id) {
      this.tooltip.isOpen = false
    },
    openLogin() {
      this.setFrom('header_login_button')
      this.setStep(1)
    },
    ...mapMutations('authModule', ['setStep', 'setFrom']),
    ...mapMutations('userInfo', ['logout']),
  },
}
</script>
<style scoped>
.dropdown-toggle,
.dropdown-toggle:focus {
  border: none;
  outline: none;
  box-shadow: none;
  background: none;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
}

.dropdown-menu {
  border: 1px solid #eaeef8;
  box-sizing: border-box;
  padding: 12px 0;
  width: 229px;
  top: 10px;
  border-radius: 7px;
}

.dropdown-item {
  padding: 5px 12px;
  font-size: 14px;
}

.dropdown-item:hover {
  background: #f9f9fc;
  border-radius: 7px;
  text-decoration: none;
}

.dropdown-header {
  padding: 0 12px;
  border-bottom: 1px solid #eaeef8;
}

ul .title {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #aab2ba;
}

.dropdown.active button {
  font-weight: 900;
  font-size: 14px;
  background: linear-gradient(
    90deg,
    #ff70a3 0%,
    #fe6c74 18.65%,
    #b260ed 31.98%,
    #a162fa 8.65%,
    #70a8f7 99.99%,
    #70a7f7 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dropdown-item:active {
  color: #212529;
}

ul .description {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #aab2ba;
  margin-bottom: 7px;
}

.drop_down {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  max-width: 185px;
  white-space: pre-wrap;
  text-align: start;
  color: #aab2ba;
  padding: 0 2px 0 0;
}

/* .dropdown.active {
  background: linear-gradient(
    90deg,
    #ff70a3 0%,
    #fe6c74 18.65%,
    #b260ed 31.98%,
    #a162fa 8.65%,
    #70a8f7 99.99%,
    #70a7f7 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

.drop_down::after {
  margin-left: 20px;
  width: 38px;
  height: 38px;
  border: none;
}

/* .drop_down::after {
  content: url('/InactiveSub.svg');
}

.dropdown.active .drop_down::after {
  content: url('/ActiveSub.svg');
} */

.drop_down::after {
  content: url('/ActiveSub.svg');
}

/* :v-deep(.drop_down),
:v-deep(.drop_down:focus),
:v-deep(.dropdown-toggle),
:v-deep(.dropdown-toggle:focus) {
  border: none;
  outline: none;
  box-shadow: none;
}

:v-deep(.dropdown-toggle) {
  display: flex;
  align-items: center;
}

:v-deep(.dropdown-menu) {
  border: 1px solid #eaeef8;
  box-sizing: border-box;
  padding: 12px 0;
  width: 229px;
  top: 10px;
}

:v-deep(.dropdown-item) {
  padding: 5px 12px;
  font-size: 14px;
}

:v-deep(.dropdown-item:hover) {
  background: #f9f9fc;
  border-radius: 7px;
}

:v-deep(.dropdown-header) {
  padding: 0 12px;
}

:v-deep(.drop_down .dropdown-header > .title) {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #aab2ba;
}

:v-deep(.drop_down.active .dropdown-header > .title) {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  display: inline-block;
  background: linear-gradient(
    90deg,
    #ff70a3 0%,
    #fe6c74 28.65%,
    #b260ed 61.98%,
    #a162fa 78.65%,
    #70a8f7 99.99%,
    #70a7f7 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

:v-deep(.drop_down .dropdown-header > .description) {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #aab2ba;
  margin-bottom: 7px;
}

:v-deep(.drop_down button) {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  max-width: 185px;
  white-space: pre-wrap;
  text-align: start;
  color: #aab2ba;
  padding: 0 2px 0 0;
}

:v-deep(.drop_down.active button) {
  background: linear-gradient(
    90deg,
    #ff70a3 0%,
    #fe6c74 28.65%,
    #b260ed 61.98%,
    #a162fa 78.65%,
    #70a8f7 99.99%,
    #70a7f7 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

:v-deep(.drop_down button::after) {
  border: none;
  content: url('/InactiveSub.svg');
  margin-left: 20px;
  width: 38px;
  height: 38px;
}

:v-deep(.drop_down.active button::after) {
  content: url('/ActiveSub.svg');
} */
</style>
