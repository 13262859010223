<template>
  <WidgetWrapper>
    <WidgetTitle
      :title="$t('main.geo.title')"
      :description="$t('main.geo.description')"
      v-if="!loading"
    >
      <template v-slot:dropDown>
        <div class="d-flex">
          <Date
            :actualDate="geo.actualDate"
            :options="{ showFullDate: false, oldCalendar: true }"
          />
          <DropDown
            v-if="geo.actualDate"
            class="value_picker"
            type="origin"
            :title="getFormattedDate(geo.actualDate)"
          >
            <template v-slot:items>
              <!-- <DropDownItem
                v-for="date of getListDates(geo.data, {
                  subscriptionActive,
                })"
                v-show="date !== geo.actualDate"
                @selectItem="updateActualDate"
                :text="getFormattedDate(date)"
                :value="date"
                :key="date"
                :isLocked="!subscriptionActive"
              /> -->
            </template>
          </DropDown>
        </div>
      </template>
    </WidgetTitle>
    <div v-if="isData" class="content mt-custom" ref="content">
      <div class="d-flex row row-cols-1 row-cols-md-2 mb-30">
        <GeoBlock
          :picked="geo.geoFirstPickedSite"
          :mutation="updateGeoFirstPickedSite"
          :dataset="dataset"
          class="mt-2"
        />
        <GeoBlock
          v-if="Object.keys(geo.data).length > 1"
          :picked="geo.geoSecondPickedSite"
          :mutation="updateGeoSecondPickedSite"
          :dataset="dataset"
          class="mt-2"
        />
        <div
          v-else
          class="col d-md-flex wrapper_template_geo justify-content-center align-items-center"
        >
          <div class="none_second_geo text-center">
            {{ $t('main.geo.addAnotherSite') }} <br />
            {{ $t('main.geo.toCompare') }}
          </div>
        </div>
      </div>
      <!-- <ShowMoreButton
        class="mt-custom-2"
        type="countries"
        :totalCount="0"
        @clickCustom="onModalOpen()"
      /> -->
    </div>
    <Modal
      type="table"
      :closeHandler="close => (isModalOpen = false)"
      :isOpen="isModalOpen"
    >
      <template v-slot:title>
        {{ $t('main.geo.title') }}
      </template>
      <template v-slot:body> <TableGeo /> </template>
    </Modal>
    <WidgetError v-if="!isData && !loading" />
    <SkeletonLoading v-if="loading" name="geo" />
  </WidgetWrapper>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import WidgetTitle from '@/components/widgets/widgets-helpers/WidgetTitle.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import WidgetError from '@/components/errors/WidgetError.vue'
import ShowMoreButton from '@/components/buttons/ShowMoreButton.vue'
import WidgetWrapper from '@/components/widgets/widgets-helpers/WidgetWrapper.vue'
import DropDown from '@/components/shared/drop-down/DropDown.vue'
import DropDownItem from '@/components/shared/drop-down/DropDownItem.vue'
import getFormattedDate from '@/helpers/date/getFormattedDate'
import getListDates from '@/helpers/date/getListDates'
import TableGeo from '@/components/tables/TableGeo.vue'
import Modal from '@/components/modals/Modal.vue'
import Date from '@/components/shared/Date.vue'
import GeoBlock from './GeoBlock.vue'

export default {
  data() {
    return {
      isModalOpen: false,
    }
  },
  components: {
    WidgetTitle,
    SkeletonLoading,
    WidgetError,
    GeoBlock,
    ShowMoreButton,
    WidgetWrapper,
    DropDown,
    DropDownItem,
    TableGeo,
    Modal,
    Date,
  },
  computed: {
    ...mapGetters(['subscriptionActive']),
    ...mapGetters('geo', ['dataset', 'isData']),
    ...mapState('mainData', {
      mainData: state => state,
    }),
    ...mapState('geo', {
      geo: state => state,
    }),
    loading() {
      return this.mainData.loading
    },
  },
  methods: {
    getFormattedDate,
    getListDates,
    ...mapMutations('geo', ['updateGeoFirstPickedSite']),
    ...mapMutations('geo', ['updateGeoSecondPickedSite']),
    ...mapMutations('geo', ['updateActualDate']),
    onModalOpen() {
      if (!this.subscriptionActive)
        return this.$store.dispatch(
          'trialEndedPopupModule/getPlanAndOpen',
          'geo'
        )
      this.isModalOpen = true
    },
  },
}
</script>

<style scoped>
@media (min-width: 768px) {
  .wrapper_title {
    margin-bottom: 0;
  }
}
.wrapper_template_geo {
  display: none;
}
.content {
  height: 100%;
}
.template_geo {
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  height: 259px;
  padding: 5px 0 10px;
}
.mt-custom {
  margin-top: 17px;
}
.mt-custom-2 {
  margin-top: 26px;
}
.none_second_geo {
  color: #aab2ba;
}
</style>
