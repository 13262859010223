export const setActualDate = (data, value, m) => {
  if (value) return value

  const dates = []
  data?.forEach(dataItem => {
    for (let i = dataItem?.data?.length; i >= 0; --i) {
      if (m === 'marketing-mix') {
        if (dataItem?.data[i]?.data?.Direct) {
          dates.push(dataItem?.data[i].date)
          break
        }
      } else if (dataItem?.data[i]?.data) {
        dates.push(dataItem?.data[i].date)
        break
      }
    }
  })

  const dataFirstDomain = data?.[0]?.data
  if (!dates.length)
    return dataFirstDomain?.[dataFirstDomain.length - 1]?.date || null
  return dates.sort()[dates.length - 1]
}
