import requestHelper from '@/helpers/store/requestHelper'

export const mainData = {
  namespaced: true,
  actions: {
    async load(ctx) {
      const result = await requestHelper(
        {
          url: '/data/getMainData',
          viewId: ctx.rootState.view.viewId,
        },
        ctx,
        null
      )
      if (result) {
        // ctx.dispatch('engagement/set', result.data, { root: true })
        ctx.dispatch('geo/set', result.data, { root: true })
        // ctx.dispatch('searchTraffic/set', result.data, { root: true })
        ctx.dispatch('marketingChannels/set', result.data, { root: true })

        /** preset because we cannot update date. We'are waiting for additionalData */
        // ctx.dispatch('marketingChannels/preset', result.data, { root: true })

        const actualDate = '2022-05-01'
        // result.data[0]?.marketingMix[result.data[0]?.marketingMix?.length - 1]
        //   ?.date
        ctx.commit('setActualDate', actualDate)
      }
    },
  },
  state: {
    loading: true,
    error: { status: false },
    actualDate: null,
  },
  mutations: {
    updateLoading(state, status) {
      state.loading = status
    },
    updateError(state, errorData) {
      state.error = errorData
    },
    setActualDate(state, actualDate) {
      state.actualDate = actualDate
    },
  },
}
