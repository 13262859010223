<template>
  <div class="d-flex wrapper_local">
    <div><img class="iconLink" :src="iconLink" /></div>
    <div>
      <h2>{{ title }}</h2>
      <h3>{{ description }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: ['iconLink', 'title', 'description'],
  computed: {},
  methods: {},
}
</script>

<style scoped>
.iconLink {
  height: 35px;
  width: 35px;
  margin-right: 30px;
  margin-left: 40px;
}
h2 {
  font-size: 22px;
  font-weight: bold;
}
h3 {
  max-width: 412px;
  line-height: 19px;
}

@media (max-width: 768px) {
  .iconLink {
    margin-left: 15px;
    margin-right: 10px;
  }
}
</style>
