export const authModule = {
  namespaced: true,
  state: () => ({
    step: null,
    from: '',
  }),
  mutations: {
    setStep(state, step) {
      console.log(step, 1)
      state.step = step
    },
    setFrom(state, from) {
      state.from = from
    },
    close(state) {
      state.step = null
    },
  },
}
