export default function getColor(colorIndex) {
  const colors = [
    '#2684FE',
    '#FF72B2',
    '#FFCF33',
    '#9BC510',
    '#2F4858',
    '#DA4086',
    '#FF9A47',
    '#FF5353',
    '#13B45D',
    '#006571',
    '#70969A',
    '#4B73B9',
    '#C0B9D2',
    '#75696C',
    '#BC9B83', // 15
    '#CB6B26',
    '#C9A21A',
    '#BE344F',
    '#804797',
    '#AC49F9',
    '#00B7FF',
    '#0BAFCA',
    '#23E0FF',
    '#468661',
    '#2AB0A9',
  ]
  return colors[colorIndex] || '#DEDEDE'
}
