<template>
  <Modal
    options="full"
    :isOpen="authModule.step !== null"
    :closeHandler="close"
  >
    <component v-bind:is="component" />
    <template v-slot:body>
      <div>
        <div class="modal__inner">
          <!-- <div class="modal_side">
            <div class="modal_title_text">
              {{ $t('modals.auth.freeMetrica') }}
            </div>
            <span class="modal_description_text text_blue">
              {{ $t('modals.auth.dataExcel') }}
            </span>
            <div class="auth_main_items">
              <div class="auth_main_item">
                <div class="big_checkbox_arrow_icon">
                  <img :src="CheckmarkIcon" />
                </div>
                <div class="auth_main_item_text">
                  <div class="title">
                    {{ $t('modals.auth.moreData') }}
                  </div>
                  <div class="desc">
                    {{ $t('modals.auth.moreDataDesc') }}
                  </div>
                </div>
              </div>
              <div class="auth_main_item">
                <div class="big_checkbox_arrow_icon">
                  <img :src="CheckmarkIcon" />
                </div>
                <div class="auth_main_item_text">
                  <div class="title">
                    {{ $t('modals.auth.historicalData') }}
                  </div>
                  <div class="desc">
                    {{ $t('modals.auth.historicalDataDesc') }}
                  </div>
                </div>
              </div>
              <div class="auth_main_item">
                <div class="big_checkbox_arrow_icon">
                  <img :src="CheckmarkIcon" />
                </div>
                <div class="auth_main_item_text">
                  <div class="title">
                    {{ $t('modals.auth.compare') }}
                  </div>
                  <div class="desc">
                    {{ $t('modals.auth.compareDesc') }}
                  </div>
                </div>
              </div>
              <div class="auth_main_item">
                <div class="big_checkbox_arrow_icon">
                  <img :src="CheckmarkIcon" />
                </div>
                <div class="auth_main_item_text">
                  <div class="title">
                    {{ $t('modals.auth.noLimits') }}
                  </div>
                  <div class="desc">
                    {{ $t('modals.auth.noLimitsDesc') }}
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="modal_side right_side">
            <div class="modal_title_text text-center">
              {{ $t('modals.auth.welcome') }}
              <!-- {{ $t('modals.auth.freeTrial') }} -->
            </div>
            <div class="description text-center">
              <span class="text-muted text-center">
                <!-- {{ $t('modals.auth.noCreditCard') }} -->
              </span>
            </div>
            <div v-if="authModule.step === 1">
              <AuthStep1 />
            </div>
            <div
              v-if="authModule.step === 2"
              class="telegram_widget text-center"
            >
              <AuthStep2 />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import {
  TELEGRAM_URL,
  TELEGRAM_SUPPORT,
  TELEGRAM_BOT_NAME,
  REDIRECT_URL,
} from '@/helpers/variables'
import ClipLogoutIcon from '@/assets/icons/clip-logout.svg'
import Modal from '@/components/modals/Modal.vue'
import CheckmarkIcon from '@/assets/icons/base/checkmark.svg'
import ClipFinancialReport from '@/assets/icons/clip-financial-report.svg'
import ClipLetsPartyIllustrations from '@/assets/illustrations/clip-lets-party.svg'
import DiscussionIllustration from '@/assets/illustrations/Discussion.svg'
import TelegramIcon from '@/assets/icons/telegram.svg'
import Button from '@/components/buttons/Button.vue'
import AuthInstruction from '@/components/auth/AuthInstruction.vue'
import AuthStep1 from '@/components/auth/AuthStep1.vue'
import AuthStep2 from '@/components/auth/AuthStep2.vue'

export default {
  data() {
    return {
      ClipLogoutIcon,
      CheckmarkIcon,
      ClipFinancialReport,
      DiscussionIllustration,
      ClipLetsPartyIllustrations,
      TelegramIcon,
      telegramUrl: TELEGRAM_URL,
      TELEGRAM_BOT_NAME,
      TELEGRAM_SUPPORT,
      REDIRECT_URL,
    }
  },
  components: {
    Button,
    Modal,
    AuthInstruction,
    AuthStep1,
    AuthStep2,
  },
  mounted() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    if (urlSearchParams.get('auth') === 'true') {
      this.source = 'ext'
      this.setStep(1)
    }
  },
  computed: {
    ...mapState('authModule', {
      authModule: state => state,
    }),
    ...mapGetters('userInfo', ['userInfo']),
    ...mapState(['view']),
    component() {
      if (this.authModule.step === 1) return 'AuthorizationPopup'
      if (this.authModule.step === 2) return 'AuthorizationPopup'
      return null
    },
  },
  methods: {
    ...mapMutations('authModule', ['setStep', 'close']),
  },
}
</script>

<style scoped>
.btn_link {
  color: #2684fe;
}
.auth_main_item :v-deep(path) {
  fill: #2684fe;
}
.modal__inner {
  display: flex;
  flex-wrap: wrap;
  width: 1160px;
  background: #ffffff;
  border-radius: 7px;
  max-width: 100vw;
}
.modal__inner .modal_side {
  /* width: 50%; */
  width: 100%;
  padding: 60px 70px 80px 60px;
  background: #ffffff;
}
.modal__inner .modal_side:last-child {
  background: #f7f7f7;
}
.modal__inner .modal_title_text {
  font-weight: bold;
  font-size: 36px;
  line-height: 49px;
  color: #0e0f10;
  margin-bottom: 10px;
}

.modal__inner .modal_description_text {
  color: #2684fe;
  font-weight: 600;
  font-size: 16px;
}
.auth_main_items {
  padding-top: 10px;
}
.auth_main_item {
  display: flex;
  margin-top: 30px;
}
.auth_main_item_text {
  margin-left: 18px;
}
.auth_main_item .title {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #0e0f10;
  margin-bottom: 5px;
}
.auth_main_item .desc {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #5c5f6c;
}
.right_side .description {
  margin-bottom: 50px;
}
.tg_icon {
  margin-right: 20px;
  width: 14px;
  height: 14px;
}
.web_tg {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #808493;
}
.web_tg span {
  cursor: pointer;
}
.big_checkbox_arrow_icon {
  margin-top: 3px;
}
.modal_side.right_side svg {
  width: 284px;
  height: 225px;
}
@media (max-width: 1024px) {
  .modal__inner {
    width: auto;
  }
  .modal__inner .modal_side {
    width: 100%;
    padding: 20px;
  }
  .modal__inner .modal_title_text {
    font-weight: bold;
    font-size: 22px;
    line-height: normal;
    text-align: center;
    margin-top: 30px;
  }
  .modal__inner .modal_side:not(.right_side) {
    padding-bottom: 50px;
  }
  .auth_main_items {
    max-width: 550px;
    margin: 0 auto;
  }
  .text_blue {
    font-size: 15px;
    text-align: center;
    display: block;
  }
  .auth_main_items {
    padding-top: 0;
  }
  .auth_main_item {
    margin-top: 15px;
  }
  .auth_main_item .title {
    font-size: 16px;
    line-height: normal;
  }
  .auth_main_item .desc {
    font-size: 15px;
    line-height: normal;
  }
  .modal_side.right_side {
    order: -1;
  }
  .modal_side.right_side svg {
    width: 250px;
  }
  .right_side .description {
    margin-bottom: 25px;
  }
}
</style>
