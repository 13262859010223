<template>
  <div>
    <Table
      v-if="!loading"
      :items="tableData.items"
      :fields="tableData.fields"
      sortBy="traffic"
      :sortDesc="true"
    >
      <template v-slot:domainPicker>
        <DomainPicker type="local" />
      </template>
      <template #cell(refSource)="data">
        <LinkCell
          :styleOptions="{ maxWidth: '500px' }"
          :data="{ value: data.value.url }"
        />
        <TextCell
          :data="{ value: data.value.title }"
          :styleOptions="{ maxWidth: '600px', color: '#04B42B' }"
        />
      </template>
      <template #cell(traffic)="data">
        <TrafficCell :data="data" />
      </template>
      <template #cell(page)="data">
        <LinkCell :styleOptions="{ maxWidth: '350px' }" :data="data" />
      </template>
    </Table>
    <SkeletonLoading v-if="loading" name="table" />
  </div>
</template>

<script>
import DomainPicker from '@/components/DomainPicker.vue'
import TrafficCell from '@/components/tables/cells/TrafficCell.vue'
import LinkCell from '@/components/tables/cells/LinkCell.vue'
import TextCell from '@/components/tables/cells/TextCell.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import Table from '@/components/tables/Table.vue'

export default {
  data() {
    return {
      selectedCountry: '',
    }
  },
  components: {
    LinkCell,
    Table,
    TrafficCell,
    DomainPicker,
    SkeletonLoading,
    TextCell,
  },
  props: {
    section: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('referrals', ['tableData']),
    ...mapGetters(['selectedDomain']),
    ...mapState('referrals', {
      referrals: state => state,
    }),
    loading() {
      return this.referrals.loading
    },
  },
  methods: {},
}
</script>

<style scoped></style>
