import Vue, { createApp } from 'vue'
import VueHtml2Canvas from 'vue-html2canvas'
import { i18n } from '@/lang'
import { getCookie } from '@/helpers/base/cookie'
import 'regenerator-runtime'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import router from './router'
import App from './App.vue'

const jwt = getCookie('jwt')
if (jwt) store.commit('userInfo/setJwt', jwt)

const app = createApp(App)

Vue.use(VueHtml2Canvas)
app.use(store)
app.use(router)
app.use(i18n)

app.mount('#app')
