<template>
  <div class="d-flex flex-column align-items-center wrap_loading">
    <div v-if="name" class="placeholder-item">
      <img height="431" :src="SourcesLoader" v-if="name === 'sources'" />
      <img :src="Notifications" v-if="name === 'notifications'" />
      <img :src="TrafficLoader" v-if="name === 'traffic'" />
      <img
        height="690"
        :src="SimilarSitesLoader"
        v-if="name === 'similarSites'"
      />
      <img height="379" :src="GeoLoader" v-if="name === 'geo'" />
      <img height="325" :src="EcosystemLoader" v-if="name === 'ecosystem'" />
      <img height="325" :src="MobileAppsLoader" v-if="name === 'mobileApps'" />
      <img height="381" :src="KeywordsLoader" v-if="name === 'keywords'" />
      <template v-if="name === 'deviceDestribution'">
        <img height="119" :src="DeviceDistrubutionHeadLoader" />
        <img
          height="51"
          :src="DeviceDistrubutionItemLoader"
          v-for="item of domains"
          :key="item"
        />
      </template>
      <template v-if="name === 'engagement'">
        <img height="122" :src="EngagementHeadLoader" />
        <img
          height="51"
          :src="EngagementItemLoader"
          v-for="item of domains"
          :key="item"
        />
      </template>
      <template v-if="name === 'uniqueUsers'">
        <img height="122" :src="DeviceDistrubutionHeadLoader" />
        <img
          height="53"
          :src="DeviceDistrubutionItemLoader"
          v-for="item of domains"
          :key="item"
        />
      </template>
      <template v-if="name === 'marketingChannels'">
        <img height="122" :src="MarketingChannelsHeadLoader" />
        <img
          height="53"
          :src="MarketingChannelsItemLoader"
          v-for="item of domains"
          :key="item"
        />
      </template>
      <template
        v-if="name === ('organicPaid' || 'searchTraffic' || 'brandNonBranded')"
      >
        <img height="99" :src="OrganicPaidHeadLoader" />
        <img
          height="39"
          :src="OrganicPaidItemLoader"
          v-for="item of domains"
          :key="item"
        />
      </template>
      <template height="673" v-if="name === 'table'">
        <img :src="TableLoader" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SourcesLoader from '@/assets/skeleton/skeleton-sources.svg'
import TrafficLoader from '@/assets/skeleton/skeleton-traffic.svg'
import EcosystemLoader from '@/assets/skeleton/skeleton-ecosystem.svg'
import GeoLoader from '@/assets/skeleton/skeleton-geo.svg'
import SimilarSitesLoader from '@/assets/skeleton/skeleton-similar-sites.svg'
import MobileAppsLoader from '@/assets/skeleton/skeleton-mobile-apps.svg'
import Notifications from '@/assets/skeleton/skeleton-notifications.svg'

import DeviceDistrubutionHeadLoader from '@/assets/skeleton/skeleton-device-destribution/device-destribution-head.svg'
import DeviceDistrubutionItemLoader from '@/assets/skeleton/skeleton-device-destribution/device-destribution-item.svg'

import EngagementHeadLoader from '@/assets/skeleton/skeleton-engagement/engagement-head.svg'
import EngagementItemLoader from '@/assets/skeleton/skeleton-engagement/engagement-item.svg'

import MarketingChannelsHeadLoader from '@/assets/skeleton/skeleton-marketing-channels/marketing-channels-head.svg'
import MarketingChannelsItemLoader from '@/assets/skeleton/skeleton-marketing-channels/marketing-channels-item.svg'

import OrganicPaidHeadLoader from '@/assets/skeleton/skeleton-organic-paid/organic-paid-head.svg'
import OrganicPaidItemLoader from '@/assets/skeleton/skeleton-organic-paid/organic-paid-item.svg'

import KeywordsLoader from '@/assets/skeleton/skeleton-keywords.svg'
import TableLoader from '@/assets/skeleton/skeleton-table.svg'

export default {
  data() {
    return {
      SourcesLoader,
      TrafficLoader,
      EcosystemLoader,
      GeoLoader,
      SimilarSitesLoader,
      MobileAppsLoader,
      DeviceDistrubutionHeadLoader,
      DeviceDistrubutionItemLoader,
      EngagementHeadLoader,
      EngagementItemLoader,
      MarketingChannelsHeadLoader,
      MarketingChannelsItemLoader,
      OrganicPaidHeadLoader,
      OrganicPaidItemLoader,
      KeywordsLoader,
      TableLoader,
      Notifications
    }
  },
  props: ['name'],
  computed: {
    ...mapGetters(['domains']),
  },
  methods: {},
}
</script>

<style scoped>
.placeholder-item {
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.placeholder-item img {
  width: 100%;
  position: relative;
}

.placeholder-item::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(242, 242, 242, 0.08),
    rgba(255, 255, 255, 0.5) 25%,
    rgba(255, 255, 255, 0.3) 50%,
    #fff0
  );
  z-index: 1;
  animation: load 1.5s cubic-bezier(0.15, 0, 0.2, 1) infinite;
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
</style>
