<template>
  <div>
    <template v-if="!errorData.status">
      <div class="d-flex flex-column flex-xl-row w-100">
        <TrafficChart class="wrapper_traffic_chart" />
        <div class="d-flex wrapper_similar_sites">
          <SimilarSites />
        </div>
      </div>
      <!-- <div class="d-flex flex-md-wrap flex-column flex-xl-row w-100 g-0">
        <div class="d-flex col wrapper_unique_visitors">
          <UniqueVisitors />
        </div>
        <div class="d-flex col wrapper_device_distribution">
          <DeviceDistribution />
        </div>
        <div class="d-flex col wrapper_engagement">
          <Engagement />
        </div>
      </div> -->

      <MarketingChannels />

      <!-- <div class="row g-0 flex-column flex-xl-row w-100">
        <div class="d-flex col wrapper_referrals">
          <referrals />
        </div>
        <div class="d-flex col wrapper_social_media">
          <SocialMedia />
        </div>
        <div class="d-flex col wrapper_ad_networks">
          <AdNetworks />
        </div>
      </div> -->

      <!-- <div class="row g-0 flex-column flex-xxl-row w-100">
        <div class="d-flex col wrapper_search_traffic">
          <SearchTraffic />
        </div>
        <div class="d-flex col wrapper_organic_vs_paid">
          <OrganicVsPaid />
        </div>
        <div class="d-flex col wrapper_brend_non_brend">
          <BrandNonBranded />
        </div>
      </div> -->

      <div class="row g-0 flex-column flex-xxl-row w-100">
        <!-- <div class="d-flex col wrapper_keywords">
          <Keywords />
        </div> -->
        <div class="d-flex col wrapper_geo">
          <Geo />
        </div>
      </div>

      <!-- <div class="row g-0 flex-column flex-xxl-row w-100">
        <div class="d-flex col wrapper_ecosystem">
          <Ecosystem />
        </div>
        <div class="d-flex col wrapper_mobile_apps">
          <MobileApps />
        </div>
      </div> -->
    </template>
    <template v-else>
      <ErrorPage :errorData="errorData" />
    </template>

    <div v-if="domains.length" @click="$store.dispatch('addDomainModal/show')">
      <AddDomainButtonMobile class="d-md-none" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import TrafficChart from '@/components/widgets/traffic/TrafficChart.vue'
import UniqueVisitors from '@/components/widgets/UniqueVisitors.vue'
import DeviceDistribution from '@/components/widgets/DeviceDistribution.vue'
import SimilarSites from '@/components/widgets/similar-sites/SimilarSites.vue'
import MarketingChannels from '@/components/widgets/marketing-channels/MarketingChannels.vue'
import Geo from '@/components/widgets/geo/Geo.vue'
import Engagement from '@/components/widgets/Engagement.vue'
import referrals from '@/components/widgets/traffic-sources/Referrals.vue'
import AdNetworks from '@/components/widgets/traffic-sources/AdNetworks.vue'
import SocialMedia from '@/components/widgets/traffic-sources/SocialMedia.vue'
import ErrorPage from '@/views/NotFoundPage.vue'
import OrganicVsPaid from '@/components/widgets/OrganicVsPaid.vue'
import SearchTraffic from '@/components/widgets/SearchTraffic.vue'
import BrandNonBranded from '@/components/widgets/BrandNonBranded.vue'
import Keywords from '@/components/widgets/keywords/Keywords.vue'
import Ecosystem from '@/components/widgets/ecosystem/Ecosystem.vue'
import MobileApps from '@/components/widgets/mobile-apps/MobileApps.vue'
import AddDomainButtonMobile from '@/components/add-domain/AddDomainButtonMobile.vue'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['navigation/domains', 'domains']),
    ...mapGetters(['errorData']),
  },
  components: {
    TrafficChart,
    SimilarSites,
    MarketingChannels,
    UniqueVisitors,
    DeviceDistribution,
    Geo,
    referrals,
    Engagement,
    ErrorPage,
    AdNetworks,
    SocialMedia,
    OrganicVsPaid,
    SearchTraffic,
    BrandNonBranded,
    Keywords,
    Ecosystem,
    MobileApps,
    AddDomainButtonMobile,
  },
  async created() {
    await this.getAndCreateView({
      domains: this['navigation/domains'],
    })
    this.loadAll()
  },
  methods: {
    ...mapActions(['loadAll', 'getAndCreateView']),
  },
}
</script>
<style scoped>
@media (min-width: 1200px) {
  .wrapper_unique_visitors,
  .wrapper_device_distribution {
    padding-right: 20px;
  }

  .wrapper_similar_sites {
    padding-left: 20px;
    min-width: 300px;
  }

  .wrapper_device_distribution,
  .wrapper_unique_visitors,
  .wrapper_referrals,
  .wrapper_social_media,
  .wrapper_ad_networks,
  .wrapper_search_traffic,
  .wrapper_organic_vs_paid,
  .wrapper_brend_non_brend,
  .wrapper_keywords,
  .wrapper_geo,
  .wrapper_ecosystem,
  .wrapper_mobile_apps {
    min-width: 450px;
  }

  .wrapper_engagement {
    min-width: 575px;
    flex-basis: calc(100% / 7 * 3);
  }

  .wrapper_referrals,
  .wrapper_social_media,
  .wrapper_search_traffic,
  .wrapper_organic_vs_paid,
  .wrapper_keywords,
  .wrapper_ecosystem {
    padding-right: 20px;
  }

  .wrapper_traffic_chart,
  .wrapper_similar_sites {
    min-width: 350px;
  }

  .wrapper_traffic_chart {
    width: 65%;
  }
  .wrapper_similar_sites {
    width: 35%;
  }
}

@media (max-width: 1200) {
  .wrapper_social_media,
  .wrapper_organic_vs_paid {
    padding-right: 0;
  }

  .wrapper_traffic_chart {
    width: 100%;
  }
  .wrapper_similar_sites {
    width: 100%;
    padding-left: 0;
  }
}

@media (max-width: 1700px) {
  .wrapper_device_distribution {
    padding-right: 0;
  }
}

.wrapper_geo {
  max-width: auto;
  width: 100%;
}

.wrapper_referrals {
  width: 100%;
}
</style>
