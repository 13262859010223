<template>
  <span>
    <template v-if="slots[`cell(${field.key})`]">
      <slot
        :name="`cell(${field.key})`"
        v-bind="{ value: item[field.key] }"
      ></slot>
    </template>
    <template v-else>
      {{ item[field.key] }}
    </template>
  </span>
</template>

<script>
import nFormatter from '@/helpers/processing/nFormatter'

export default {
  components: {},
  props: ['item', 'field', 'slots'],
  data() {
    return {}
  },
  methods: {
    nFormatter,
  },
}
</script>

<style scoped></style>
