<template>
  <div>
    <Table
      v-if="!loading"
      :items="countryFilter(tableData.items, selectedCountry)"
      :fields="tableData.fields"
      sortBy="share"
      :sortDesc="true"
    >
      <template v-slot:domainPicker>
        <DomainPicker type="local" />
      </template>
      <template v-if="selectedCountry" v-slot:countryPicker>
        <DropDown :title="selectedCountry" type="country">
          <template v-slot:items>
            <DropDownItem
              v-for="country of countries"
              type="country"
              :text="country"
              :value="country"
              :key="country"
              @selectItem="selectItem"
            />
          </template>
        </DropDown>
      </template>
      <template #cell(key)="data">
        <TextCell :data="data" />
      </template>
      <template #cell(share)="data">
        <TrafficCell :data="data" format="share" />
      </template>
      <template #cell(page)="data">
        <LinkCell
          :data="data"
          :styleOptions="{ maxWidth: '220px' }"
          :domain="selectedDomain"
          :favicon="false"
        />
      </template>
    </Table>
    <SkeletonLoading v-if="loading" name="table" />
  </div>
</template>

<script>
import DomainPicker from '@/components/DomainPicker.vue'
import TrafficCell from '@/components/tables/cells/TrafficCell.vue'
import TextCell from '@/components/tables/cells/TextCell.vue'
import LinkCell from '@/components/tables/cells/LinkCell.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import DropDown from '@/components/shared/drop-down/DropDown.vue'
import DropDownItem from '@/components/shared/drop-down/DropDownItem.vue'
import countryFilter from '@/helpers/filters/country-filter'
import { mapActions, mapState, mapGetters } from 'vuex'
import Table from '@/components/tables/Table.vue'

export default {
  data() {
    return {
      selectedCountry: '',
    }
  },
  components: {
    LinkCell,
    Table,
    TrafficCell,
    DomainPicker,
    SkeletonLoading,
    DropDown,
    DropDownItem,
    TextCell,
  },
  props: {
    section: {
      type: String,
    },
  },
  created() {
    this.selectedCountry = this.tableData.items[0]?.countryName
  },
  computed: {
    ...mapGetters('keywords', ['tableData']),
    ...mapGetters(['selectedDomain']),
    ...mapState('keywords', {
      keywords: state => state,
    }),
    loading() {
      return this.keywords.loading
    },
    countries() {
      return this.tableData.items.map(item => item.countryName)
    },
  },
  methods: {
    countryFilter,
    selectItem(value) {
      this.selectedCountry = value
    },
  },
}
</script>

<style scoped></style>
