<template>
  <div>
    <Table
      v-if="!loading && isData"
      :items="tableData.items"
      :fields="tableData.fields"
      sortBy="traffic"
      :sortDesc="true"
    >
      <template v-slot:domainPicker>
        <DomainPicker type="local" />
      </template>
      <template #cell(country)="data">
        <CountryCell :data="data" />
      </template>
      <template #cell(traffic)="data">
        <TrafficCell :data="data" format="share" />
      </template>
      <template #cell(change)="data">
        <ChangeCell :data="data" />
      </template>
    </Table>
    <WidgetError v-if="!isData && !loading" />
    <SkeletonLoading v-if="loading" name="table" />
  </div>
</template>

<script>
import DomainPicker from '@/components/DomainPicker.vue'
import TrafficCell from '@/components/tables/cells/TrafficCell.vue'
import CountryCell from '@/components/tables/cells/CountryCell.vue'
import ChangeCell from '@/components/tables/cells/ChangeCell.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import Table from '@/components/tables/Table.vue'
import WidgetError from '@/components/errors/WidgetError.vue'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {}
  },
  components: {
    Table,
    TrafficCell,
    DomainPicker,
    SkeletonLoading,
    CountryCell,
    ChangeCell,
    WidgetError,
  },
  props: {
    section: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('geo', ['tableData', 'isData']),
    ...mapState('geo', {
      geo: state => state,
    }),
    ...mapState('mainData', {
      mainData: state => state,
    }),
    loading() {
      return this.geo.loading
    },
  },
}
</script>

<style scoped></style>
