<template>
  <div
    @click="$emit('input', !value || null)"
    role="checkbox"
    :aria-checked="value"
    tabIndex="0"
    @keypress="$emit('input', !value || null)"
    data-label="{label}"
    class="wrapper_checkbox"
  >
    <div
      :style="{
        backgroundColor: value ? color : '',
        border: `1px solid ${color}`,
      }"
      class="checkbox"
    >
      <img :src="CheckedIcon" v-if="value" />
    </div>
    <div class="label">
      <slot />
    </div>
  </div>
</template>

<script>
import CheckedIcon from '@/assets/icons/base/checkmark-white.svg'

export default {
  name: 'Checkbox',
  data() {
    return {
      CheckedIcon,
    }
  },
  props: {
    value: Boolean,
    color: {
      type: String,
      default: '#4E8CF9',
    },
  },
  components: {},
}
</script>

<style scoped>
.checkbox {
  border-radius: 2px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.checkbox svg {
  pointer-events: none;
}
.wrapper_checkbox {
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
}
.label {
  font-size: 14px;
  line-height: 16px;
  color: #808493;
}
</style>
