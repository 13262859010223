<template>
  <div class="wrapper_domain_content">
    <div class="d-flex">
      <Domain maxWidth="maxWidth" :domain="domain" />
    </div>
    <div class="divider w-100"></div>
    <div class="d-flex align-items-center">
      <span class="visits ms-2">
        {{ nFormatter(getValue(traffic)) }}
      </span>
    </div>
  </div>
</template>

<script>
import Domain from '@/components/widgets/widgets-helpers/Domain.vue'
import nFormatter from '@/helpers/processing/nFormatter'
import getValue from '@/helpers/processing/getValue'

export default {
  data() {
    return {}
  },
  props: ['domain', 'traffic'],
  components: { Domain },
  computed: {},
  methods: {
    nFormatter,
    getValue,
  },
}
</script>

<style scoped>
.visits {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0e0f10;
}

.divider {
  border-bottom: 1px solid #eaeef8;
  margin-bottom: 4px;
}

.wrapper_domain_content {
  display: grid;
  grid: min-content / max-content 1fr max-content;
  gap: 16px 20px;
  height: 22px;
  margin-bottom: 16px;
}
</style>
