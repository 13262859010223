/* eslint-disable camelcase */
export default function getListDates(data, options = {}) {
  const localOptions = {
    withAvailableData: options.withAvailableData
      ? options.withAvailableData
      : true,
    subscriptionActive: options.subscriptionActive,
  }

  const dates = []
  data.forEach(dataItem => {
    dataItem.data.forEach(item => {
      if (
        dates.includes(item.date) ||
        (localOptions.withAvailableData &&
          !item.data &&
          localOptions.isSubscriptionActive)
      )
        return
      dates.push(item.date)
    })
  })
  dates.sort().reverse()
  return dates
}
