<template>
  <WidgetWrapper>
    <WidgetTitle
      v-if="!loading"
      :title="$t('main.searchTraffic.title')"
      :description="$t('main.searchTraffic.title')"
    />
    <div class="content" ref="content" v-if="isData">
      <div class="wrapper_domain_content">
        <WidgetListTitle
          class="topics"
          :firstName="$t('main.searchTraffic.widgetListTitle')"
          :secondName="$t('main.searchTraffic.widgetListValue')"
        />
        <template v-for="item of dataOnActualDate" :key="item.domainName">
          <DomainItemLine :domain="item.domainName" :traffic="item.data" />
        </template>
      </div>
    </div>
    <WidgetError v-if="!isData && !loading" />
    <SkeletonLoading v-if="loading" name="searchTraffic" />
  </WidgetWrapper>
</template>

<script>
import WidgetWrapper from '@/components/widgets/widgets-helpers/WidgetWrapper.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import WidgetError from '@/components/errors/WidgetError.vue'
import WidgetTitle from '@/components/widgets/widgets-helpers/WidgetTitle.vue'
import WidgetListTitle from '@/components/widgets/widgets-helpers/WidgetListTitle.vue'
import DomainItemLine from '@/components/widgets/widgets-helpers/DomainItemLine.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {}
  },
  components: {
    SkeletonLoading,
    WidgetError,
    WidgetTitle,
    WidgetWrapper,
    WidgetListTitle,
    DomainItemLine,
  },
  computed: {
    ...mapGetters('searchTraffic', ['dataOnActualDate']),
    ...mapState('mainData', {
      mainData: state => state,
    }),
    isData() {
      return !this.loading && !this.mainData.error.status
    },
    loading() {
      return this.mainData.loading
    },
  },
}
</script>

<style scoped>
.item {
  padding-bottom: 8px;
  padding-top: 16px;
}

.item + .item {
  padding-top: 8px;
}
.item:last-child {
  padding-bottom: 16px;
}

.topics {
  margin-bottom: 18px;
}

.visits {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #0e0f10;
}
/* 
.divider {
  border-bottom: 1px solid #eaeef8;
  margin-bottom: 4px;
} */

/* .wrapper_domain_content {
  display: grid;
  grid: min-content / max-content 1fr max-content;
  gap: 16px 20px;
}

.topics {
  grid-column: span 3;
} */
</style>
