export const searchTraffic = {
  namespaced: true,
  actions: {
    set(ctx, data) {
      ctx.commit('updateDates', ctx.rootState['marketingChannels/dates'])
      ctx.commit(
        'updateActualDate',
        ctx.rootState['marketingChannels/actualDate']
      )
    },
  },
  state: {
    dataSearch: [],
    dataPaidOrganic: [],
    actualDate: '2021-09-01',
    dates: [],
  },
  mutations: {
    updateDates(state, data) {
      state.dates = data
    },
    updateActualDate(state, value) {
      state.actualDate = value
    },
  },
  getters: {
    dataOnActualDate(state, getters, rootState, rootGetters) {
      const { domains } = rootState.view

      const result = domains.map(domainName => {
        const trafficFiltered = rootState.traffic.data.filter(
          item => item.domainName === domainName
        )
        const trafficValues = Object.values(trafficFiltered[0]?.data || {})
        const trafficValue = trafficValues[trafficValues.length - 1] || 0
        const channelsFiltered = rootGetters[
          'marketingChannels/dataOnActualDate'
        ].filter(item => item.domainName === domainName)
        const searchShare = channelsFiltered[0]?.data[0]?.data?.Search || null
        const searchValue = trafficValue * (searchShare / 100) || null
        return {
          domainName,
          data: searchValue,
        }
      })
      return result
    },
  },
}
