<template>
  <div>
    <Table
      v-if="!loading"
      :items="tableData.items"
      :fields="tableData.fields"
      sortBy="traffic"
      :sortDesc="true"
    >
      <template v-slot:domainPicker>
        <DomainPicker type="local" />
      </template>
      <template #cell(domainName)="data">
        <LinkCell :data="data" />
      </template>
      <template #cell(traffic)="data">
        <TrafficCell :data="data" />
      </template>
      <!-- <template #cell(change)="data">
        <ChangeCell :data="data" />
      </template> -->
    </Table>
    <WidgetError v-if="!isData && !loading" />
    <SkeletonLoading v-if="loading" name="table" />
  </div>
</template>

<script>
import DomainPicker from '@/components/DomainPicker.vue'
import TrafficCell from '@/components/tables/cells/TrafficCell.vue'
import LinkCell from '@/components/tables/cells/LinkCell.vue'
import ChangeCell from '@/components/tables/cells/ChangeCell.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import WidgetError from '@/components/errors/WidgetError.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import Table from '@/components/tables/Table.vue'

export default {
  data() {
    return {}
  },
  components: {
    LinkCell,
    Table,
    TrafficCell,
    DomainPicker,
    SkeletonLoading,
    WidgetError,
    ChangeCell,
  },
  props: {
    section: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('ecosystem', ['tableData']),
    ...mapGetters('ecosystem', ['dataOnActualDate', 'isData']),
    ...mapState('ecosystem', {
      ecosystem: state => state,
    }),
    loading() {
      return this.ecosystem.loading
    },
  },
}
</script>

<style scoped></style>
