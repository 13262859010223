<template>
  <div
    class="d-flex wrapper_local w-100 justify-content-between align-items-center"
  >
    <div class="wrapper_domain">
      <div class="d-flex">
        <Favicon class="icon" :domain="domain" />
        <div class="domain">{{ domain }}</div>
        <a
          class="d-flex link align-items-start"
          target="_blank"
          :href="'//' + domain"
        >
          <img :src="LinkOnSiteIcon" />
        </a>
      </div>
    </div>
    <div class="wrapper_visits">
      <div><span class="equal">≈</span>{{ visits }}</div>
    </div>
  </div>
</template>

<script>
import LinkOnSiteIcon from '@/assets/icons/link-on-site.svg'
import Favicon from '@/components/shared/Favicon.vue'

export default {
  data() {
    return {
      LinkOnSiteIcon,
    }
  },
  props: ['domain', 'visits'],
  computed: {},
  components: {
    Favicon,
  },
  methods: {},
}
</script>

<style scoped>
.wrapper_local {
  max-width: 670px;
  background: #fafafc;
  padding: 30px 40px;
  border-radius: 7px;
}
.wrapper_domain {
  font-size: 24px;
  margin-right: 10px;
}
.wrapper_visits {
  font-size: 36px;
}
.icon,
.equal {
  margin-right: 10px;
}
:v-deep(.icon img) {
  width: 24px !important;
  height: 24px !important;
}

@media (max-width: 768px) {
  .wrapper_domain {
    font-size: 18px;
  }
  .wrapper_visits {
    font-size: 26px;
  }
  .wrapper_local {
    padding: 30px 15px;
    max-width: 100%;
  }
}

.domain {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
}
@media (max-width: 520px) {
  .domain {
    max-width: 131px;
  }
}
</style>
