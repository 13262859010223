<template>
  <WidgetWrapper>
    <WidgetTitle
      v-if="!loading"
      :title="$t('main.marketingChannels.title')"
      :description="$t('main.marketingChannels.description')"
    >
      <template v-slot:dropDown>
        <div class="d-flex">
          <Date
            :actualDate="marketingChannels.actualDate"
            :options="{ showFullDate: false, oldCalendar: true }"
          />
          <DropDown
            class="value_picker"
            type="origin"
            :title="valuePicker[value_type]"
            :divider="true"
          >
            <template v-slot:items>
              <DropDownItem
                @selectItem="selectItem"
                :text="$t('main.marketingChannels.valuePicker.percentValue')"
                value="percent"
              />
              <DropDownItem
                @selectItem="selectItem"
                :text="$t('main.marketingChannels.valuePicker.absoluteValue')"
                value="absolute"
                :isLocked="!subscriptionActive"
              />
            </template>
          </DropDown>
          <!-- <div @click="onModalOpen()">
            <DynamicButton />
          </div> -->
        </div>
      </template>
    </WidgetTitle>
    <div v-if="isData" class="content" ref="content">
      <TableView
        :channelsInfo="channelsInfo"
        :valueHandler="getValue"
        :value_type="value_type"
        :dataset="datasetOnActualDate"
        :actualDate="marketingChannels.actualDate"
        :platforms="marketingChannels.platforms"
      />
    </div>
    <Modal
      type="table"
      :closeHandler="close => (isModalOpen = false)"
      :isOpen="isModalOpen"
    >
      <template v-slot:title>
        {{ $t('main.marketingChannels.title') }}
      </template>
      <template v-slot:body>
        <DynamicMarketingChannels :channelsInfo="channelsInfo" />
      </template>
    </Modal>
    <WidgetError v-if="!isData && !loading" />
    <SkeletonLoading v-if="loading" name="marketingChannels" />
  </WidgetWrapper>
</template>

<script>
import WidgetTitle from '@/components/widgets/widgets-helpers/WidgetTitle.vue'
import Modal from '@/components/modals/Modal.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import WidgetError from '@/components/errors/WidgetError.vue'
import WidgetWrapper from '@/components/widgets/widgets-helpers/WidgetWrapper.vue'
import DynamicMarketingChannels from '@/components/widgets/marketing-channels/dynamic/DynamicMarketingChannels.vue'
import { mapGetters, mapState } from 'vuex'
import SocialIcon from '@/assets/icons/marketing-channels/Social.svg'
import AdNetworksIcon from '@/assets/icons/marketing-channels/AdNetworks.svg'
import MailIcon from '@/assets/icons/marketing-channels/Mail.svg'
import ReferralsIcon from '@/assets/icons/marketing-channels/Referrals.svg'
import SearchIcon from '@/assets/icons/marketing-channels/Search.svg'
import DirectIcon from '@/assets/icons/marketing-channels/Direct.svg'
import DropDown from '@/components/shared/drop-down/DropDown.vue'
import DropDownItem from '@/components/shared/drop-down/DropDownItem.vue'
import getValue from '@/helpers/processing/getValue'
import getColor from '@/helpers/store/getColor'
import nFormatter from '@/helpers/processing/nFormatter'
import getChannelSortIndex from '@/helpers/store/getChannelSortIndex'
import DynamicButton from '@/components/buttons/DynamicButton.vue'
import Date from '@/components/shared/Date.vue'
import TableView from './TableView.vue'

export default {
  data() {
    return {
      value_type: 'percent',
      valuePicker: {
        percent: '%',
        absolute: '#',
      },
      isModalOpen: false,
      channelsInfo: {
        Direct: {
          title: this.$t('main.marketingChannels.direct.title'),
          icon: DirectIcon,
          hintName: 'metric-direct',
          hintTitle: this.$t('faq.metric-direct'),
        },
        Search: {
          title: this.$t('main.marketingChannels.search.title'),
          icon: SearchIcon,
          hintName: 'metric-search',
          hintTitle: this.$t('faq.metric-search'),
        },
        Referrals: {
          title: this.$t('main.marketingChannels.referrals.title'),
          icon: ReferralsIcon,
          hintName: 'metric-referral-sites',
          hintTitle: this.$t('faq.metric-referral-sites'),
        },
        Social: {
          title: this.$t('main.marketingChannels.social.title'),
          icon: SocialIcon,
          hintName: 'metric-social-media',
          hintTitle: this.$t('faq.metric-social-media'),
        },
        'Paid Referrals': {
          title: this.$t('main.marketingChannels.paidReferrals.title'),
          icon: AdNetworksIcon,
          hintName: 'metric-display-ads',
          hintTitle: this.$t('faq.metric-display-ads'),
        },
        Mail: {
          title: this.$t('main.marketingChannels.mail.title'),
          icon: MailIcon,
          hintName: 'metric-email',
          hintTitle: this.$t('faq.metric-email'),
        },
      },
    }
  },
  components: {
    WidgetTitle,
    TableView,
    SkeletonLoading,
    WidgetError,
    WidgetWrapper,
    DropDown,
    DropDownItem,
    DynamicMarketingChannels,
    DynamicButton,
    Modal,
    Date,
  },
  computed: {
    ...mapGetters(['subscriptionActive']),
    ...mapGetters('marketingChannels', ['datasetOnActualDate']),
    ...mapState('mainData', {
      mainData: state => state,
    }),
    ...mapState('additionalData', {
      additionalData: state => state,
    }),
    ...mapState('marketingChannels', {
      marketingChannels: state => state,
    }),
    isData() {
      return (
        !this.loading &&
        !this.mainData.error.status &&
        this.datasetOnActualDate.length
      )
    },
    loading() {
      // return true
      return this.mainData.loading // && this.additionalData.loading
    },
  },
  methods: {
    getValue,
    getColor,
    getChannelSortIndex,
    nFormatter,
    selectItem(value) {
      this.value_type = value
    },
    onModalOpen() {
      if (!this.subscriptionActive)
        return this.$store.dispatch(
          'trialEndedPopupModule/getPlanAndOpen',
          'marketing_channels'
        )
      this.isModalOpen = true
    },
  },
}
</script>

<style scoped>
.value_picker {
  margin-right: 20px;
}
</style>
