<template>
  <div class="hint" :data-label="text">
    <slot />
  </div>
</template>

<script>
export default {
  props: ['text'],
}
</script>

<style scoped>
.hint {
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.hint::before,
.hint::after {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  display: none;
  transition: all 200ms ease;
  z-index: 9;
}

.hint::before {
  content: '';
  top: calc(100% + 4px);
  border: 8px solid #2c2738;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top: none;
}

.hint::after {
  content: attr(data-label);
  top: calc(100% + 12px);
  background-color: #2c2738;
  border-radius: 7px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  padding: 15px 20px;
  text-align: center;
}

.hint:hover::before,
.hint:hover::after {
  display: block;
}

@media (max-width: 768px) {
  .hint::before,
  .hint::after {
    display: none;
  }
}
</style>
