<template>
  <span
    class="wrapper_difference d-flex align-items-center"
    :class="{ positive: change > 0 ? true : false }"
  >
    <img v-if="change && change > 0" class="me-1" :src="ChangeGreenIcon" />
    <img v-if="change && change <= 0" class="me-1" :src="ChangeRedIcon" />

    {{ getValue(change, '%') }}
  </span>
</template>

<script>
import getValue from '@/helpers/processing/getValue'
import ChangeRedIcon from '@/assets/icons/base/change-arrow-red.svg'
import ChangeGreenIcon from '@/assets/icons/base/change-arrow-green.svg'

export default {
  data() {
    return {
      ChangeRedIcon,
      ChangeGreenIcon,
    }
  },
  components: {},
  props: ['change'],
  methods: {
    getValue,
  },
}
</script>

<style scoped>
.wrapper_difference {
  font-size: 12px;
  font-weight: 400;
  color: #f82128;
}

.wrapper_difference.positive {
  color: #04b42b;
}

/* .wrapper_difference.positive > svg {
  transform: rotate(180deg);
} */
</style>
