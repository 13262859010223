<template>
  <div :style="styleOptions">
    {{ data.value }}
  </div>
</template>

<script>
import nFormatter from '@/helpers/processing/nFormatter'

export default {
  components: {},
  props: {
    data: Object,
    styleOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    nFormatter,
  },
}
</script>

<style scoped>
div {
  max-width: 300px;
}
</style>
