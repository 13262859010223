<template>
  <WidgetWrapper>
    <WidgetTitle
      v-if="!loading"
      :title="$t('main.social.title')"
      :description="$t('main.social.description')"
      :hintOptions="hintOptions"
      :actualDate="social.actualDate"
    />
    <div v-if="isData" class="content" ref="content">
      <WidgetListTitle
        class="title mb-2 mt-2"
        :firstName="$t('main.social.widgetListTitle')"
        :secondName="$t('main.social.widgetListValue')"
      />
      <template v-for="(share, socialName) in mainDomainData" :key="socialName">
        <DomainShareItem
          :domain="socialName"
          :share="share + '%'"
          :change="0"
        />
      </template>
    </div>
    <WidgetError v-if="!isData && !loading" />
    <SkeletonLoading v-if="loading" name="sources" />
  </WidgetWrapper>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import WidgetTitle from '@/components/widgets/widgets-helpers/WidgetTitle.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import WidgetError from '@/components/errors/WidgetError.vue'
import WidgetListTitle from '@/components/widgets/widgets-helpers/WidgetListTitle.vue'
import DomainShareItem from '@/components/widgets/traffic-sources/DomainShareItem.vue'
import WidgetWrapper from '@/components/widgets/widgets-helpers/WidgetWrapper.vue'

export default {
  data() {
    return {}
  },
  components: {
    WidgetTitle,
    SkeletonLoading,
    WidgetError,
    WidgetListTitle,
    DomainShareItem,
    WidgetWrapper,
  },
  computed: {
    ...mapGetters('social', ['dataOnActualDate', 'mainDomainData']),
    ...mapState('additionalData', {
      additionalData: state => state,
    }),
    ...mapState('social', {
      social: state => state,
    }),
    hintOptions() {
      return {
        name: 'metric-social-media',
        title: this.$t('faq.metric-social-media'),
        actualDate: this.social.actualDate,
        platforms: this.social.platforms,
      }
    },
    ...mapState('social', {
      social: state => state,
    }),
    isData() {
      return (
        !this.loading &&
        !this.additionalData.error.status &&
        Object.keys(this.mainDomainData)?.length
      )
    },
    loading() {
      return this.additionalData.loading
    },
  },
}
</script>

<style scoped>
.content {
  margin: 0 -20px;
}
.title {
  padding: 0 20px;
}
</style>
