import { filterOnActualDate } from '@/helpers/store/filterOnActualDate'
import { setActualDate } from '@/helpers/store/setActualDate'
import { filterBySelectedDomain } from '@/helpers/store/filterBySelectedDomain'
import requestHelper from '@/helpers/store/requestHelper'
import extractDomain from '@/helpers/domains/extractDomain'
import { i18n } from '@/lang'

export const referrals = {
  namespaced: true,
  actions: {
    async load(ctx) {
      const { selectedDomain } = ctx.rootGetters
      await requestHelper(
        {
          url: '/data/getbacklinks',
          domains: selectedDomain,
          viewId: ctx.rootState.view.viewId,
          timeout: 3000,
        },
        ctx,
        'updateDataAll'
      )
      ctx.commit('updateActualDate')
      ctx.commit('updateLoading', false)
    },
    // Only 5 referrals
    set(ctx, data) {
      ctx.commit('updateData', data)
      ctx.commit('updateActualDate')
    },
  },
  state: () => ({
    data: [],
    dataAll: [],
    actualDate: null,
    loading: true,
    error: { status: false },
  }),
  mutations: {
    updateDataAll(state, data) {
      const adaptedData = data.map(item => {
        return {
          domainName: item.domainName,
          data: item.data.map(dataItem => {
            const totalTraffic = item?.totalTraffic
            const share = totalTraffic
              ? Math.round((dataItem.traffic / totalTraffic) * 10000) / 100
              : 0
            return {
              referralUrl: extractDomain(dataItem.url),
              traffic: dataItem.traffic,
              title: dataItem.title,
              page: extractDomain(dataItem.page, true),
              share,
              change: null,
            }
          }),
          date: item?.date,
          totalCount: item?.totalCount,
        }
      })
      state.dataAll = adaptedData
    },
    updateData(state, data) {
      const adaptedData = data.map(item => {
        return {
          domainName: item.domainName,
          data: item.referrals?.map(dateItem => {
            return {
              data: dateItem.data?.referrals?.map(referralItem => {
                return {
                  referralUrl: referralItem?.domainName,
                  change: referralItem?.change,
                  share: referralItem?.share,
                }
              }),
              date: dateItem?.date,
            }
          }),
        }
      })
      state.data = adaptedData
    },
    updateActualDate(state, value) {
      state.actualDate = setActualDate(state.data, value)
    },
    updateLoading(state, status) {
      state.loading = status
    },
    updateError(state, data) {
      state.error = data
    },
  },
  getters: {
    dataOnActualDate(state, getters, rootState, rootGetters) {
      const { selectedDomain } = rootGetters
      const data = filterOnActualDate(state.data, state.actualDate)
      return filterBySelectedDomain(data, selectedDomain)
    },
    dataOnActualDateAll(state, getters, rootState, rootGetters) {
      const { selectedDomain } = rootGetters
      return filterBySelectedDomain(state.dataAll, selectedDomain)
    },
    tableData(state, getters, rootState, rootGetters) {
      const items = []
      const fields = [
        {
          key: 'refSource',
          label: i18n.global.t('analytics.referral.title'),
          sortable: true,
        },
        {
          key: 'traffic',
          label: i18n.global.t('analytics.traffic.title'),
          sortable: true,
        },
        {
          key: 'page',
          label: i18n.global.t('analytics.page.title'),
          sortable: true,
        },
      ]
      getters.dataOnActualDateAll[0]?.data.map(item => {
        items.push({
          traffic: item.traffic,
          refSource: {
            url: item.referralUrl,
            title: item.title,
          },
          page: item.page,
        })
      })
      return {
        items,
        fields,
      }
    },
  },
}
