import requestHelper from '@/helpers/store/requestHelper'
import { filterBySelectedDomain } from '@/helpers/store/filterBySelectedDomain'
import { i18n } from '@/lang'

export const similarSites = {
  namespaced: true,
  actions: {
    async load(ctx, params) {
      const { selectedDomain } = params || ctx.rootGetters
      await requestHelper(
        {
          url: '/data/getSimilarSites',
          domains: selectedDomain,
          viewId: ctx.rootState.view.viewId,
        },
        ctx
      )
    },
  },
  state: () => ({
    data: [],
    loading: true,
    error: { status: false },
    selectedDomainLocal: '',
  }),
  mutations: {
    updateData(state, data) {
      const adaptedData = data.map(item => {
        // const domainName = Object.keys(item)[0]
        return {
          domainName: item.domainName,
          data: item.data,
          totalCount: item.totalCount,
        }
      })
      state.data = adaptedData
    },
    updateLoading(state, status) {
      state.loading = status
    },
    updateSelectedDomainLocal(state, domain) {
      state.selectedDomainLocal = domain
    },
    updateError(state, errorData) {
      state.error = errorData
    },
  },
  getters: {
    dataOnActualDate(state, getters, rootState, rootGetters) {
      const { selectedDomain } = rootGetters
      return filterBySelectedDomain(state.data, selectedDomain)
    },
    tableData(state, getters, rootState, rootGetters) {
      const items = []
      const fields = [
        {
          key: 'domainName',
          label: i18n.global.t('analytics.site.title'),
          sortable: true,
        },
        {
          key: 'traffic',
          label: i18n.global.t('analytics.traffic.title'),
          sortable: true,
        },
      ]
      getters.dataOnActualDate[0]?.data.map(item => {
        items.push({
          domainName: item.domainName,
          traffic: item.lastMonthVisits,
        })
      })
      return {
        items,
        fields,
      }
      // const { selectedDomain } = rootGetters
      // return filterBySelectedDomain(state.data, selectedDomain)
    },
  },
}
