<template>
  <teleport v-if="isOpen" to="#modals">
    <div
      @keydown="handleEscPress"
      @click="handleCloseModal"
      class="overlay_modal g-0"
      :class="[type, options]"
    >
      <div
        @click="handleInsideModalClick"
        aria-modal="true"
        tabindex="-1"
        role="dialog"
        :style="styleModal"
        class="modal_component"
      >
        <img :src="CloseIcon" class="close_icon" @click="handleCloseModal" />
        <div class="title_modal">
          <slot name="title" />
        </div>
        <div class="body_modal">
          <slot name="body" />
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import CloseIcon from '@/assets/icons/base/close.svg'
// import FocusLock from 'vue-focus-lock'

export default {
  data() {
    return {
      CloseIcon,
    }
  },
  name: 'Modal',
  components: {
    // FocusLock,
  },
  props: {
    type: String,
    isOpen: Boolean,
    closeHandler: Function,
    styleModal: Object,
    options: String,
  },
  methods: {
    handleCloseModal() {
      if (this.closeHandler && this.isOpen) this.closeHandler()
    },
    handleEscPress({ key }) {
      if (key === 'Escape') this.handleCloseModal()
    },
    handleInsideModalClick(event) {
      event.stopPropagation()
    },
  },
}
</script>

<style scoped>
.overlay_modal.full {
  background: #f4f4f5;
}
.overlay_modal.full,
.overlay_modal.full .modal_component {
  padding: 0;
}
.overlay_modal.full .title_modal {
  display: none;
}
.overlay_modal {
  background: rgba(38, 38, 38, 0.5);
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 100;
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.close_icon {
  position: absolute;
  right: 33px;
  top: 33px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  fill: #aab2ba;
  z-index: 100;
}

.modal_component {
  margin: auto;
  min-width: 300px;
  min-height: 200px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(232, 232, 232, 0.2);
  border-radius: 4px;
  position: relative;
  padding: 40px 30px 31px 30px;
  outline: none;
}

.title_modal {
  font-weight: 500;
  font-size: 24px;
  color: #0e0f10;
  margin-bottom: 7px;
  display: flex;
  justify-content: center;
}

.body_modal {
  font-size: 16px;
  line-height: 137%;
  color: #5c5f6c;
}
.wrapper_modal {
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
  max-width: 1160px;
}

/** table */
.overlay_modal.table .modal_component {
  max-width: 1160px;
  width: 100%;
  min-height: 86vh;
  overflow-y: hidden;
}
/* .wrapper_modal {
  margin-top: 30px;
} */
.overlay_modal .wrapper_modal {
  margin-top: 60px;
}

.overlay_modal.table .wrapper_modal {
  width: 100%;
  margin-top: 30px;
}
.overlay_modal.table .title_modal {
  justify-content: flex-start;
}

.modal_component .title_modal {
  font-weight: bold;
}

/** adaptive */
@media (max-width: 992px) {
  .overlay_modal .wrapper_modal {
    margin-top: 0 !important;
  }
  .overlay_modal.table {
    padding: 0;
  }
}
</style>
