<template>
  <div>
    <DropDown type="domain" :title="selectedDomainActual">
      <template v-slot:items>
        <DropDownItem
          v-for="domain of domainsActual"
          type="domain"
          :text="domain"
          :value="domain"
          :key="domain"
          @selectItem="selectItem"
        />
      </template>
    </DropDown>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DropDown from '@/components/shared/drop-down/DropDown.vue'
import DropDownItem from '@/components/shared/drop-down/DropDownItem.vue'

export default {
  data() {
    return {}
  },
  components: {
    DropDown,
    DropDownItem,
  },
  props: ['type'],
  computed: {
    ...mapGetters(['domains', 'selectedDomain']),
    selectedDomainActual() {
      return this.selectedDomain
    },
    domainsActual() {
      return this.domains.filter(item => {
        return item !== this.selectedDomainActual || this.domains.length <= 1
      })
    },
  },
  methods: {
    ...mapActions(['changeSelectedDomain']),
    selectItem(value) {
      // if (this.type === 'local') {
      //   this.selectedDomainLocal = value
      //   return this.$emit('changeSelectedDomain', value)
      // }
      this.changeSelectedDomain(value)
    },
  },
}
</script>

<style lang="css" scoped></style>
