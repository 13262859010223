<template>
  <div class="dropdown" :class="type">
    <button
      class="dropdown-toggle drop_down"
      type="button"
      id="dropDown"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span class="title">
        <Favicon v-if="type === 'domain'" :domain="title" class="favicon" />
        <Flag v-if="type === 'country'" :country="title" />
        {{ title }}
      </span>
      <span v-if="divider" class="divider"></span>
      <div class="arrow" :class="{ show: showDialog }">
        <img v-if="type === 'domain'" :src="ArrowWhiteIcon" />
        <img v-else :src="ArrowGrayIcon" />
      </div>
    </button>
    <transition name="dropdown-dialog">
      <ul class="dropdown-menu dialog" aria-labelledby="dropDown">
        <slot name="items"></slot>
      </ul>
    </transition>
  </div>
</template>

<script>
import ArrowGrayIcon from '@/assets/icons/base/arrow-gray.svg'
import ArrowWhiteIcon from '@/assets/icons/base/arrow-white.svg'
import Favicon from '@/components/shared/Favicon.vue'
import Flag from '@/components/shared/Flag.vue'

export default {
  data() {
    return {
      showDialog: false,
      ArrowGrayIcon,
      ArrowWhiteIcon,
    }
  },
  components: {
    Favicon,
    Flag,
  },
  props: {
    type: {
      type: String,
      default: 'origin', // domain
    },
    divider: {
      type: Boolean,
      default: false,
    },
    title: String,
  },
  computed: {},
  methods: {
    onClick() {
      this.showDialog = !this.showDialog
    },
  },
}
</script>

<style lang="css" scoped>
.favicon {
  margin-right: 7px;
}

.divider {
  padding: 1px 0 1px 1px;
  margin-right: 12px;
  margin-left: -3px;
  background: #dadbe0;
}

.dropdown {
  position: relative;
  z-index: 10;
}

.dropdown.domain {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 11rem;
}

button .title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 6px;
}

.dropdown.domain > .dropdown-toggle {
  background: none;
  border-radius: 7px;
  padding: 8px 17px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  background-color: #2684fe;
  font-size: 0.875rem;
  font-weight: 500;
  transition: background-color 0.2s ease-out;
}

.dropdown-menu {
  min-width: 100%;
}

.dropdown.origin,
.dropdown.country {
  border: 1px solid #eaeef8;
  border-radius: 7px;
}

.dropdown.origin > .dropdown-toggle,
.dropdown.country > .dropdown-toggle {
  justify-content: center;
  outline: none;
  box-shadow: none;
  background: none;
  padding: 7px 15px 8px;
}

.dropdown.origin > .dropdown-toggle span,
.dropdown.country > .dropdown-toggle span {
  margin-right: 15px;
}

.dropdown > .dropdown-toggle:hover {
  cursor: pointer;
}

.dropdown > .dropdown-toggle > .icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dropdown > .dropdown-toggle > .arrow {
  display: inline-block;
}

.dropdown > .dropdown-toggle > .arrow img {
  display: inline-block;
  transition: all 0.3s ease-out;
  position: relative;
}

.dropdown .arrow.show img {
  transform: rotate(180deg);
  padding-top: 2px;
}

.dropdown .dropdown-toggle::after {
  display: none;
}

.dropdown > .dialog {
  list-style: none;
  margin: 0;
  padding: 5px;
  background: #ffffff;
  border: 1px solid #eaeef8;
  border-radius: 7px;
  box-shadow: 0px 4px 20px rgba(196, 201, 211, 0.2);
  font-weight: normal;
  max-height: 290px;
  overflow-y: auto;
}

.dropdown > .dialog::-webkit-scrollbar,
.dropdown > .dialog::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 50px;
  background-clip: padding-box;
  border: 3px solid white;
}

.dropdown > .dialog::-webkit-scrollbar-thumb {
  background: #d6dadf;
}

.dropdown-dialog-enter-active {
  transition: all 0.1s ease-out;
}

.dropdown-dialog-leave-active {
  transition: all 0.1s ease-in;
}

.dropdown-dialog-enter-from,
.dropdown-dialog-leave-to {
  transform: translateY(-0.625rem);
  transform: scale(0.8);
  opacity: 0;
}

@media (max-width: 666px) {
  .dropdown > .dialog,
  .dropdown.domain {
    width: 10em;
  }
}
</style>
