<template>
  <WidgetWrapper>
    <WidgetTitle
      v-if="!loading"
      :title="$t('main.similarSites.title')"
      :description="$t('main.similarSites.description')"
    />
    <div
      v-if="isData"
      class="content justify-content-between d-flex flex-column h-100"
      ref="content"
    >
      <div class="d-flex flex-column">
        <div class="row row-cols-1 justify-content-between">
          <WidgetListTitle
            class="plr-15 mb-2"
            :firstName="$t('main.similarSites.widgetListTitle')"
            :secondName="$t('main.similarSites.widgetListValue')"
          />
        </div>
        <div class="row row-cols-1 justify-content-between">
          <button
            class="d-flex justify-content-between wrapper_domain_content"
            v-for="(item, index) of dataOnActualDateLocal.data.slice(
              0,
              competitorsCount
            )"
            :key="index"
            @click="showDialog(item.domainName, index)"
            @focusout="dialog.show = false"
            @mouseover="mouseOverIndex = index"
            @mouseleave="mouseOverIndex = null"
          >
            <div>
              <Domain
                class="domain"
                :domain="item.domainName"
                :isLink="isMobile() ? false : true"
              />
              <img
                :src="CheckmarkIcon"
                v-if="domains.includes(item.domainName)"
                class="ms-2"
              />
              <CompareButton
                v-else
                v-show="mouseOverIndex === index"
                :domain="item.domainName"
              />
            </div>
            <div class="wrapper_link_after d-flex h-100">
              <div class="visits ms-2">
                <template v-if="item.lastMonthVisits >= 0">
                  {{ nFormatter(item.lastMonthVisits) }}
                </template>
                <template v-else>
                  &lt;50K
                </template>
              </div>
            </div>
            <DomainContextMenu
              class="d-lg-none"
              :domain="item.domainName"
              v-if="dialog.show && dialog.index === index"
            />
          </button>
        </div>
      </div>
      <ShowMoreButton
        v-if="totalCount > 16"
        class="mt-20"
        @clickCustom="onModalOpen()"
        :totalCount="totalCount"
      />
    </div>
    <Modal
      type="table"
      :closeHandler="close => (isModalOpen = false)"
      :isOpen="isModalOpen"
    >
      <template v-slot:title>
        {{ $t('main.similarSites.table.title') }}
      </template>
      <template v-slot:body> <TableSimilarSites /> </template>
    </Modal>
    <WidgetError v-if="!isData && !loading" />
    <SkeletonLoading v-if="loading" name="similarSites" />
  </WidgetWrapper>
</template>

<script>
import WidgetWrapper from '@/components/widgets/widgets-helpers/WidgetWrapper.vue'
import WidgetTitle from '@/components/widgets/widgets-helpers/WidgetTitle.vue'
import DomainContextMenu from '@/components/shared/DomainContextMenu.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import WidgetError from '@/components/errors/WidgetError.vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import ShowMoreButton from '@/components/buttons/ShowMoreButton.vue'
import CompareButton from '@/components/buttons/CompareButton.vue'
import WidgetListTitle from '@/components/widgets/widgets-helpers/WidgetListTitle.vue'
import Domain from '@/components/widgets/widgets-helpers/Domain.vue'
import CheckmarkIcon from '@/assets/icons/base/checkmark.svg'
import isMobile from '@/helpers/base/isMobile'
import Modal from '@/components/modals/Modal.vue'
import TableSimilarSites from '@/components/tables/TableSimilarSites.vue'
import nFormatter from '../../../helpers/processing/nFormatter'

export default {
  data() {
    return {
      mouseOverIndex: null,
      dialog: {
        show: false,
        domain: null,
        index: null,
      },
      competitorsCount: 16,
      isModalOpen: false,
      CheckmarkIcon,
    }
  },
  components: {
    SkeletonLoading,
    WidgetError,
    WidgetTitle,
    WidgetWrapper,
    ShowMoreButton,
    Domain,
    CompareButton,
    WidgetListTitle,
    DomainContextMenu,
    Modal,
    TableSimilarSites,
  },
  computed: {
    ...mapGetters([
      'domains',
      'creatorSubscriptionActive',
      'subscriptionActive',
    ]),
    ...mapGetters('similarSites', ['dataOnActualDate']),
    ...mapState('similarSites', {
      similarSites: state => state,
    }),
    dataOnActualDateLocal() {
      return this.dataOnActualDate[0]
    },
    isData() {
      return (
        this.dataOnActualDateLocal?.data?.length &&
        !this.loading &&
        !this.similarSites.error.status
      )
    },
    totalCount() {
      return this.dataOnActualDateLocal?.totalCount || 0
    },
    loading() {
      // return true
      return this.similarSites.loading
    },
  },
  methods: {
    nFormatter,
    isMobile,
    showDialog(domain, index) {
      this.dialog.show = !this.dialog.show
      this.dialog.domain = domain
      this.dialog.index = index
    },
    onModalOpen() {
      if (!this.subscriptionActive)
        return this.$store.dispatch(
          'trialEndedPopupModule/getPlanAndOpen',
          'similar_sites'
        )
      this.isModalOpen = true
    },
  },
}
</script>

<style scoped>
.wrapper {
  position: relative;
}

@media (max-width: 1476px) {
  .wrapper_domain_content:nth-child(1n + 11) {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .wrapper_domain_content:nth-child(1n + 8) {
    display: none !important;
  }
}

.domain {
  max-width: 240px;
}

.plr-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.mt-20 {
  margin-top: 26px;
}

.wrapper_domain_content {
  padding: 7px 15px 6px;
  position: relative;
  background: none;
}
.wrapper_domain_content:hover {
  background: #f9f9fc;
  border-radius: 8px;
}

img .path {
  fill: 'red';
}

.topics {
  font-size: 12px;
  color: #aab2ba;
}

.visits {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #0e0f10;
}

.add_icon {
  stroke: #2196f3;
  width: 9px;
  height: 9px;
}

.success_icon {
  fill: #34a853;
}

.wrapper_success {
  display: inline-block;
}
</style>
