<template>
  <div>
    <AuthInstruction>
      <template v-slot:picture>
        <img :src="ClipFinancialReport" />
      </template>
      <template v-slot:points>
        <!-- <div class="text-muted">
          {{ $t('modals.auth.freeMetrica2') }}
        </div> -->
      </template>
    </AuthInstruction>
    <Button color="white" class="button mt-4 mb-3" @click="openGoogle()">
      <div class="icon">
        <img :src="GoogleButtonIcon" />
      </div>
      {{ $t('modals.auth.singInGoogle') }}
    </Button>
    <Button color="blue" class="button mb-3" @click="openMainButtonTelegram()">
      <div class="icon">
        <img :src="TelegramButtonIcon" />
      </div>
      {{ $t('modals.auth.singInTelegram') }}
    </Button>
    <div
      @click="openWebVersionTelegram()"
      class="text-center text-muted web_tg"
    >
      {{ $t('modals.auth.imUsing') }}
      <span class="btn_link">{{ $t('modals.auth.webVersion') }}</span>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import { getCookie } from '@/helpers/base/cookie'
import { makeEvent } from '@/helpers/base/amplitude'
import {
  TELEGRAM_URL,
  TELEGRAM_SUPPORT,
  TELEGRAM_BOT_NAME,
  REDIRECT_URL,
} from '@/helpers/variables'
import ClipLetsPartyIllustrations from '@/assets/illustrations/clip-lets-party.svg'
import ClipFinancialReport from '@/assets/illustrations/ClipFinancialReport.svg'
import GoogleButtonIcon from '@/assets/icons/google-icon.svg'
import TelegramButtonIcon from '@/assets/icons/telegram-button.svg'
import Button from '@/components/buttons/Button.vue'
import AuthInstruction from '@/components/auth/AuthInstruction.vue'
import { api } from '@/helpers/base/api'

export default {
  data() {
    return {
      ClipLetsPartyIllustrations,
      ClipFinancialReport,
      TelegramButtonIcon,
      GoogleButtonIcon,
      source: 'web',
      telegramUrl: TELEGRAM_URL,
      TELEGRAM_BOT_NAME,
      TELEGRAM_SUPPORT,
      REDIRECT_URL,
    }
  },
  components: {
    Button,
    AuthInstruction,
  },
  computed: {
    ...mapState('authModule', {
      authModule: state => state,
    }),
    ...mapGetters('userInfo', ['userInfo']),
    ...mapState(['view']),
    firstViewId() {
      return getCookie('firstViewId')
    },
    urlAuth() {
      return `${this.telegramUrl}?start=authenticate_${this.source}_${
        this.view.viewId
      }${this.firstViewId ? `_${this.firstViewId}` : ''}`
    },
  },
  methods: {
    ...mapMutations('authModule', ['setStep', 'close']),
    openMainButtonTelegram() {
      makeEvent('auth popup', {
        button: 'open_telegram',
      })
      window.open(this.urlAuth)
      this.setStep(null)
    },
    async openGoogle() {
      try {
        const { pathname } = window.location
        const respond = await api.get(`/auth/google?lastLink=${pathname}`)
        makeEvent('auth popup', {
          button: 'sign_in_google',
        })
        window.open(`${respond.data}`, '_self')
      } catch (err) {
        console.error(err)
      }
    },
    openWebVersionTelegram() {
      this.setStep(2)
      makeEvent('auth popup', {
        button: 'using_web_version',
      })
    },
  },
}
</script>

<style scoped>
.btn_link {
  color: #2684fe;
}
.auth_main_item :v-deep(path) {
  fill: #2684fe;
}
.modal__inner {
  display: flex;
  flex-wrap: wrap;
  width: 1160px;
  background: #ffffff;
  border-radius: 7px;
  max-width: 100vw;
}
.modal__inner .modal_side {
  width: 50%;
  padding: 60px 70px 80px 60px;
  background: #ffffff;
}
.modal__inner .modal_side:last-child {
  background: #f7f7f7;
}
.modal__inner .modal_title_text {
  font-weight: bold;
  font-size: 36px;
  line-height: 49px;
  color: #0e0f10;
  margin-bottom: 10px;
}

.modal__inner .modal_description_text {
  color: #2684fe;
  font-weight: 600;
  font-size: 15px;
}
.auth_main_items {
  padding-top: 10px;
}
.auth_main_item {
  display: flex;
  margin-top: 30px;
}
.auth_main_item_text {
  margin-left: 18px;
}
.auth_main_item .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #0e0f10;
  margin-bottom: 5px;
}
.auth_main_item .desc {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #5c5f6c;
}
.right_side .description {
  margin-bottom: 50px;
}
.icon {
  margin-right: 20px;
}
.button {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  box-shadow: 0px 4px 20px rgba(232, 232, 232, 0.2);
  max-width: 350px;
  margin: auto;
}
.web_tg {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #808493;
}
.web_tg span {
  cursor: pointer;
}
.big_checkbox_arrow_icon {
  margin-top: 3px;
}
.modal_side.right_side svg {
  width: 284px;
  height: 225px;
}
@media (max-width: 1024px) {
  .modal__inner {
    width: auto;
  }
  .modal__inner .modal_side {
    width: 100%;
    padding: 20px;
  }
  .modal__inner .modal_title_text {
    font-weight: bold;
    font-size: 22px;
    line-height: normal;
    text-align: center;
    margin-top: 30px;
  }
  .modal__inner .modal_side:not(.right_side) {
    padding-bottom: 50px;
  }
  .auth_main_items {
    max-width: 550px;
    margin: 0 auto;
  }
  .text_blue {
    font-size: 15px;
    text-align: center;
    display: block;
  }
  .auth_main_items {
    padding-top: 0;
  }
  .auth_main_item {
    margin-top: 15px;
  }
  .auth_main_item .title {
    font-size: 16px;
    line-height: normal;
  }
  .auth_main_item .desc {
    font-size: 15px;
    line-height: normal;
  }
  .modal_side.right_side {
    order: -1;
  }
  .modal_side.right_side svg {
    width: 250px;
  }
  .right_side .description {
    margin-bottom: 25px;
  }
}
</style>
