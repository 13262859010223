<template>
  <span>
    <img
      class="favicon"
      :style="{
        width: width + 'px',
        height: height + 'px',
        marginRight: marginRight ? '10px' : 0,
      }"
      :src="getFavicon(domain, 64)"
    />
  </span>
</template>

<script>
import getFavicon from '@/helpers/domains/getFavicon'

export default {
  data() {
    return {}
  },
  props: {
    domain: String,
    width: {
      type: Number,
      default: 14,
    },
    height: {
      type: Number,
      default: 14,
    },
    marginRight: Boolean,
  },
  components: {},
  methods: {
    getFavicon,
  },
}
</script>

<style scoped></style>
