<template>
  <div class="block_success">
    <img :src="SuccessCheck" />
    <div class="title">
      {{ $t('successPage.title') }}
    </div>
    <div class="description">
      {{ $t('successPage.gratitude') }}
      <br />
      {{ $t('successPage.support') }}
    </div>
    <a class="href_main" href="/">
      <Button :color="'pink'">
        {{ $t('buttons.goToMain') }}
      </Button>
    </a>
  </div>
</template>

<script>
import SuccessCheck from '@/assets/icons/success_check.svg'
import Button from '@/components/buttons/Button.vue'
import { makeEvent } from '@/helpers/base/amplitude'

export default {
  data() {
    return {
      SuccessCheck,
    }
  },
  name: 'SuccessPaid',
  mounted() {
    makeEvent('success payment page')
  },
  components: {
    Button,
  },
  methods: {},
}
</script>

<style scoped>
.block_success {
  max-width: 508px;
  height: 354px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(232, 232, 232, 0.2);
  border-radius: 4px;
  padding: 40px 30px 40px 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 61px;
}
.title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #0e0f10;
  margin-top: 20px;
  margin-bottom: 15px;
}
.description {
  font-size: 16px;
  line-height: 126.69%;
  text-align: center;
  color: #808493;
}
.href_main {
  margin-top: auto;
  width: 100%;
  text-decoration: none;
}
</style>
