export const getCookie = name =>
  document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=')
    return parts[0] === name ? decodeURIComponent(parts[1]) : r
  }, '')

export const deleteCookie = name => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export function setCookie(name, value, days) {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = `; expires=${date.toUTCString()}`
  }
  const domain = document.domain.match(/[^\.]*\.[^.]*$/)?.[0] || document.domain

  document.cookie = `${name}=${value || ''}${expires}; path=/;domain=${
    domain === 'localhost' ? domain : `.${domain}`
  };`
}
