<template>
  <button
    :class="{ active: activeChannel === channelKey }"
    @click="$emit('channelClicked', channelKey)"
  >
    <span class="icon">
      <img :src="channelIcon" />
    </span>
    <span>{{ channelData.title }}</span>
  </button>
</template>

<script>
export default {
  data() {
    return {}
  },
  components: {},
  props: ['channelData', 'channelKey', 'activeChannel', 'channelIcon'],
  methods: {},
}
</script>

<style scoped>
button {
  border-radius: 7px;
  padding: 9px 15px;
  background: #f8f9fe;
  color: #808493;
}
.icon {
  margin-right: 10px;
}
.active {
  background: #2684fe;
  color: #ffffff;
}
</style>
