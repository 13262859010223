<template>
  <div>
    <div class="d-flex justify-content-between wrapper_header">
      <slot name="domainPicker"></slot>
      <slot name="countryPicker"></slot>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" v-for="field of fields" :key="field">
              {{ field.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item of items" :key="item">
            <template v-for="(field, index) of fields" :key="field">
              <component
                :is="!index ? 'th' : 'td'"
                :scope="!index ? 'row' : ''"
              >
                <template v-if="$slots[`cell(${field.key})`]">
                  <slot
                    :name="`cell(${field.key})`"
                    v-bind="{ value: item[field.key] }"
                  ></slot>
                </template>
                <template v-else>
                  {{ item[field.key] }}
                </template>
              </component>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import TableItem from './TableItem.vue'

export default {
  components: { TableItem },
  props: ['items', 'fields', 'sortBy', 'sortDesc'],
  data() {
    return {
      currentPage: 1,
    }
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped>
.table-responsive {
  overflow-y: auto;
  max-height: 65vh;
  font-size: 14px;
}

.table-responsive::-webkit-scrollbar {
  width: 4px;
}
.table-responsive::-webkit-scrollbar-thumb {
  background-color: #d6dadf;
  border-radius: 50px;
}
.table-responsive::-webkit-scrollbar-track {
  background-color: white;
}

.table_wrapper {
  padding: 0;
}

.wrapper_header {
  margin: 3px 0 32px;
}

.table thead th {
  color: #aab2ba;
  border-bottom: 1px solid #eaeef8;
}

.table tbody th {
  min-width: 175px;
  color: #0e0f10;
}

.table th {
  /* text-transform: uppercase; */
  font-weight: 400;
  border-top: none;
}

.table td,
.table tr {
  border: none;
  padding: 13px;
  vertical-align: middle;
}
.table tr {
  font-weight: 500;
}

tr:hover td,
tr:hover th {
  background: #f9f9fc;
  border-radius: 7px;
}

/* th[aria-sort='ascending'],
th[aria-sort='descending'] {
  color: #2684fe !important;
  background-image: none;
}

.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-image: none;
} */

/* .table.b-table thead tr div {
  display: inline-block;
  position: relative;
} */

/* thead tr div::after {
  content: '';
  width: 16px;
  height: 16px;
  position: absolute;
  top: 3px;
  margin-left: 5px;
  z-index: 3;
  background: url('/sort/sort.svg') 0 0 repeat;
}

thead > tr > [aria-sort='descending'] div::after {
  background-image: url('/sort/sort_active.svg') !important;
}

thead > tr > [aria-sort='ascending'] div::after {
  background-image: url('/sort/sort_active_asc.svg') !important;
} */
</style>
