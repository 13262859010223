<template>
  <WidgetWrapper class="justify-content-between">
    <WidgetTitle
      v-if="!loading"
      :title="$t('main.deviceDistribution.title')"
      :description="$t('main.deviceDistribution.description')"
    />
    <div v-if="isData" class="content" ref="content">
      <div class="d-flex flex-wrap g-0">
        <div class="invisible col-4 distribution_name widget_title_list">
          empty row
        </div>
        <WidgetListTitle
          class="widget_title_list col"
          :firstName="$t('main.deviceDistribution.WidgetListTitle')"
          :secondName="$t('main.deviceDistribution.widgetListValue')"
        />
        <div
          class="d-flex item align-items-center col-12 g-0"
          v-for="item of dataOnActualDate"
          :key="item?.domainName"
        >
          <Domain
            class="d-flex flex-nowrap align-items-center col-4"
            :domain="item.domainName"
          />
          <div class="wrapper_traffic d-flex align-items-center col">
            <Indicator2
              :share1="getShare('desktopShare', item)"
              :share2="getShare('mobileShare', item)"
            />
          </div>
        </div>
      </div>
    </div>
    <WidgetError v-if="!isData && !loading" />
    <SkeletonLoading v-if="loading" name="deviceDestribution" />
  </WidgetWrapper>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import WidgetTitle from '@/components/widgets/widgets-helpers/WidgetTitle.vue'
import SkeletonLoading from '@/components/SkeletonLoading.vue'
import getFavicon from '@/helpers/domains/getFavicon'
import WidgetError from '@/components/errors/WidgetError.vue'
import WidgetWrapper from '@/components/widgets/widgets-helpers/WidgetWrapper.vue'
import Domain from '@/components/widgets/widgets-helpers/Domain.vue'
import Indicator2 from '@/components/widgets/widgets-helpers/Indicator2.vue'
import WidgetListTitle from '@/components/widgets/widgets-helpers/WidgetListTitle.vue'
import DropDown from '@/components/shared/drop-down/DropDown.vue'
import DropDownItem from '@/components/shared/drop-down/DropDownItem.vue'
import getFormattedDate from '@/helpers/date/getFormattedDate'

export default {
  data() {
    return {
      title: 'Устройства',
      description: 'Распределение трафика по устройствам',
    }
  },
  components: {
    WidgetTitle,
    SkeletonLoading,
    WidgetError,
    WidgetWrapper,
    Domain,
    Indicator2,
    WidgetListTitle,
    DropDown,
    DropDownItem,
  },
  computed: {
    ...mapGetters('deviceDistribution', ['dataOnActualDate']),
    ...mapGetters(['subscriptionActive']),
    ...mapState('deviceDistribution', {
      deviceDistribution: state => state,
    }),
    isData() {
      return !this.loading && !this.deviceDistribution.error.status
    },
    loading() {
      // return true
      return this.deviceDistribution.loading
    },
  },
  methods: {
    ...mapMutations('deviceDistribution', ['updateActualDate']),
    getFavicon,
    getFormattedDate,
    getShare(metrica, item) {
      return item.data?.[0]?.[metrica]
    },
  },
}
</script>

<style scoped>
@media (min-width: 768px) {
  .wrapper_title {
    margin-bottom: 0;
  }
}

.item {
  white-space: nowrap;
  position: relative;
  padding: 16px 0;
}
.item + .item {
  border-top: 1px solid #eaeef8;
}

.indicator {
  height: 10px;
  width: 100%;
  margin: 0 16px;
  background: #a6d6ff;
  border-radius: 0.5rem;
  overflow: hidden;
}

.domain {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.desktop_indicator {
  height: 10px;
  border-radius: 0.5rem;
}

.widget_title_list {
  margin-bottom: 12px;
}
.distribution_name {
  font-size: 13px;
  color: #aeb0ba;
}
.favicon {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.share {
  min-width: 32px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0e0f10;
}

.mobile_share {
  text-align: right;
}
</style>
