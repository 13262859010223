<template>
  <div class="d-flex justify-content-between">
    <span class="title_item">{{ firstName }}</span>
    <span class="title_item">{{ secondName }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    firstName: String,
    secondName: String,
  },
  components: {},
  methods: {},
}
</script>

<style scoped>
.title_item {
  font-size: 13px;
  color: #aeb0ba;
}
</style>
