<template>
  <div class="wrapper_layout">
    <Menu class="menu" :isCollapsed="true" />
    <div class="app_layout">
      <Header class="mb-20" />
      <div class="row home_body col-12 g-0">
        <router-view />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from '@/views/Header.vue'
import Menu from '@/views/header/menu/Menu.vue'
import Footer from '@/views/Footer.vue'

export default {
  components: {
    Header,
    Footer,
    Menu,
  },
}
</script>
<style scoped>
.app_layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-left: 50px;
  position: relative;
  overflow-x: hidden;
}
.mb-20 {
  margin-bottom: 20px;
}
.row {
  flex-grow: 1;
}
.home_body {
  max-width: 1852px;
  padding: 0 30px;
  margin: auto;
}

@media (max-width: 992px) {
  .menu {
    display: none;
  }
  .app_layout {
    margin-left: 0;
  }
  .home_body {
    padding: 0;
  }
}
</style>
