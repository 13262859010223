import axios from 'axios'
import { getCookie } from './cookie'
import { API_SERVER } from '../variables'

export const instance = axios.create({
  baseURL: API_SERVER,
  withCredentials: true,
  //   crossdomain: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-Client-App': 'Web',
    jwt: getCookie('jwt'),
    'accept' : '',
    // 'Content-Type': 'application/json',
  },
})

const api = {}
api.get = (endpoint, params = {}, options = {}) => {
  return instance.get(endpoint, { params, ...options }).then(({ data }) => data)
}

api.post = (endpoint, body, options) => {
  return instance.post(endpoint, body, options).then(({ data }) => data)
}

api.delete = (endpoint, data, options) =>
  instance.delete(endpoint, { data }, options)
api.put = (endpoint, data) => instance.put(endpoint, data)
export { api }

window.api = api
