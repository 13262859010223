<template>
  <span class="wrapper_stars filled d-flex align-items-center">
    <template v-for="item of ratingArray" :key="item">
      <img :src="StarActive" v-if="rating > item" />
      <img :src="StarInactive" v-else />
    </template>
  </span>
</template>

<script>
import StarActive from '@/assets/icons/mobile-apps/StarActive.svg'
import StarInactive from '@/assets/icons/mobile-apps/StarInactive.svg'

export default {
  data() {
    return {
      ratingArray: [1, 2, 3, 4, 5],
      StarActive,
      StarInactive,
    }
  },
  props: ['rating'],
  components: {},
  methods: {
    ratingCount(rating) {
      return Math.round(+rating)
    },
  },
}
</script>

<style>
.wrapper_stars {
  padding-left: 8px;
}

.wrapper_stars + .wrapper_stars {
  padding-left: 2px;
}
</style>
