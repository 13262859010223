<template>
  <img class="icon flag" :src="'/flags/' + country + '.svg'" />
</template>

<script>
export default {
  name: 'Feature',
  props: ['country'],
}
</script>

<style scoped>
.icon {
  width: 16px;
  height: 16px;
}
.flag {
  margin-right: 10px;
  margin-bottom: 2px;
}
</style>
