<template>
  <div>
    <div
      v-if="!isOneDomain"
      class="channels col d-flex flex-wrap flex-row justify-content-center"
    >
      <ChannelButtonItem
        class="ChannelButtonItem"
        v-for="(channel, channelKey) in channelsInfo"
        :channelData="channel"
        :channelKey="channelKey"
        :channelIcon="channelsInfo[channelKey].icon"
        :key="channelKey"
        :activeChannel="marketingChannels.activeChannel"
        @channelClicked="setActiveChannel"
      />
    </div>
    <LineChart
      :chartData="dataset"
      :key="marketingChannels.activeChannel + $i18n.locale"
    >
      <template v-slot:legend>
        <div class="d-flex flex-column align-items-center">
          <LegendDomains
            :dataset="dataset"
            :class="{ 'mt-3': isOneDomain }"
            :removeButton="false"
            :type="isOneDomain ? 'checkbox_button' : 'checkbox'"
            :link="isOneDomain ? false : true"
            chartName="marketingChannels"
          />
          <div class="one_domain" v-if="isOneDomain">
            <Domain :domain="domains[0]" />
          </div>
        </div>
      </template>
    </LineChart>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import LineChart from '@/components/line-chart/LineChart.vue'
import LegendDomains from '@/components/line-chart/legends/LegendDomains.vue'
import Domain from '@/components/widgets/widgets-helpers/Domain.vue'
import ChannelButtonItem from './ChannelButtonItem.vue'

export default {
  data() {
    return {}
  },
  props: ['channelsInfo'],
  computed: {
    ...mapGetters('marketingChannels', ['datasetFull', 'datasetOneDomain']),
    ...mapGetters(['domains']),
    ...mapState('marketingChannels', {
      marketingChannels: state => state,
    }),
    isOneDomain() {
      return this.domains.length === 1
    },
    dataset() {
      if (this.domains.length > 1) return this.datasetFull
      return this.datasetOneDomain
    },
  },
  components: {
    LineChart,
    LegendDomains,
    ChannelButtonItem,
    Domain,
  },
  methods: {
    ...mapMutations('marketingChannels', ['setActiveChannel']),
  },
}
</script>

<style scoped>
.ChannelButtonItem {
  margin: 10px 10px 10px 0;
}
.channels {
  margin: 13px 0 20px;
}
.one_domain {
  margin-top: 30px;
}
</style>
