<template>
  <div class="bell">
    <img :src="NotificationBellIcon" />
    <img class="badge" v-if="notifications.isShowBadge" :src="BadgePointIcon" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NotificationBellIcon from '@/assets/icons/notificationBell.svg'
import BadgePointIcon from '@/assets/icons/badge-point.svg'

export default {
  data() {
    return {
      NotificationBellIcon,
      BadgePointIcon,
    }
  },
  computed: {
    ...mapState('notifications', {
      notifications: state => state,
    }),
  },
  mounted() {
    this.$store.dispatch('notifications/loadNotificationStatus')
  },
}
</script>

<style scoped>
.bell {
  cursor: pointer;
  position: relative;
}
.badge {
  display: flex;
  position: absolute;
  top: -4px;
  right: -7px;
}
</style>
