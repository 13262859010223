export const addDomainModal = {
  namespaced: true,
  actions: {
    show(ctx, params) {
      if (
        !ctx.rootGetters.subscriptionActive &&
        ctx.rootGetters.domains.length >= 3
      )
        return ctx.dispatch(
          'trialEndedPopupModule/getPlanAndOpen',
          'add_domain',
          {
            root: true,
          }
        )
      ctx.commit('setVisibility', true)
    },
    hide(ctx, params) {
      ctx.commit('setVisibility', false)
    },
  },
  state: () => ({
    isShow: false,
  }),
  mutations: {
    setVisibility(state, visibility) {
      state.isShow = visibility
    },
  },
}
