<template>
  <li class="dialog-item" @click="onChange(value)">
    <div class="text_wrapper">
      <slot>
        <Favicon v-if="type === 'domain'" class="favicon" :domain="text" />
        <Flag v-if="type === 'country'" :country="value" />
        <span>{{ text }}</span>
      </slot>
    </div>
    <img :src="LockIcon" v-if="isLocked" class="lock_item" />
  </li>
</template>

<script>
import Favicon from '@/components/shared/Favicon.vue'
import LockIcon from '@/assets/icons/lock.svg'
import Flag from '@/components/shared/Flag.vue'

export default {
  data() {
    return {
      LockIcon,
    }
  },
  props: ['type', 'text', 'value', 'isLocked'],
  components: {
    Favicon,
    Flag,
  },
  computed: {},
  methods: {
    onChange(val) {
      if (this.isLocked)
        return this.$store.dispatch(
          'trialEndedPopupModule/getPlanAndOpen',
          'drop_down'
        )
      this.$emit('selectItem', val)
    },
  },
}
</script>

<style lang="css" scoped>
.favicon {
  margin-right: 10px;
}

.dialog-item {
  font-size: 14px;
  line-height: 19px;
  color: #0e0f10;
  border-radius: 7px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
}

.dialog-item:hover {
  cursor: pointer;
  background-color: #f9f9fc;
}

.text_wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding: 0.375rem 0.75rem;
}

.text_wrapper span {
  margin-right: 10px;
}

.lock_item {
  margin-left: -5px;
  margin-right: 11px;
}
</style>
