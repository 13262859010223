import { api } from '@/helpers/base/api'
import { makeEvent } from '@/helpers/base/amplitude'
import router from '@/router'

export default {
  actions: {
    async loadAll(ctx, params) {
      this.dispatch('traffic/load')
      this.dispatch('mainData/load')
      // this.dispatch('referrals/load')
      this.dispatch('similarSites/load')
      // this.dispatch('uniqueVisitors/load')
      // this.dispatch('deviceDistribution/load')
      // this.dispatch('additionalData/load')
      // this.dispatch('ecosystem/load')
      // this.dispatch('keywords/load')
      this.dispatch('geo/load')
    },
    async getAndCreateView(ctx, params) {
      this.commit('updateError', { status: false })
      if (params?.domains?.length)
        await this.dispatch('createView', { domains: params.domains })
      const result = await this.dispatch('getView')
      if (result?.error) {
        this.commit('updateError', { status: true })
        return result
      }
    },
    async addDomain(ctx, params) {
      try {
        const newDomains = params.domains
        newDomains.map(item => {
          if (ctx.state.domains.includes(item)) throw 'dublicatedSite'
        })

        const domains = ctx.state.domains.concat(newDomains)
        makeEvent('add domain', {
          from: params.from,
          domains_count: domains?.length,
        })
        if (!ctx.state.subscriptionActive && ctx.state.domains.length >= 3)
          throw 'addMoreSites'
        if (domains.length >= 50) throw 'maxLimitSites'
        await ctx.dispatch('getAndCreateView', { domains })
        ctx.dispatch('loadAll')
      } catch (error) {
        return { error }
      }
    },
    async removeDomain(ctx, params) {
      try {
        const domainsDeleted = params.domains
        const firstDomain = params.domains[0]
        // delete domain from domains
        const domains = ctx.state.domains.filter(
          domain => !domainsDeleted.includes(domain)
        )
        if (domains.length <= 0) throw 'minLimitSites'
        makeEvent('delete domain')
        await ctx.dispatch('getAndCreateView', { domains })
        // when first domain
        if (domainsDeleted.includes(firstDomain))
          ctx.commit('updateSelectedDomain', ctx.state.domains[0])

        ctx.dispatch('loadAll')
      } catch (error) {
        console.error(error)
        return { error }
      }
    },
    async getView(ctx) {
      try {
        const viewId = router.currentRoute.value.params.id
        const url = '/service/getViewSummary'
        const result = await api.get(url, { id: viewId })
        if (!result.success) throw result.message
        if (result) ctx.commit('updateViewSummary', result)
        ctx.commit('updateViewId', viewId)
        return result
      } catch (error) {
        return { error: 'invalid view_id' }
      }
    },
    async createView(ctx, params) {
      try {
        const { domains } = params
        const url = '/service/createView'
        const result = await api.post(url, { domains })
        if (result.success) {
          await router.push(`/id/${result.viewId}`)
          return result
        }
        throw result
      } catch (error) {
        return error
      }
    },
    changeSelectedDomain(ctx, param) {
      if (!param) return
      ctx.commit('updateSelectedDomain', param)
      // this.dispatch('referrals/load')
      this.dispatch('similarSites/load')
      // this.dispatch('ecosystem/load')
      // this.dispatch('keywords/load')
      this.dispatch('geo/load')
    },
  },
  state: {
    domains: [],
    selectedDomain: null,
    viewId: null,
    creatorSubscriptionActive: false,
    isCreatorRequested: false,
    subscriptionActive: false,
    errorData: {
      status: false,
    },
  },
  mutations: {
    updateViewSummary(state, data) {
      state.domains = data.domains
      state.selectedDomain = state.selectedDomain || data.domains[0]
      state.creatorSubscriptionActive = data.isCreatorHasSubscription
      state.isCreatorRequested = data.isCreatorRequested
      state.subscriptionActive = data.isSubscriptionActive
    },
    updateViewId(state, data) {
      state.viewId = data
    },
    updateError(state, data) {
      state.errorData = {
        status: data.status,
        message: data.message,
      }
    },
    updateSelectedDomain(state, data) {
      const newDomains = []
      state.domains.forEach(item => {
        if (item === data) return newDomains.unshift(item)
        newDomains.push(item)
      })
      state.domains = newDomains
      state.selectedDomain = data
    },
  },
  getters: {
    domains(state) {
      return state.domains
    },
    errorData(state) {
      return state.errorData
    },
    selectedDomain(state) {
      return state.selectedDomain
    },
    viewId(state) {
      return state.viewId
    },
    creatorSubscriptionActive(state) {
      return state.creatorSubscriptionActive
    },
    subscriptionActive(state) {
      return state.subscriptionActive
    },
  },
}
