export default {
  aspectRatio: 1.3,
  animation: {
    duration: 0, // general animation time
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    ],
  },
  layout: {
    padding: 0,
  },
  legend: {
    display: false,
  },
  elements: {
    point: {
      radius: 0,
    },
    line: {
      tension: 0.5,
      borderWidth: 2,
    },
  },
}
