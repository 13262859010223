import { setActualDate } from '@/helpers/store/setActualDate'
import { filterOnActualDate } from '@/helpers/store/filterOnActualDate'
import { filterBySelectedDomain } from '@/helpers/store/filterBySelectedDomain'
import requestHelper from '@/helpers/store/requestHelper'
import { i18n } from '@/lang'

export const geo = {
  namespaced: true,
  actions: {
    async load(ctx) {
      // const { selectedDomain } = ctx.rootGetters
      // await requestHelper(
      //   {
      //     url: '/data/getAdditionalGeo',
      //     domains: selectedDomain,
      //     viewId: ctx.rootState.view.viewId,
      //   },
      //   ctx,
      //   'updateDataAll'
      // )
    },
    set(ctx, data) {
      const domain_first = this.getters.domains[0]
      if (domain_first) ctx.commit('updateGeoFirstPickedSite', domain_first)
      const domain_second = this.getters.domains[1]
      if (domain_second) ctx.commit('updateGeoSecondPickedSite', domain_second)
      ctx.commit('updateData', data)
      ctx.commit('updateActualDate')
    },
  },
  state: {
    data: [],
    dataAll: [],
    actualDate: null,
    platforms: 'desktop',
    geoFirstPickedSite: '',
    geoSecondPickedSite: '',
    loading: true,
    error: { status: false },
  },
  mutations: {
    updateData(state, data) {
      const result = data.map(dataItem => {
        return {
          domainName: dataItem.domainName,
          data: dataItem.geo,
        }
      })
      state.data = result
    },
    updateDataAll(state, data) {
      const result = data.map(dataItem => {
        return {
          domainName: dataItem.domainName,
          data: dataItem.data,
        }
      })
      state.dataAll = result
    },
    updateGeoFirstPickedSite(state, data) {
      state.geoFirstPickedSite = data
    },
    updateGeoSecondPickedSite(state, data) {
      if (data) state.geoSecondPickedSite = data
    },
    updateActualDate(state, value) {
      console.log(state.data, value)
      state.actualDate = setActualDate(state.data, value)
    },
    updateLoading(state, status) {
      state.loading = status
    },
    updateError(state, data) {
      state.error = data
    },
  },
  getters: {
    dataOnActualDate(state, getters, rootState, rootGetters) {
      return filterOnActualDate(state.data, state.actualDate)
    },
    dataset(state, getters, rootState, rootGetters) {
      const result = {}
      state.data.forEach((dataItem, index) => {
        const data = getters.dataOnActualDate[index]?.data[0]?.data || {}
        result[dataItem.domainName] = data
      })
      return result
    },
    tableData(state, getters, rootState, rootGetters) {
      const items = []
      const fields = [
        {
          key: 'country',
          label: i18n.global.t('analytics.country.title'),
          sortable: true,
        },
        {
          key: 'traffic',
          label: i18n.global.t('analytics.traffic.title'),
          sortable: true,
        },
      ]
      const dataAll = filterBySelectedDomain(
        state.dataAll,
        rootGetters.selectedDomain
      )
      const countriesAll = dataAll[0]?.data || {}
      Object.keys(countriesAll).map(item => {
        items.push({
          country: item,
          traffic: countriesAll[item]?.share,
          change: countriesAll[item]?.change || null,
        })
      })
      return {
        items,
        fields,
      }
    },
    isData(state, getters, rootState) {
      return !rootState.mainData.loading && !rootState.mainData.error.status
    },
  },
}
