<template>
  <div class="d-flex flex-column align-items-center wrapper_error">
    <div class="d-flex flex-row">
      <div class="d-flex title">
        {{ $t(`error.${errorData.name}`) }}
      </div>
    </div>
    <!-- <div class="body">{{ $t(`error.${errorData.name}.body`) }}</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    errorData: {
      type: Object,
      default: () => {
        return {
          name: 'notFound',
        }
      },
    },
  },
  methods: {},
}
</script>

<style scoped>
.wrapper_error {
  padding: 0 20px;
  margin: 10px 0;
}
.title {
  color: #8f8f8f;
  margin-right: 5px;
  text-align: center;
}
.body {
  color: #8f8f8f;
  text-align: center;
}
.image_error {
  width: 125px;
  margin-bottom: 15px;
}
.specialTitleText {
  color: #2196f3;
  font-weight: 400;
}
</style>
